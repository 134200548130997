import React, { Fragment, useEffect, useState } from "react";
import "./body.css";

import axios from "axios";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";
import Constant from "../../constants";

const Body = ({
  setStep,
  step,
  bodytypeList,
  setBodyTypeId,
  bodyTypeId,
  setIsBodyTypeActive,
  isBodyTypeActive,
  nextStep,
  prevStep,
  checkCategory,
  editVehicleObj,
  isEngActive,
  setIsEngActive,
  setEngCond,
  engCond,
  engineConditionList,
}) => {
  const [updateBodyList, setUpdateBodylist] = useState([]);

  if (editVehicleObj?._id) {
    let bodyTypeIndex = bodytypeList
      .map((bodyType) => bodyType._id)
      .indexOf(bodyTypeId);
    setIsBodyTypeActive(bodyTypeIndex);

    let bodyTypeUpIndex = updateBodyList
      .map((bodyType) => bodyType._id)
      .indexOf(bodyTypeId);
    setIsBodyTypeActive(bodyTypeUpIndex);

    let engIndex = engineConditionList
      .map((engine) => engine.condition.toLowerCase())
      .indexOf(engCond);

    setIsEngActive(engIndex);
  }

  useEffect(() => {
    const fetchBodyTypes = async () => {
      const response = await axios.get(
        Constant.getUrls.getAllBodyTypes +
          `?status=active&limit=500&category=${editVehicleObj?.category?._id}`
      );
      setUpdateBodylist(response.data._getBodyType.docs);
    };

    fetchBodyTypes();

    window.scrollTo(0, 0);
  });

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />

        {checkCategory === "Tractors" ||
        checkCategory === "Construction Equipments" ? (
          <div className="owner-type-container">
            <h5>
              Engine <span style={{ color: "red" }}>*</span>
            </h5>

            <div className="owner-fields-container">
              <div className="owner-type-list">
                {engineConditionList.map((engine, index) => (
                  <div
                    className={
                      isEngActive === index ? "owner-data active" : "owner-data"
                    }
                    key={index}
                    onClick={() => {
                      setEngCond(engine.condition);
                      setIsEngActive(index);
                      setStep((prevState) => prevState + 1);
                    }}
                  >
                    <p style={{ color: isEngActive === index && "#ffffff" }}>
                      {engine.label}
                    </p>
                  </div>
                ))}
              </div>

              <div className="owner-btns">
                <button onClick={prevStep} className="prev-btn">
                  Previous
                </button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        ) : editVehicleObj ? (
          <div className="body-type-container">
            <h5>Body Type</h5>

            <div className="body-fields-container">
              <div className="body-type-list">
                {updateBodyList.map((type, index) => (
                  <div
                    className={
                      isBodyTypeActive === index
                        ? "body-data active"
                        : "body-data"
                    }
                    key={index}
                    onClick={() => {
                      setBodyTypeId(type._id);
                      setIsBodyTypeActive(index);
                      setStep((prevState) => prevState + 1);
                    }}
                  >
                    <p
                      style={{ color: isBodyTypeActive === index && "#ffffff" }}
                    >
                      {type.title}
                    </p>
                  </div>
                ))}
              </div>

              <div className="body-btns">
                <button onClick={prevStep} className="prev-btn">
                  Previous
                </button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="body-type-container">
            <h5>Body Type</h5>

            <div className="body-fields-container">
              <div className="body-type-list">
                {bodytypeList.map((type, index) => (
                  <div
                    className={
                      isBodyTypeActive === index
                        ? "body-data active"
                        : "body-data"
                    }
                    key={index}
                    onClick={() => {
                      setBodyTypeId(type._id);
                      setIsBodyTypeActive(index);
                      setStep((prevState) => prevState + 1);
                    }}
                  >
                    <p
                      style={{ color: isBodyTypeActive === index && "#ffffff" }}
                    >
                      {type.title}
                    </p>
                  </div>
                ))}
              </div>

              <div className="body-btns">
                <button onClick={prevStep} className="prev-btn">
                  Previous
                </button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default Body;
