import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";

import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import FAQToggle from "../components/FAQToggle";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Constant, { imgurl } from "../constants";

import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

// import girlImage from "../assets/girl_image.png";
import gadiLoader from "../assets/gaddideal_loader.png";
import imgPlaceholder from "../assets/img-not-available.jpg";
import locationIcon from "../assets/location-home.png";

import "./sellerhomepage.style.css";

import Lottie from "react-lottie";
import carwaitLottie from "../assets/carwait.json";
import lottieAnimation from "../assets/my-vehicles-lottie.json";
import registerLottie from "../assets/register.json";
import verifiedLottie from "../assets/verified.json";

// import VehicleDetails from "./VehicleDetails";
import { Link } from "react-router-dom";
// import "http://translate.googlr.com/translate_a/elemnt.js?cb=loadGoogleTranslate";
import { toast } from "react-toastify";

import Modal from "react-awesome-modal";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import CloseTab from "../assets/close-tab.png";
import downArrow from "../assets/down-arrow.png";
import Edit from "../assets/edit.png";
import { selectLocation } from "../store/location/location.selector";
import {
  setMobSignInValue,
  setSignUpValue,
} from "../store/signup/signup.action";

import AWS_Translater from "../Helpers/AWS_Translater";

const SellerHomePage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  let user_token = localStorage.getItem("Token");
  const [categoriesData, setCategoriesData] = useState([]);
  const [truckCategoryId, setTruckCategoryId] = useState("");
  const [tractorsCategoryId, setTractorsCategoryId] = useState("");
  const [busesCategoryId, setBusesCategoryId] = useState("");
  const [constructionCategoryId, setConstructionCategoryId] = useState("");
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [advertisementData, setAdvertisementData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [latestTrucksData, setLatestTrucksData] = useState([]);
  const [latestBusesData, setLatestBusesData] = useState([]);
  const [latestTractorsData, setLatestTractorsData] = useState([]);
  const [latestConstructionData, setLatestConstructionData] = useState([]);
  const [faqs, setFAQS] = useState([]);
  const [vehicleId, setvehicleId] = useState("");
  const [token, settoken] = useState(localStorage.getItem("Token"));
  const [locationDropDown, setlocationDropDown] = useState(false);
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);
  const [resendId, setResendId] = useState("");

  const locationCity = useSelector(selectLocation);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [statecities, setstatecities] = useState([]);
  const fetchLocationCities = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllCity + "?limit=5000"
    );
    setstatecities(response?.data?.getAllCities?.docs);
  };
  // for get seller details
  // const [loadingDetails, setLoadingDetails] = useState(false);
  const [userObj, setUserObj] = useState();
  const [BuyerInput, setBuyerInput] = useState(false);
  const [BuyerOtp, setBuyerOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [SellerDetails, setSellerDetails] = useState(false);

  const [userToken, setUserToken] = useState(localStorage.getItem("Token"));

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mob_no, setmob_no] = useState("");
  const [regNo, setRegNo] = useState("");
  const [city, setcity] = useState("");
  const [user_type, setuser_type] = useState("");
  const [isTypeActive, setIsTypeActive] = useState();
  const [seller_id, setseller_id] = useState("");
  const [seller, setSeller] = useState("");
  const [EnableResendOtp, setEnableResendOtp] = useState(false);
  const [timerVisible, settimerVisible] = useState(true);

  const [counter, setCounter] = React.useState(25);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setEnableResendOtp(true);
      settimerVisible(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const dealerType = [
    {
      user_type: "Company",
    },
    {
      user_type: "Sole Proprietor",
    },
    {
      user_type: "Agent/Broker",
    },
    {
      user_type: "Dealer",
    },
    {
      user_type: "Other",
    },
  ];

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  // useEffect(() => {
  //   window
  //     .matchMedia("(max-width: 1000px)")
  //     .addEventListener("change", (e) => setMatches(e.matches));
  // }, []);

  // useEffect(() => {
  //   const getSingleVehicleDetails = async () => {
  //     setLoadingDetails(true);
  //     const res = await axios.get(
  //       "https://gaddideals.brokerinvoice.co.in/api/vehicle/vehicleDetails/" +
  //       vehicleId
  //     );
  //     // console.log(res.data.vehicle.user._id);
  //     // setVehicleDetails(res.data.vehicle);
  //     // setCheckCategory(res.data?.vehicle?.category?.title);
  //     setseller_id(res.data?.vehicle?.user?._id);
  //     setSeller(res.data?.vehicle?.user);

  //     setLoadingDetails(false);
  //   };

  //   getSingleVehicleDetails();
  // }, []);

  //  if buyer is not logged-in

  // if (loadingDetails) {
  //   return "Loading...";
  // }

  //

  const fetchFaqs = async () => {
    const response = await axios.get(Constant.getUrls.getAllFaqs);
    setFAQS(response.data.faq.docs);
  };

  const fetchCategories = async () => {
    const response = await axios.get(Constant.getUrls.getAllCategories);
    setCategoriesData(response.data.category.docs);
  };

  const fetchTestimonials = async () => {
    const response = await axios.get(Constant.getUrls.getAllTestimonials);
    setTestimonialsData(response.data.testimonial.docs);
  };

  const fetchAdvertisements = async () => {
    const response = await axios.get(Constant.getUrls.getAllAdvertisments);
    setAdvertisementData(response.data.advertisment.docs);
  };

  const fetchBrands = async () => {
    const response = await axios.get(Constant.getUrls.getAllTrustedClients);
    setBrandsData(response.data.getAllClients.docs);
  };

  const fetchLatestTrucks = async () => {
    const response = await axios.get(
      `${
        Constant.getUrls.getAllVehicles +
        `?category=63358965981d13bbde27c012&status=approved&is_sold=no&is_featured=yes&is_delete=no&limit=1000`
      }`
    );
    setLatestTrucksData(response.data.vehicle.docs);
  };

  const fetchLatestBuses = async () => {
    const response = await axios.get(
      `${
        Constant.getUrls.getAllLatestVehicles +
        `?category=63358aac981d13bbde27c03a&status=approved&is_sold=no&is_featured=yes&is_delete=no&limit=1000`
      }`
    );
    setLatestBusesData(response.data.vehicle.docs);
  };

  const fetchLatestTractors = async () => {
    const response = await axios.get(
      `${
        Constant.getUrls.getAllLatestVehicles +
        `?category=63358a8c981d13bbde27c031&status=approved&is_sold=no&is_featured=yes&is_delete=no&limit=1000`
      }`
    );
    setLatestTractorsData(response.data.vehicle.docs);
  };

  const fetchLatestContruction = async () => {
    const response = await axios.get(
      `${
        Constant.getUrls.getAllLatestVehicles +
        `?category=63358ad7981d13bbde27c043&status=approved&is_sold=no&is_featured=yes&is_delete=no&limit=1000`
      }`
    );
    setLatestConstructionData(response.data.vehicle.docs);
  };

  const rupee_format = (str) => {
    if (str) {
      var x = str;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else {
      return;
    }
  };

  const filterCurentCities = statecities.filter((data) =>
    data.title.toLowerCase().includes(city.toLowerCase())
  );

  // function loadGoogleTranslate(){
  //   FaGoogle.translate.TranslateElement("intro-title");
  // }
  const validateFields = () => {
    let validateName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let validateMobNo =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

    const cityExists = statecities.find(
      (data) => city.toLowerCase() === data.title.toLowerCase()
    );

    if (!validateName.test(name.trim())) {
      toast.error("please enter valid name");
      return false;
    }
    if (!validateMobNo.test(mob_no)) {
      toast.error("please enter valid mobile number");
      return false;
    }
    if (city === "") {
      toast.error("please enter location");
      return false;
    }
    if (!validateEmail.test(email.trim())) {
      toast.error("please enter valid email id");
      return false;
    }
    if (!cityExists) {
      toast.error("Please select a city");
      return false;
    }
    if (user_type === "") {
      toast.error("please select user type");
      return false;
    }

    return true;
  };

  //for user visiting for the first time
  async function enquiryApi() {
    let user_token = localStorage.getItem("Token");

    if (validateFields()) {
      let payload = {
        vehicleId: seller_id,
        name: name.trim(),
        email: email.trim(),
        mob_no,
        user_type,
        city,
        hash: "ekxpmAB8m9v",
      };
      await axios
        .post(Constant.postUrls.postAllEnquiries, payload)
        .then((result) => {
          console.log(result.data);
          if (result.data.status === "failed") {
            console.log(result.data);
            // toast.error(result.data.message);
            toast.error("You are already a user");
            toast.error("Please sign in for enquiry");
          } else {
            if (result.data.status === "success") {
              console.log(result.data);
              // toast.success(result.data.message);
              setResendId(result.data.data._id);
              setBuyerInput(!BuyerInput); //closing buyer input screen
              setBuyerOtp(!BuyerOtp); //displaying otp screen
            }
          }
        });
    }
  }

  //for already logged user
  async function LoggedenquiryApi() {
    let user_token = localStorage.getItem("Token");

    if (validateFields()) {
      let payload = {
        vehicleId: seller_id,
        name: name.trim(),
        email: email.trim(),
        mob_no,
        user_type,
        city,
        hash: "ekxpmAB8m9v",
      };
      await axios
        .post(Constant.postUrls.postAllEnquiries, payload, {
          headers: {
            Authorization: ` Bearer ${user_token} `,
          },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status === "failed") {
            console.log(result.data);
            toast.error(result.data.message);
          } else {
            if (result.data.status === "success") {
              console.log(result.data);
              // toast.success(result.data.message);
              setResendId(result.data.data._id);
              setBuyerInput(!BuyerInput); //closing buyer input screen
              setBuyerOtp(!BuyerOtp); //displaying otp screen
            }
          }
        });
    }
  }

  function getSingleUserInfo() {
    let user_token = localStorage.getItem("Token");
    axios
      .get(Constant.getUrls.getSingleUser, {
        headers: {
          Authorization: ` Bearer ${user_token} `,
        },
      })
      .then((res) => {
        // console.log("here" + res.data.user.city);
        // console.log(res.data.user);
        setcity(res.data.user.city);
        setname(res.data.user.name);
        setemail(res.data.user.email);
        setmob_no(res.data.user.mob_no);
        setuser_type(res?.data?.user?.user_type);
        setBuyerInput(!BuyerInput); //displaying buyer input detail screen
        // saveBuyer();

        if (res?.data?.user?.user_type) {
          let typeIdx = dealerType
            .map((type) => type.user_type)
            .indexOf(res?.data?.user?.user_type);
          setIsTypeActive(typeIdx);
        }
      });
  }

  //saveBuyer
  const saveBuyer = async () => {
    let payload = {
      seller_id,
      name: name.trim(),
      email: email.trim(),
      mob_no,
      user_type,
      city,
      hash: "ekxpmAB8m9v",
    };
    await axios
      .post(Constant.postUrls.postAllEnquiries, payload)
      .then((result) => {
        if (result.data.status === "failed") {
          toast.error(result.data.message);
          console.log(result);
        } else {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            // setvisibleOTP(!visibleOTP);
            setSellerDetails(!SellerDetails);
            // setOtp(otp);
            // verifyOtp();
            setBuyerOtp(!BuyerOtp);
            // setCounter(59);
            // savePhoneOtp();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const testimonialBuyer = testimonialsData.filter(
    (buyer) => buyer.type === "buyer"
  );

  const faqsBuyer = faqs.filter((buyer) => buyer.type === "buyer");

  function verifyOtp() {
    console.warn({ mob_no });
    let payload = { mob_no, hash: "ekxpmAB8m9v" };
    axios.post(Constant.postUrls.postAllSignins, payload).then((result) => {
      console.log("result", result);
      if (mob_no === "") {
        // notify();
        toast.error("enter moile number");
      } else if (result.data.status === "failed") {
        toast.error(result.data.message);
      } else {
        if (result.data.status === "success") {
          toast.success(result.data.message);

          // setOtp(result.data.otp);
          // setvisibleOTP(!visibleOTP);
          // setvisible(false);

          // setCounter(59);
        }
      }
    });
  }

  function handleChange(o) {
    setOtp(o);
    // console.log(otp);
  }

  function savePhoneOtp() {
    // console.log("otp verified");
    console.warn({ mob_no, otp });
    let payload = { mob_no, otp };
    axios.post(Constant.postUrls.postAllOtps, payload).then((res) => {
      // console.log(res);

      if (res.data.status === "failed") {
        toast.error("incorrect otp");
      } else if (res.data.status === "Success") {
        toast.success(res.data.message);

        setBuyerOtp(!BuyerOtp);
      }
    });
  }
  //for otp page
  const ValidateMobileNo = () => {
    let validateMobNo =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!validateMobNo.test(mob_no)) {
      toast.error("please enter valid mobile number");
      return false;
    } else {
      return true;
    }
  };

  //enquiry otp verify
  function enquiryVerifyOtp() {
    if (ValidateMobileNo()) {
      console.log("otp verified");
      console.warn({ mob_no, otp });
      let payload = { mob_no, otp };
      axios.post(Constant.postUrls.postAllEnquiryOtps, payload).then((res) => {
        console.log(res);

        if (res.data.status === "failed") {
          toast.error("incorrect otp");
        } else if (res.data.status === "Success") {
          toast.success(res.data.message);
          setBuyerOtp(!BuyerOtp); //closingotp screen
          setSellerDetails(!SellerDetails); //displaing seller details
          // saveBuyer();
        }
      });
    }
  }

  //signup otp verify
  function signUpVeryfyOtp() {
    if (ValidateMobileNo()) {
      console.log("otp verified");
      console.warn({ mob_no, otp });
      let payload = { mob_no, otp };
      axios.post(Constant.postUrls.postAllOtps, payload).then((res) => {
        console.log(res);
        localStorage.setItem("Token", res.data.user.accessToken);
        window.location.href = "/loggeduser";
        // if (res.data.status === "failed") {
        // toast.error("incorrect otp");
        // } else if (res.data.status === "Success") {
        // toast.success(res.data.message);
        // setBuyerOtp(!BuyerOtp); //closingotp screen
        // setSellerDetails(!SellerDetails); //displaing seller details
        // saveBuyer();
        // }
      });
    }
  }

  function resendotp() {
    console.warn({ mob_no });
    let payload = { mob_no, hash: "ekxpmAB8m9v", _id: resendId };
    axios
      .post(Constant.postUrls.postEnquiryResendOTP, payload)
      .then((result) => {
        // console.log("result", result);
        if (mob_no === "") {
          toast.error("enter mobile number");
        } else if (result.data.status === "failed") {
          toast.error(result.data.message);
        } else {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            // setOtp(result.data.otp);
            settimerVisible(true);
            setEnableResendOtp(false);
            setCounter(25);
          }
        }
      });
  }

  useEffect(() => {
    fetchCategories();
    fetchTestimonials();
    fetchAdvertisements();
    fetchBrands();
    fetchLatestTrucks();
    fetchLatestBuses();
    fetchLatestTractors();
    fetchLatestContruction();
    fetchFaqs();
    fetchLocationCities();
    // languageTranslation();

    // const converted = Object.assign(
    //   {},
    //   ...categoriesData.map((category) => ({ [category.title]: category }))
    // );

    // setTruckCategoryId(converted.Trucks._id);

    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    // get single seller
    // const getSingleVehicleDetails = async () => {
    //   setLoadingDetails(true);
    //   const res = await axios.get(
    //     "https://gaddideals.brokerinvoice.co.in/api/vehicle/vehicleDetails/" +
    //     vehicleId
    //   );
    //   // console.log(res.data.vehicle.user._id);
    //   // setVehicleDetails(res.data.vehicle);
    //   // setCheckCategory(res.data?.vehicle?.category?.title);
    //   setseller_id(res.data?.vehicle?.user?._id);
    //   setSeller(res.data?.vehicle?.user);
    //   console.log(res.data?.vehicle?.user);

    //   setSellerDetails(true);
    //   setLoadingDetails(false);
    // };

    // getSingleVehicleDetails();
  }, []);

  const defaultOptionsVehicle = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: registerLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: carwaitLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: verifiedLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const filterAdvertisementBuyer = advertisementData.filter(
    (buyer) => buyer.type === "buyer"
  );

  return (
    <div>
      <Navbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>GaddiDeals | Buy &amp; Sell Used Commercial Vehicle</title>
        <meta
          name="description"
          content="GaddiDeals is a next-generation platform that has the purpose to ease the process of the used commercial vehicle market by providing buyers and sellers a platform to buy and sell used commercial vehicles"
        />
        <meta name="author" content="Carapace Technologies" />
        <meta
          property="og:title"
          content="GaddiDeals - Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gaddideals.com/" />
        <meta
          property="og:image"
          content="https://gaddideals.com/hero_BG.png"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GaddiDeals" />
        <meta
          name="twitter:description"
          content="Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta name="twitter:url" content="https://gaddideals.com/" />
        <meta
          name="twitter:image"
          content="https://gaddideals.com/hero_BG.png"
        />
      </Helmet>

      {matches ? (
        <a className="floating-btn" href="tel:9902544445">
          <CallIcon />
        </a>
      ) : (
        <div
          className="floating-btn"
          onClick={() => setOpenPhoneDialog((prevState) => !prevState)}
        >
          {openPhoneDialog && (
            <div
              className={
                openPhoneDialog ? "phone-no-text slide-left" : "phone-no-text"
              }
            >
              <p>+91 99025-44445</p>
            </div>
          )}
          <div>
            <CallIcon />
          </div>
        </div>
      )}

      {BuyerInput && (
        <div>
          <Modal
            visible={BuyerInput}
            width={matches ? "85%" : "35%"}
            effect="fadeInUp"
            onClickAway={() => {
              setBuyerInput(!BuyerInput);
            }}
            containerStyle={{ zIndex: 999999999 }}
          >
            <div className="buyer-dtails-container">
              <img
                src={CloseTab}
                alt=""
                onClick={() => {
                  setBuyerInput(!BuyerInput);
                }}
              ></img>
              <h1>Please share your contact</h1>
              <input
                className="buyer-name"
                onChange={(e) => {
                  setname(e.target.value);
                }}
                value={name}
                type="text"
                placeholder="Name "
              ></input>
              <input
                className="buyer-number"
                placeholder="Phone Number "
                onChange={(e) => {
                  setmob_no(e.target.value);
                }}
                value={mob_no}
                maxLength={10}
              ></input>
              <input
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                value={email}
                type="email"
                className="buyer-email"
                placeholder="Email"
              ></input>

              <div className="buyer-location">
                <input
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                  value={city}
                  placeholder="Location"
                  onFocus={() => {
                    setlocationDropDown(!locationDropDown);
                  }}
                ></input>
                <img src={downArrow} alt="" />
                {locationDropDown && (
                  <div className="sign-up-loaction-drop-down">
                    {filterCurentCities.map((data) => (
                      <p
                        onClick={() => {
                          setcity(data.title);
                          setlocationDropDown(false);
                        }}
                      >
                        {data.title}
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="dealerType">
                <div className="dealer-que">What type of user you are?</div>
                <div className="dealerTypeOption">
                  {dealerType.map((user_types, index) => (
                    <div
                      key={index}
                      className={
                        isTypeActive === index ? "dealer active" : "dealer"
                      }
                      onClick={() => {
                        setuser_type(user_types.user_type);
                        setIsTypeActive(index);
                      }}
                    >
                      <span>{user_types.user_type}</span>
                    </div>
                  ))}
                </div>
              </div>

              <button
                onClick={() => {
                  if (user_token) {
                    LoggedenquiryApi();
                  } else {
                    enquiryApi(); //posting data into enquiry api
                  }
                  setCounter(25);
                }}
              >
                Get Contact Details
              </button>

              {!userToken && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (matches) {
                      dispatch(setMobSignInValue(true));
                    } else {
                      dispatch(setSignUpValue(true));
                    }
                    setBuyerInput((prevState) => !prevState);
                  }}
                >
                  <h6 style={{ margin: 0, color: "#050f56", fontSize: "12px" }}>
                    Already a user? Sign In.
                  </h6>
                </div>
              )}

              {!userToken && (
                <div className="modal-agree-terms">
                  <div>
                    <input type="checkbox" checked />
                  </div>
                  <span>
                    "By clicking on get contact details" you agree with our
                    &nbsp;<a href="/termsandconditions">terms and condition.</a>
                  </span>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
      {BuyerOtp && (
        <div>
          <Modal
            visible={BuyerOtp}
            width={matches ? "85%" : "35%"}
            effect="fadeInUp"
            onClickAway={() => {
              setBuyerOtp(!BuyerOtp);
            }}
          >
            <div className="buyer-otp-container">
              <img
                src={CloseTab}
                alt=""
                onClick={() => {
                  setBuyerOtp(!BuyerOtp);
                }}
              ></img>
              <div className="instruction-text">
                6 digit code sent to mobile number
              </div>
              <div className="buyer-phone-number-input">
                <input
                  placeholder="Enter Phone Number"
                  onChange={(e) => {
                    setmob_no(e.target.value);
                  }}
                  value={mob_no}
                ></input>
                <img
                  src={Edit}
                  alt=""
                  onClick={() => {
                    setBuyerInput(!BuyerInput);
                    setBuyerOtp(!BuyerOtp);
                  }}
                ></img>
              </div>
              <div className="enter-otp-text">Enter OTP to verify</div>
              <OtpInput
                containerStyle="otpStyle"
                inputStyle="otBoxStyle"
                numInputs={6}
                separator={<span></span>}
                value={otp}
                type="number"
                onChange={(e) => {
                  handleChange(e);
                }}
              ></OtpInput>
              <div className="new-otp-text">
                {timerVisible && (
                  <p>
                    Get new OTP in
                    <span className="blue-text"> {counter} sec</span>
                  </p>
                )}
                {EnableResendOtp && (
                  <p
                    onClick={() => {
                      resendotp();
                    }}
                    className="blue-text"
                  >
                    Resend
                  </p>
                )}
              </div>
              <button
                onClick={() => {
                  setOtp("");
                  if (userToken) {
                    enquiryVerifyOtp(); //verify enquiry otp
                  } else {
                    enquiryVerifyOtp();
                    signUpVeryfyOtp(); //hitting both api
                  }
                }}
              >
                Verify
              </button>
            </div>
          </Modal>
        </div>
      )}

      {SellerDetails && (
        <div>
          <Modal
            visible={SellerDetails}
            width={matches ? "85%" : "35%"}
            effect="fadeInUp"
            onClickAway={() => {
              setSellerDetails(!SellerDetails);
            }}
          >
            <div className="sellerDetailsContainer">
              <img
                src={CloseTab}
                alt=""
                onClick={() => {
                  setSellerDetails(!SellerDetails);
                }}
              ></img>
              <div className="userProfilePic">
                <img
                  ssrc={
                    imgurl + userObj?.secondary_name
                      ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      : userObj?.user?.profile_pic_url
                  }
                  alt="profile"
                ></img>
              </div>
              <div className="userName">
                {userObj?.secondary_name
                  ? userObj?.secondary_name
                  : userObj?.user?.name}
              </div>
              <hr></hr>
              <input
                value={
                  userObj?.secondary_name
                    ? userObj?.secondary_name
                    : userObj?.user?.name
                }
                placeholder="Name"
              ></input>
              <input
                value={
                  userObj?.secondary_mob
                    ? userObj?.secondary_mob
                    : userObj?.user?.mob_no
                }
                placeholder="Phone Numer"
              ></input>
              <input
                value={
                  userObj?.secondary_email
                    ? userObj?.secondary_email
                    : userObj?.user.email
                }
                placeholder="Email"
              ></input>
              <input value={regNo} placeholder="Vehicle number" />
            </div>
          </Modal>
        </div>
      )}

      <Banner />
      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="how-it-works-section"
      >
        <div className="header-title">
          <h1 id="intro-title">How It Works</h1>
        </div>

        <div className="how-cards-container gd_container">
          <div
            className="how-card"
            onClick={() => navigate("/BuyUsedCommercialVehicle")}
          >
            <div className="how-card-title">
              <h5>Select the vehicle of your choice</h5>
            </div>

            <div className="how-card-img1">
              <Lottie options={defaultOptions} height="100%" width="100%" />
              {/* <img src={girlImage} alt="girl" /> */}
            </div>

            <div className="how-card-text">
              <p>
                We have a variety of options available on the website and one
                can select the vehicle of their choice
              </p>
            </div>
          </div>

          <div
            className="how-card how-card-2"
            onClick={() => navigate("/BuyUsedCommercialVehicle")}
          >
            <div className="how-card-title">
              <h5>Get the seller details</h5>
            </div>

            <div className="how-card-img2">
              <Lottie options={defaultOptions2} height="100%" width="100%" />
              {/* <img src={girlImage} alt="girl" /> */}
            </div>

            <div className="how-card-text2">
              <p>
                Once the buyer selects the vehicle of their choice, they will
                get the seller details
              </p>
            </div>
          </div>

          <div
            className="how-card how-card-3"
            onClick={() => navigate("/BuyUsedCommercialVehicle")}
          >
            <div className="how-card-title">
              <h5>Directly negotiate with the seller of the vehicle</h5>
            </div>

            <div className="how-card-img">
              <Lottie options={defaultOptions3} height="100%" width="100%" />
              {/* <img src={girlImage} alt="girl" /> */}
            </div>

            <div className="how-card-text">
              <p>
                The buyer can directly negotiate with the seller of the vehicle
                and there will be NO COMMISSION charged to the buyer of the
                vehicle
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="how-it-works-section"
        className="latest-vehicle-section"
      >
        <div className="latest-vehicle-header">
          <h1>Latest Vehicles</h1>
        </div>

        <div className="latest-vehicles-container gd_container">
          <Tabs
            selectedIndex={tabIndex}
            selectedTabClassName="active-bar"
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList className="tablist-container">
              <Tab className="tab">Trucks</Tab>
              <Tab className="tab">Buses</Tab>
              <Tab className="tab">Tractors</Tab>
              <Tab className="tab">Construction Equipments</Tab>
            </TabList>

            <TabPanel className="tab-panel">
              <Swiper
                className="swiper-latest"
                spaceBetween={20}
                slidesPerView={3}
                navigation={true}
                breakpoints={{
                  50: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  999: {
                    slidesPerView: 3,
                  },
                  1368: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
              >
                {latestTrucksData.length <= 0 ? (
                  <div className="no-data-home">
                    <img src={gadiLoader} alt="loader" />
                  </div>
                ) : (
                  <>
                    {latestTrucksData.map((latestTruck) => (
                      <SwiperSlide
                        className="swiper-slide-latest"
                        key={latestTruck._id}
                      >
                        <div key={latestTruck._id} className="latest-card">
                          <div className="home-uid-overlay">
                            <span>{latestTruck.uid}</span>
                          </div>
                          <Link to={`/vehicledetails/${latestTruck._id}`}>
                            <div className="latest-img-wrapper">
                              <img
                                src={
                                  latestTruck.front_side_pic
                                    ? `${imgurl}${latestTruck.front_side_pic}`
                                    : ` ${imgPlaceholder}`
                                }
                                alt={latestTruck?.brand?.title}
                              />
                            </div>
                          </Link>
                          <div className="latest-info">
                            <div className="latest-header">
                              <div className="latest-title">
                                <h5 className="notranslate">
                                  <AWS_Translater
                                    _text={
                                      latestTruck?.brand?.title +
                                      " " +
                                      latestTruck?.model?.name
                                    }
                                  />
                                </h5>
                                <div className="latest-location">
                                  <img src={locationIcon} alt="location icon" />
                                  <span className="notranslate">
                                    <AWS_Translater
                                      _text={latestTruck?.city?.title}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p>₹ {rupee_format(latestTruck.selling_price)}</p>
                            </div>

                            <button
                              onClick={() => {
                                setUserObj(latestTruck);
                                setseller_id(latestTruck._id);
                                setRegNo(latestTruck?.reg_no);
                                if (userToken) {
                                  getSingleUserInfo();
                                } else {
                                  setBuyerInput(!BuyerInput);
                                }
                              }}
                            >
                              Get Seller Details
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel className="tab-panel">
              <Swiper
                className="swiper-latest"
                spaceBetween={20}
                slidesPerView={3}
                navigation={true}
                breakpoints={{
                  50: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  999: {
                    slidesPerView: 3,
                  },
                  1368: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
              >
                {latestBusesData.length <= 0 ? (
                  <div className="no-data-home">
                    <img src={gadiLoader} alt="loader" />
                  </div>
                ) : (
                  <>
                    {latestBusesData.map((latestBuses) => (
                      <SwiperSlide
                        className="swiper-slide-latest"
                        key={latestBuses._id}
                      >
                        <div key={latestBuses._id} className="latest-card">
                          <div className="home-uid-overlay">
                            <span>{latestBuses.uid}</span>
                          </div>
                          <Link to={`/vehicledetails/${latestBuses._id}`}>
                            <div className="latest-img-wrapper">
                              <img
                                src={
                                  latestBuses.front_side_pic
                                    ? `${imgurl}${latestBuses.front_side_pic}`
                                    : ` ${imgPlaceholder}`
                                }
                                alt={latestBuses?.brand?.title}
                              />
                            </div>
                          </Link>
                          <div className="latest-info">
                            <div className="latest-header">
                              <div className="latest-title">
                                <h5 className="notranslate">
                                  <AWS_Translater
                                    _text={
                                      latestBuses?.brand?.title +
                                      " " +
                                      latestBuses?.model?.name
                                    }
                                  />
                                </h5>

                                <div className="latest-location">
                                  <img src={locationIcon} alt="location icon" />
                                  <span className="notranslate">
                                    <AWS_Translater
                                      _text={latestBuses?.city?.title}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p>₹ {rupee_format(latestBuses.selling_price)}</p>
                            </div>

                            <button
                              onClick={() => {
                                // userToken ? setSellerDetails(!SellerDetails) : setBuyerInput(!BuyerInput)
                                // setvehicleId(latestBuses._id)
                                setUserObj(latestBuses);
                                setseller_id(latestBuses._id);
                                setRegNo(latestBuses?.reg_no);

                                if (userToken) {
                                  getSingleUserInfo();
                                } else {
                                  setBuyerInput(!BuyerInput);
                                }
                              }}
                            >
                              Get Seller Details
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel className="tab-panel">
              <Swiper
                className="swiper-latest"
                spaceBetween={20}
                slidesPerView={3}
                navigation={true}
                breakpoints={{
                  50: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  999: {
                    slidesPerView: 3,
                  },
                  1368: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
              >
                {latestTractorsData.length <= 0 ? (
                  <div className="no-data-home">
                    <img src={gadiLoader} alt="loader" />
                  </div>
                ) : (
                  <>
                    {latestTractorsData.map((latestTractor) => (
                      <SwiperSlide
                        className="swiper-slide-latest"
                        key={latestTractor._id}
                      >
                        <div key={latestTractor._id} className="latest-card">
                          <div className="home-uid-overlay">
                            <span>UID-{latestTractor.uid}</span>
                          </div>
                          <Link to={`/vehicledetails/${latestTractor._id}`}>
                            <div className="latest-img-wrapper">
                              <img
                                src={
                                  latestTractor.front_side_pic
                                    ? `${imgurl}${latestTractor.front_side_pic}`
                                    : ` ${imgPlaceholder}`
                                }
                                alt={latestTractor?.brand?.title}
                              />
                            </div>
                          </Link>
                          <div className="latest-info">
                            <div className="latest-header">
                              <div className="latest-title">
                                <h5 className="notranslate">
                                  <AWS_Translater
                                    _text={
                                      latestTractor?.brand?.title +
                                      " " +
                                      latestTractor?.model?.name
                                    }
                                  />
                                </h5>

                                <div className="latest-location">
                                  <img src={locationIcon} alt="location icon" />
                                  <span className="notranslate">
                                    <AWS_Translater
                                      _text={latestTractor?.city?.title}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p>
                                ₹ {rupee_format(latestTractor.selling_price)}
                              </p>
                            </div>

                            <button
                              onClick={() => {
                                // userToken ? setSellerDetails(!SellerDetails) : setBuyerInput(!BuyerInput)
                                // setvehicleId(latestTractor._id)
                                setUserObj(latestTractor);
                                setseller_id(latestTractor._id);
                                setRegNo(latestTractor?.reg_no);

                                if (userToken) {
                                  getSingleUserInfo();
                                } else {
                                  setBuyerInput(!BuyerInput);
                                }
                              }}
                            >
                              Get Seller Details
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel className="tab-panel">
              <Swiper
                className="swiper-latest"
                spaceBetween={20}
                slidesPerView={3}
                navigation={true}
                breakpoints={{
                  50: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  999: {
                    slidesPerView: 3,
                  },
                  1368: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
              >
                {latestConstructionData.length <= 0 ? (
                  <div className="no-data-home">
                    <img src={gadiLoader} alt="loader" />
                  </div>
                ) : (
                  <>
                    {latestConstructionData.map((latestConstruction) => (
                      <SwiperSlide
                        className="swiper-slide-latest"
                        key={latestConstruction._id}
                      >
                        <div
                          key={latestConstruction._id}
                          className="latest-card"
                        >
                          <div className="home-uid-overlay">
                            <span>UID-{latestConstruction.uid}</span>
                          </div>
                          <Link
                            to={`/vehicledetails/${latestConstruction._id}`}
                          >
                            <div className="latest-img-wrapper">
                              <img
                                src={
                                  latestConstruction.front_side_pic
                                    ? `${imgurl}${latestConstruction.front_side_pic}`
                                    : ` ${imgPlaceholder}`
                                }
                                alt={latestConstruction?.brand?.title}
                              />
                            </div>
                          </Link>
                          <div className="latest-info">
                            <div className="latest-header">
                              <div className="latest-title">
                                <h5 className="notranslate">
                                  <AWS_Translater
                                    _text={
                                      latestConstruction?.brand?.title +
                                      " " +
                                      latestConstruction?.model?.name
                                    }
                                  />
                                </h5>

                                <div className="latest-location">
                                  <img src={locationIcon} alt="location icon" />
                                  <span className="notranslate">
                                    <AWS_Translater
                                      _text={latestConstruction?.city?.title}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p>
                                ₹{" "}
                                {rupee_format(latestConstruction.selling_price)}
                              </p>
                            </div>

                            <button
                              onClick={() => {
                                // userToken ? setSellerDetails(!SellerDetails) : setBuyerInput(!BuyerInput)
                                // setvehicleId(latestConstruction._id)
                                setUserObj(latestConstruction);
                                setseller_id(latestConstruction._id);
                                setRegNo(latestConstruction?.reg_no);

                                if (userToken) {
                                  getSingleUserInfo();
                                } else {
                                  setBuyerInput(!BuyerInput);
                                }
                              }}
                            >
                              Get Seller Details
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </TabPanel>
          </Tabs>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="our-categories-section gd_container"
      >
        <div className="our-categories-header">
          <h1>Our Categories</h1>
        </div>

        <div className="our-categories-container">
          {categoriesData.map((category) => {
            return (
              <div className="our-category" key={category._id}>
                <a
                  href={
                    "/BuyUsedCommercialVehicle?category=" +
                    category._id +
                    "&title=" +
                    category.title
                  }
                >
                  <img
                    src={`${imgurl}${category.image}`}
                    alt={category?.title}
                  />
                </a>
                <div className="our-category-title">
                  <h6>{category?.title}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Customer Review */}
      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="customer-review-section gd_container"
      >
        <div className="customer-review-header">
          <h1>Customer Reviews</h1>
        </div>

        <div className="customer-review-container">
          <Swiper
            className="testimonial-slide"
            centeredSlides={true}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            breakpoints={{
              50: {
                slidesPerView: "auto",
                spaceBetween: 10,
              },
              820: {
                slidesPerView: "auto",
                spaceBetween: 25,
              },
              1368: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: "auto",
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {testimonialBuyer.map((testimonial) => (
              <SwiperSlide
                key={testimonial._id}
                className="customer-swiper-slide"
              >
                <div className="customer-card">
                  <div className="profile-img">
                    <img
                      src={`${imgurl}${testimonial.image}`}
                      alt={testimonial?.title}
                    />
                  </div>
                  <div className="profile-name-location">
                    <h6>{testimonial?.title}</h6>
                    <div className="profile-location">
                      <img src={locationIcon} alt={testimonial.location} />
                      <span>{testimonial.location}</span>
                    </div>
                  </div>
                  <div className="profile-line"></div>
                  <div className="profile-review">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: testimonial.description,
                      }}
                    ></p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* Trusted Brands */}
      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="trusted-brands-section"
      >
        <div className="trusted-brand-header">
          <h1>Trusted Clients</h1>
        </div>

        <div className="trusted-brands-container gd_container">
          <Swiper
            slidesPerView={5}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              820: {
                slidesPerView: 3.8,
                spaceBetween: 20,
              },
              50: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              999: {
                slidesPerView: 5,
              },
              1368: {
                slidesPerView: 6,
              },
            }}
            modules={[Autoplay]}
          >
            {brandsData.map((brand) => (
              <SwiperSlide key={brand._id} className="brands-swiper-slide">
                <div className="brand-wrapper">
                  <img src={`${imgurl}${brand?.image}`} alt={brand?.name} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* FAQ Section */}
      <div
        data-aos="fade-up"
        data-aos-delay="250"
        data-aos-duration="1500"
        className="faq-section gd_container"
      >
        <div className="faq-header">
          <h1>Frequently Asked Questions</h1>
        </div>

        <div className="faq-container">
          {faqsBuyer.map((faq) => (
            <FAQToggle key={faq._id} question={faq.question}>
              <div className="answer">
                <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </div>
            </FAQToggle>
          ))}
        </div>
      </div>

      {/*Truck Image  */}
      <div className="truck-section gd_container">
        <Swiper
          grabCursor={true}
          modules={[Autoplay]}
          className="advertise-swiper"
        >
          {filterAdvertisementBuyer.map((advertise) => (
            <SwiperSlide key={advertise._id} className="advertise-slide">
              <div className="image-wrapper">
                <img src={`${imgurl}${advertise.image}`} alt="truck" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Footer */}
      <Footer />
      {locationDropDown && (
        <div
          className="mob-menue-overlay"
          onClick={() => {
            setlocationDropDown(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default SellerHomePage;
