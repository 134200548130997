import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import "./imageupload.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import { imgurl } from "../../constants";

import cloudIcon from "../../assets/cloud.png";

import Stepper from "../Stepper/Stepper";

import Cropper from "react-easy-crop";
import getCroppedImg from "../../Helpers/cropImage";

import Modal from "react-awesome-modal";
import "react-image-crop/dist/ReactCrop.css";

const ImageUpload = ({
  setStep,
  step,
  checkCategory,
  nextStep,
  prevStep,
  setIsFrontsideImgClicked,
  frontSideImgURL,
  setFrontSideImgURL,
  frontsideCropImg,
  isFrontsideImgClicked,
  isEngineImgClicked,
  isBacksideImgClicked,
  isfronttyreLeftImgClicked,
  isfronttyreRightImgClicked,
  isSidePicLeftClicked,
  isSidePicRightClicked,
  setFrontsideCropImg,
  setIsEngineImgClicked,
  engineImgURL,
  setEngineImgURL,
  engineCropImg,
  setEngineCropImg,
  setIsBacksideImgClicked,
  backsideImgURL,
  setBacksideImgURL,
  backsideCropImg,
  setBacksideCropImg,
  setIsFronttyreLeftImgClicked,
  fronttyreLeftImgURL,
  setFronttyreLeftImgURL,
  fronttyreLeftCropImg,
  setFronttyreLeftCropImg,
  setIsFronttyreRightImgClicked,
  fronttyreRightImgURL,
  setFronttyreRightImgURL,
  fronttyreRightCropImg,
  setFronttyreRightCropImg,
  setIsSidePicLeftClicked,
  sidePicLeftImgURL,
  setSidePicLeftImgURL,
  sidePicLeftCropImg,
  setSidePicLeftCropImg,
  setIsSidePicRightClicked,
  sidePicRightImgURL,
  setSidePicRightImgURL,
  sidePicRightCropImg,
  setSidePicRightCropImg,
  editVehicleObj,
}) => {
  const [frontIsOpen, setFrontIsOpen] = useState(false);
  const [engineIsOpen, setEngineIsOpen] = useState(false);
  const [backsideIsOpen, setBacksideIsOpen] = useState(false);
  const [fronttyreLeftIsOpen, setFronttyreLeftIsOpen] = useState(false);
  const [fronttyreRightIsOpen, setFronttyreRightIsOpen] = useState(false);
  const [sidePicLeftIsOpen, setSidePicLeftIsOpen] = useState(false);
  const [sidePicRightIsOpen, setSidePicRightIsOpen] = useState(false);

  const [frontsideCrop, setFrontSideCrop] = useState({ x: 0, y: 0 });
  const [engineCrop, setEngineCrop] = useState({ x: 0, y: 0 });
  const [backsideCrop, setBacksideCrop] = useState({ x: 0, y: 0 });
  const [fronttyreLeftCrop, setFronttyreLeftCrop] = useState({ x: 0, y: 0 });
  const [fronttyreRightCrop, setFronttyreRightCrop] = useState({ x: 0, y: 0 });
  const [sidePicLeftCrop, setSidePicLeftCrop] = useState({ x: 0, y: 0 });
  const [sidePicRightCrop, setSidePicRightCrop] = useState({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const frontsideRef = useRef(null);
  const engineRef = useRef(null);
  const backsideRef = useRef(null);
  const fronttyreLeftRef = useRef(null);
  const fronttyreRightRef = useRef(null);
  const sidePicLeftRef = useRef(null);
  const sidePicRightRef = useRef(null);

  const CONTAINER_HEIGHT = 300;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    window.scrollTo(0, 0);
  }, []);

  // frontside cropped
  const frontsideCroppedImage = useCallback(async () => {
    setFrontIsOpen((prevState) => !prevState);

    try {
      const croppedImage = await getCroppedImg(
        frontSideImgURL,
        croppedAreaPixels
      );
      setFrontsideCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // Engine Cropped
  const engineCroppedImage = useCallback(async () => {
    setEngineIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(engineImgURL, croppedAreaPixels);
      setEngineCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // Backside cropped
  const backsideCroppedImage = useCallback(async () => {
    setBacksideIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(
        backsideImgURL,
        croppedAreaPixels
      );
      setBacksideCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // fronttyre left cropped
  const fronttyreLeftCroppedImage = useCallback(async () => {
    setFronttyreLeftIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(
        fronttyreLeftImgURL,
        croppedAreaPixels
      );
      setFronttyreLeftCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // fronttyre Right cropped
  const fronttyreRightCroppedImage = useCallback(async () => {
    setFronttyreRightIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(
        fronttyreRightImgURL,
        croppedAreaPixels
      );
      setFronttyreRightCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // sidePic left cropped
  const sidePicLeftCroppedImage = useCallback(async () => {
    setSidePicLeftIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(
        sidePicLeftImgURL,
        croppedAreaPixels
      );
      setSidePicLeftCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  // sidePic right cropped
  const sidePicRightCroppedImage = useCallback(async () => {
    setSidePicRightIsOpen((prevState) => !prevState);
    try {
      const croppedImage = await getCroppedImg(
        sidePicRightImgURL,
        croppedAreaPixels
      );
      setSidePicRightCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  /* Handle Click */
  const handleFrontsideClick = () => {
    frontsideRef.current.click();
  };

  const handleEngineClick = () => {
    engineRef.current.click();
  };

  const handleBacksideClick = () => {
    backsideRef.current.click();
  };

  const handleFronttyreLeftClick = () => {
    fronttyreLeftRef.current.click();
  };

  const handleFronttyreRightClick = () => {
    fronttyreRightRef.current.click();
  };

  const handleSidePicLeftClick = () => {
    sidePicLeftRef.current.click();
  };

  const handleSidePicRightClick = () => {
    sidePicRightRef.current.click();
  };

  /* Handle Image Change */
  const handleImageChange = (e) => {
    if (e.target.name === "frontside") {
      setFrontIsOpen(true);
      setFrontSideImgURL(URL.createObjectURL(e.target.files[0]));
      setIsFrontsideImgClicked((prevState) => !prevState);
    }

    if (e.target.name === "engine") {
      setEngineIsOpen(true);
      setEngineImgURL(URL.createObjectURL(e.target.files[0]));
      setIsEngineImgClicked((prevState) => !prevState);
    }

    if (e.target.name === "backside") {
      setBacksideIsOpen(true);
      setBacksideImgURL(URL.createObjectURL(e.target.files[0]));
      setIsBacksideImgClicked((prevState) => !prevState);
    }

    if (e.target.name === "fronttyreleft") {
      setFronttyreLeftIsOpen(true);
      setFronttyreLeftImgURL(URL.createObjectURL(e.target.files[0]));
      setIsFronttyreLeftImgClicked((prevState) => !prevState);
    }

    if (e.target.name === "fronttyreright") {
      setFronttyreRightIsOpen(true);
      setFronttyreRightImgURL(URL.createObjectURL(e.target.files[0]));
      setIsFronttyreRightImgClicked((prevState) => !prevState);
    }

    if (e.target.name === "sidepicleft") {
      setSidePicLeftIsOpen(true);
      setSidePicLeftImgURL(URL.createObjectURL(e.target.files[0]));
      setIsSidePicLeftClicked((prevState) => !prevState);
    }

    if (e.target.name === "sidepicright") {
      setSidePicRightIsOpen(true);
      setSidePicRightImgURL(URL.createObjectURL(e.target.files[0]));
      setIsSidePicRightClicked((prevState) => !prevState);
    }
  };

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="upload-container">
          <h5>Upload Your Vehicle Images</h5>
        </div>

        <div className="upload-fields-container">
          <div className="upload-row-one">
            <div className="upload-img">
              <h6>
                Side Picture <span style={{ color: "red" }}>*</span>
              </h6>

              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={sidePicLeftIsOpen}
                onClickAway={() => {
                  setSidePicLeftIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={sidePicLeftImgURL}
                    crop={sidePicLeftCrop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setSidePicLeftCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // onMediaLoaded={(mediaSize) => {
                    //   // Adapt zoom based on media size to fit max height
                    //   setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
                    // }}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={sidePicLeftCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() =>
                      setSidePicLeftIsOpen((prevState) => !prevState)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: sidePicLeftCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleSidePicLeftClick}
              >
                <input
                  ref={sidePicLeftRef}
                  type="file"
                  name="sidepicleft"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isSidePicLeftClicked
                      ? sidePicLeftCropImg
                      : sidePicLeftImgURL
                      ? imgurl + sidePicLeftImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>
            </div>

            <div className="upload-img">
              <h6>
                Front Side Picture <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                ref={frontsideRef}
                type="file"
                name="frontside"
                onChange={handleImageChange}
                accept="image/*"
                hidden
              />
              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={frontIsOpen}
                onClickAway={() => {
                  setFrontIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={frontSideImgURL}
                    crop={frontsideCrop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setFrontSideCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // onMediaLoaded={(mediaSize) => {
                    //   // Adapt zoom based on media size to fit max height
                    //   setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
                    // }}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={frontsideCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() => setFrontIsOpen((prevState) => !prevState)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
              <div
                className="upload-box"
                style={{
                  padding: frontsideCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleFrontsideClick}
              >
                <img
                  src={
                    isFrontsideImgClicked
                      ? frontsideCropImg
                      : frontSideImgURL
                      ? imgurl + frontSideImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>
            </div>

            {/* <div className="upload-img">
              <h6>
                Engine Picture <span style={{ color: "red" }}>*</span>
              </h6>

              <input
                ref={engineRef}
                type="file"
                name="engine"
                onChange={handleImageChange}
                accept="image/*"
                hidden
              />

              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={engineIsOpen}
                onClickAway={() => {
                  setEngineIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={engineImgURL}
                    crop={engineCrop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setEngineCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={engineCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() => setEngineIsOpen((prevState) => !prevState)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
              <div
                className="upload-box"
                style={{
                  padding: engineCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleEngineClick}
              >
                <img
                  src={
                    isEngineImgClicked
                      ? engineCropImg
                      : engineImgURL
                      ? imgurl + engineImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>
            </div> */}

            {/* <div className="upload-img">
              <h6>Back Side Picture</h6>

              <input
                ref={backsideRef}
                type="file"
                name="backside"
                onChange={handleImageChange}
                accept="image/*"
                hidden
              />

              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={backsideIsOpen}
                onClickAway={() => {
                  setBacksideIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={backsideImgURL}
                    crop={backsideCrop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setBacksideCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={backsideCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() => setBacksideIsOpen((prevState) => !prevState)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: backsideCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleBacksideClick}
              >
                <img
                  src={
                    isBacksideImgClicked
                      ? backsideCropImg
                      : backsideImgURL
                      ? imgurl + backsideImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>
            </div> */}
          </div>

          <div className="upload-row-two">
            <h6>Tyre and Engine Picture.</h6>
            <div className="upload-front-tyre">
              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={fronttyreLeftIsOpen}
                onClickAway={() => {
                  setFronttyreLeftIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={fronttyreLeftImgURL}
                    crop={fronttyreLeftCrop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setFronttyreLeftCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // onMediaLoaded={(mediaSize) => {
                    //   // Adapt zoom based on media size to fit max height
                    //   setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
                    // }}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={fronttyreLeftCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() =>
                      setFronttyreLeftIsOpen((prevState) => !prevState)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: fronttyreLeftCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleFronttyreLeftClick}
              >
                <input
                  ref={fronttyreLeftRef}
                  type="file"
                  name="fronttyreleft"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isfronttyreLeftImgClicked
                      ? fronttyreLeftCropImg
                      : fronttyreLeftImgURL
                      ? imgurl + fronttyreLeftImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>

              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={engineIsOpen}
                onClickAway={() => {
                  setEngineIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={engineImgURL}
                    crop={engineCrop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setEngineCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={engineCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() => setEngineIsOpen((prevState) => !prevState)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: engineCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleEngineClick}
              >
                <input
                  ref={engineRef}
                  type="file"
                  name="engine"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isEngineImgClicked
                      ? engineCropImg
                      : engineImgURL
                      ? imgurl + engineImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>

              {/* <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={fronttyreRightIsOpen}
                onClickAway={() => {
                  setFronttyreRightIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={fronttyreRightImgURL}
                    crop={fronttyreRightCrop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setFronttyreRightCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={fronttyreRightCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() =>
                      setFronttyreRightIsOpen((prevState) => !prevState)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal> */}

              {/* <div
                className="upload-box"
                style={{
                  padding: fronttyreRightCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleFronttyreRightClick}
              >
                <input
                  ref={fronttyreRightRef}
                  type="file"
                  name="fronttyreright"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isfronttyreRightImgClicked
                      ? fronttyreRightCropImg
                      : fronttyreRightImgURL
                      ? imgurl + fronttyreRightImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div> */}
            </div>
          </div>

          <div className="upload-row-three">
            <h6>Backside Picture</h6>
            <div className="upload-front-tyre">
              <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={backsideIsOpen}
                onClickAway={() => {
                  setBacksideIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={backsideImgURL}
                    crop={backsideCrop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setBacksideCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    // onMediaLoaded={(mediaSize) => {
                    //   // Adapt zoom based on media size to fit max height
                    //   setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
                    // }}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={backsideCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() => setBacksideIsOpen((prevState) => !prevState)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: backsideCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleBacksideClick}
              >
                <input
                  ref={backsideRef}
                  type="file"
                  name="backside"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isBacksideImgClicked
                      ? backsideCropImg
                      : backsideImgURL
                      ? imgurl + backsideImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div>

              {/* <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={sidePicLeftIsOpen}
                onClickAway={() => {
                  setSidePicLeftIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={sidePicLeftImgURL}
                    crop={sidePicLeftCrop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setSidePicLeftCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={sidePicLeftCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() =>
                      setSidePicLeftIsOpen((prevState) => !prevState)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: sidePicLeftCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleSidePicLeftClick}
              >
                <input
                  ref={sidePicLeftRef}
                  type="file"
                  name="sidepicleft"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isSidePicLeftClicked
                      ? sidePicLeftCropImg
                      : sidePicLeftImgURL
                      ? imgurl + sidePicLeftImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div> */}

              {/* <Modal
                width={matches ? "80%" : "90%"}
                height={matches ? "50%" : "100%"}
                effect="fadeInUp"
                visible={sidePicRightIsOpen}
                onClickAway={() => {
                  setSidePicRightIsOpen((prevState) => !prevState);
                }}
              >
                <div>
                  <Cropper
                    image={sidePicRightImgURL}
                    crop={sidePicRightCrop}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setSidePicRightCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="modal-buttons">
                  <button onClick={sidePicRightCroppedImage}>Save</button>
                  <button
                    className="cancel-modal-button"
                    onClick={() =>
                      setSidePicRightIsOpen((prevState) => !prevState)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal>

              <div
                className="upload-box"
                style={{
                  padding: sidePicRightCropImg ? "0px" : "",
                  width: matches ? "70px" : "120px",
                  height: matches ? "70px" : "120px",
                }}
                onClick={handleSidePicRightClick}
              >
                <input
                  ref={sidePicRightRef}
                  type="file"
                  name="sidepicright"
                  onChange={handleImageChange}
                  accept="image/*"
                  hidden
                />
                <img
                  src={
                    isSidePicRightClicked
                      ? sidePicRightCropImg
                      : sidePicRightImgURL
                      ? imgurl + sidePicRightImgURL
                      : cloudIcon
                  }
                  alt="icon"
                />
              </div> */}
            </div>
          </div>

          <div className="upload-btns">
            <button onClick={prevStep} className="prev-btn">
              Previous
            </button>
            <button onClick={nextStep}>Next</button>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ImageUpload;
