import React from "react";
import locationIcon from "../assets/location.png";
import shipping from "../assets/shipping.png";
import "./LoggedUser.css";
import "./UserVehicles.style.css";
import "./vehiclecard.styles.css";
import logout from "../assets/logout.png";
import myVehicleEnqIco from "../assets/myvehicle_enq.png";
import next_arrow from "../assets/next_arrow.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clipboard from "../assets/clipboard.png";
import lockPasswordIcon from "../assets/lock-password.png";
import animationData from "../assets/my-vehicles-lottie.json";
import soldtabIcon from "../assets/soldicon.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Constant, { imgurl } from "../constants";
import init from "../Helpers/WindowToken";
import AWS_Translater from "../Helpers/AWS_Translater";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function UserVehicles() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [name, setname] = useState("");
  // for get name
  const getDetails = () => {
    if (init() === "success") {
      axios.get(Constant.getUrls.getSingleUser).then((res) => {
        setname(res.data.user);
      });
    }
  };
  function logoutAccount() {
    localStorage.clear();
    window.location.href = "/";
  }
  const [dataAvailable, setdataAvailable] = useState(false);
  // const [array,setarray]=useState("");
  const [vehicleData, setvehicleData] = useState([]);
  const [vehicleName, setvehicleName] = useState("");
  const [userToken, setUserToken] = useState(localStorage.getItem("Token"));
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [vehicleId, setVehicleId] = useState("");

  const [pageNo, setPageNo] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const [loading, setLoading] = useState(false);
  //for vehicle data

  const navigate = useNavigate();

  const getMyVehicleDetails = async () => {
    setLoading(true);
    await axios
      .get(
        Constant.getUrls.getAllVehicles +
          `/my_vehicles?is_sold=no&is_delete=no&sort=true`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setvehicleData(res.data.getMyVehicle.docs);
        res.data.getMyVehicle.docs.forEach((item, index) => {
          setvehicleName(item.model);
          setdataAvailable(true);
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
    getMyVehicleDetails();
  }, []);

  const rupee_format = (str) => {
    if (str) {
      var x = str;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else {
      return;
    }
  };

  const handleMarkAsSold = () => {
    setOpenDialog((prevState) => !prevState);
  };

  const dialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteVehicleDialog = () => {
    setDeleteDialog((prevState) => !prevState);
  };

  const dialogDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleMarkAsSoldPut = async (id) => {
    const response = await axios.put(
      Constant.getUrls.getAllVehicles + `/markAsSold/${id}`
    );

    if (response.data.status === "success") {
      toast.success(response.data.message);
      setOpenDialog(false);
      getMyVehicleDetails();
    }
  };

  const handleVehicleDelete = async (vehicleId) => {
    const response = await axios.delete(
      Constant.getUrls.getAllVehicles + `/delete/${vehicleId}`
    );

    if (response.data.status === "success") {
      toast.success(response.data.message);
      setDeleteDialog(false);
      getMyVehicleDetails();
    }
  };

  const fetchItems = async () => {
    const response = await axios.get(
      `${Constant.getUrls.getAllVehicles}/my_vehicles?page=${pageNo}&limit=10`
    );

    return response?.data?.getAllVehicles?.docs;
  };

  const fetchData = async () => {
    const vehicles = await fetchItems();

    setvehicleData([...vehicleData, ...vehicles]);

    if (vehicles.length === 0 || vehicles.length < 10) {
      setHasMore((prevState) => !prevState);
    }

    setPageNo(pageNo + 1);
  };

  if (loading) {
    return (
      <div className="loading-effect">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="outside-container">
        <div className="profile-container gd_container">
          <div className="left-profile-container">
            <div className="upper-div">
              <p className="hello-text">Hello</p>
              <Link to="/LoggedUser">
                <p className="user-name-left-div">{name.name}</p>
              </Link>
            </div>
            <div className="options-div">
              <div className="my-vehicle-div">
                <img className="shipping-img" src={shipping} alt=""></img>
                <Link to="/UserVehicles" className="my-vehicle-text">
                  <span> My Vehicles</span>
                </Link>
                <Link to="/UserVehicles">
                  <img className="next-arrow-img" src={next_arrow} alt="" />
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={soldtabIcon} alt=""></img>
                <Link to="/mysoldvehicles" className="my-order-text">
                  <span>My Sold Vehicles</span>
                </Link>
                <Link to="/mysoldvehicles">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={clipboard} alt=""></img>
                <Link to="/Userorder" className="my-order-text">
                  <span>My Buying Enquiries</span>
                </Link>
                <Link to="/Userorder">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={myVehicleEnqIco}
                  alt=""
                ></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span>My Listing Enquiries</span>
                </Link>
                <Link to="/myvehicleenq">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={lockPasswordIcon}
                  alt=""
                ></img>
                <Link to="/changepassword" className="my-order-text">
                  <span>Change Password</span>
                </Link>
                <Link to="/changepassword">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={logout} alt=""></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span
                    onClick={() => {
                      logoutAccount();
                    }}
                  >
                    Sign out
                  </span>
                </Link>
                <Link to="">
                  <img
                    className="next-arrow-img hidden"
                    src={next_arrow}
                    alt=""
                  ></img>
                </Link>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="250"
            data-aos-duration="1500"
            className="right-vehicle-container"
          >
            <div className="user-vehicle-header">
              <h1>My Vehicles</h1>
            </div>

            <div style={{ width: "100%" }}>
              <InfiniteScroll
                dataLength={vehicleData.length}
                next={fetchData}
                style={{ width: "100%" }}
                hasMore={hasMore}
              >
                {dataAvailable ? (
                  <div className="vehicle-card-container">
                    {vehicleData.map((item) => (
                      <div className="card" key={item._id}>
                        <div className="user-vehicle-uid">
                          <span>UID-{item?.uid}</span>
                        </div>
                        <div className="card-wrapper-vehicles">
                          <Link to={`/vehicledetails/${item._id}`}>
                            <div className="card-img-wrapper uservehicle">
                              <img
                                src={imgurl + item.front_side_pic}
                                alt="truck"
                              />
                            </div>
                          </Link>

                          <div className="card-info">
                            <div className="card-info-header">
                              <div className="card-info-title">
                                <h1>
                                  {!item["brand"] && !item["model"]
                                    ? `${item.brand_other} ${item.model_other}`
                                    : !item["model"]
                                    ? `${item.brand.title} ${item.model_other}`
                                    : `${item.brand.title} ${item.model.name}`}
                                </h1>
                                <div className="location">
                                  <img src={locationIcon} alt="location icon" />
                                  {/* fetching city name  */}
                                  <span className="notranslate">
                                    <AWS_Translater _text={item?.city?.title} />
                                  </span>
                                </div>
                              </div>

                              <div className="review-container">
                                {item?.is_sold === "yes" ? (
                                  <div className="sold-btn">Sold</div>
                                ) : (
                                  <div className="sold-btn">
                                    <div
                                      onClick={() => {
                                        handleMarkAsSold();
                                        setVehicleId(item._id);
                                      }}
                                    >
                                      Mark as Sold
                                    </div>
                                  </div>
                                )}

                                <div className="card-publish-review">
                                  {item?.inspection_status === "published" ? (
                                    <div className="review">
                                      <strong>Under Review</strong>
                                    </div>
                                  ) : item?.inspection_status === "draft" ? (
                                    <div className="review">
                                      <strong>Draft</strong>
                                    </div>
                                  ) : (
                                    <div className="review published">
                                      <strong>Approved</strong>
                                    </div>
                                  )}

                                  {item?.inspection_status !== "published" ? (
                                    <span>
                                      (Approved&nbsp;
                                      {moment(item?.updatedAt).format(
                                        "MMMM Do YYYY"
                                      )}
                                      )
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="card-price">
                              <h3>₹{rupee_format(item?.selling_price)} </h3>
                            </div>
                            <div className="card-stats">
                              <div className="stat">
                                <span>
                                  {item?.km_driven ? item?.km_driven : "N/A"}
                                  &nbsp; km
                                </span>
                              </div>
                              <div className="stat">
                                <span>
                                  {item?.no_of_owner
                                    ? item?.no_of_owner
                                    : "N/A"}
                                  &nbsp; owner
                                </span>
                              </div>
                              {item?.category?.title === "Trucks" && (
                                <div className="stat">
                                  <span>
                                    {item?.no_of_tyre
                                      ? item?.no_of_tyre
                                      : "N/A"}
                                    &nbsp; tyres
                                  </span>
                                </div>
                              )}

                              {item?.category?.title === "Tractors" && (
                                <div className="stat">
                                  <span>
                                    {item?.horse_power
                                      ? item?.horse_power
                                      : "N/A"}
                                    &nbsp; hp
                                  </span>
                                </div>
                              )}

                              {item?.category?.title === "Buses" && (
                                <div className="stat">
                                  <span>
                                    {item?.no_of_seats
                                      ? item?.no_of_seats
                                      : "N/A"}
                                    &nbsp; seats
                                  </span>
                                </div>
                              )}

                              {item?.category?.title ===
                                "Construction Equipments" && (
                                <div className="stat">
                                  <span>
                                    {item?.no_of_tyre
                                      ? item?.no_of_tyre
                                      : "N/A"}
                                    &nbsp; tyres
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card-btns">
                          {/* <button>Book for Inspection</button> */}
                          <button
                            onClick={() => navigate(`/sellerform/${item._id}`)}
                          >
                            Edit
                          </button>
                          <button
                            className="del-btn"
                            onClick={() => {
                              handleDeleteVehicleDialog();
                              setVehicleId(item._id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="lottie-div">
                    <Lottie
                      options={defaultOptions}
                      height="100%"
                      width="100%"
                    />
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>

          <Dialog
            open={openDialog}
            onClose={dialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to mark as sold a vehicle ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* Let Google help apps determine location.
                                      This means sending anonymous location data
                                      to Google, even when no apps are running. */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleMarkAsSoldPut(vehicleId);
                }}
              >
                Yes
              </Button>
              <Button onClick={dialogClose}>No</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={deleteDialog}
            onClose={dialogDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete a vehicle ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {/* Let Google help apps determine location.
                                      This means sending anonymous location data
                                      to Google, even when no apps are running. */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleVehicleDelete(vehicleId)}>
                Yes
              </Button>
              <Button onClick={dialogDeleteClose}>No</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default UserVehicles;
