import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./wizard.css";

import axios from "axios";
import moment from "moment";

import Brand from "../Brand/Brand";
import Model from "../Model/Model";
import Year from "../Year/Year";
import Tyre from "../Tyre/Tyre";
import Body from "../Body/Body";
import FuelType from "../FuelType/FuelType";
import Details from "../Details/Details";
import ImageUpload from "../ImageUpload/ImageUpload";
import Info from "../Info/Info";
import User from "../User/User";

import Constant from "../../constants";

const Wizard = () => {
  let { categoryId } = useParams();

  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [saveLoading, setSaveLoading] = useState(false);

  const [isFrontsideImgClicked, setIsFrontsideImgClicked] = useState(false);
  const [frontSideImgURL, setFrontSideImgURL] = useState(null);
  const [frontsideCropImg, setFrontsideCropImg] = useState(null);

  const [isEngineImgClicked, setIsEngineImgClicked] = useState(false);
  const [engineImgURL, setEngineImgURL] = useState(null);
  const [engineCropImg, setEngineCropImg] = useState(null);

  const [isBacksideImgClicked, setIsBacksideImgClicked] = useState(false);
  const [backsideImgURL, setBacksideImgURL] = useState(null);
  const [backsideCropImg, setBacksideCropImg] = useState(null);

  const [isfronttyreLeftImgClicked, setIsFronttyreLeftImgClicked] =
    useState(false);
  const [fronttyreLeftImgURL, setFronttyreLeftImgURL] = useState(null);
  const [fronttyreLeftCropImg, setFronttyreLeftCropImg] = useState(null);

  const [isfronttyreRightImgClicked, setIsFronttyreRightImgClicked] =
    useState(false);
  const [fronttyreRightImgURL, setFronttyreRightImgURL] = useState(null);
  const [fronttyreRightCropImg, setFronttyreRightCropImg] = useState(null);

  const [isSidePicLeftClicked, setIsSidePicLeftClicked] = useState(false);
  const [sidePicLeftImgURL, setSidePicLeftImgURL] = useState(null);
  const [sidePicLeftCropImg, setSidePicLeftCropImg] = useState(null);

  const [isSidePicRightClicked, setIsSidePicRightClicked] = useState(false);
  const [sidePicRightImgURL, setSidePicRightImgURL] = useState(null);
  const [sidePicRightCropImg, setSidePicRightCropImg] = useState(null);

  const [formfields, setFormFields] = useState({
    brandText: "",
    modelText: "",
    kmDrivenText: "",
    vehicleNoText: "",
    expectedPriceText: "",
    noOfTyreText: "",
    noOfHrsText: "",
    noOfSeatsText: "",
    horsePowerText: "",
    insuranceValidity: "",
    taxValidity: "",
    fitnessValidity: "",
    userName: "",
    userState: "",
    userCity: "",
    userEmail: "",
    userMobNo: "",
  });

  const {
    brandText,
    modelText,
    kmDrivenText,
    vehicleNoText,
    expectedPriceText,
    noOfTyreText,
    noOfHrsText,
    noOfSeatsText,
    horsePowerText,
    insuranceValidity,
    taxValidity,
    fitnessValidity,
    userName,
    userState,
    userCity,
    userEmail,
    userMobNo,
  } = formfields;

  const [checkCategory, setCheckCategory] = useState("");

  const [brandsList, setBrandsList] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [modelList, setModelList] = useState([]);
  const [modelId, setModelId] = useState("");
  const [yearList, setYearList] = useState([]);
  const [yearId, setYearId] = useState("");
  const [yearTitle, setYearTitle] = useState("");
  const [tyreCondition, setTyreCondition] = useState("");
  const [bodytypeList, setBodyTypeList] = useState([]);
  const [updateBodyList, setUpdateBodylist] = useState([]);
  const [bodyTypeId, setBodyTypeId] = useState("");
  const [fueltypeList, setFuelTypeList] = useState([]);
  const [fuelTypeId, setFuelTypeId] = useState("");
  const [rc, setRC] = useState("");
  const [permit, setPermit] = useState("");
  const [owner, setOwner] = useState("");
  const [engCond, setEngCond] = useState("");
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [cityList, setCityList] = useState("");
  const [catId, setCatId] = useState("");
  const [userData, setUserData] = useState();

  const [isTyreCondActive, setIsTyreCondActive] = useState();
  const [isBodyTypeActive, setIsBodyTypeActive] = useState();
  const [isFuelTypeActive, setIsFuelTypeActive] = useState();
  const [isRCActive, setIsRCActive] = useState();
  const [isPermitActive, setIsPermitActive] = useState();
  const [isOwnerActive, setIsOwnerActive] = useState();
  const [isEngActive, setIsEngActive] = useState();

  const [editVehicleObj, setEditVehicleObj] = useState();

  const handleOnChange = (e) => {
    setFormFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (brandText) {
      fetchBrands();
    }

    if (modelText) {
      fetchModels(brandId);
    }
  };

  const nextStep = () => {
    let validateVehicleNo =
      /^[A-Z|a-z]{2}\s?[0-9]{1,2}\s?[A-Z|a-z]{0,3}\s?[0-9]{4}$/;
    let validateNums = /^[1-9]+[0-9]*$/;

    if (step === 0) {
      setStep((prevState) => prevState + 1);
    }

    if (step === 1) {
      if (!modelId) {
        toast.error("Model is required");
      } else {
        setStep((prevState) => prevState + 1);
      }
    }

    if (step === 2) {
      if (!yearId) {
        toast.error("Year is required");
      } else {
        setStep((prevState) => prevState + 1);
      }
    }

    if (step === 3) {
      if (!tyreCondition) {
        toast.error("Tyre condition is required");
      } else {
        setStep((prevState) => prevState + 1);
      }
    }

    if (step === 4) {
      if (checkCategory === "Tractors") {
        if (!engCond) {
          toast.error("Engine condition is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Construction Equipments") {
        if (!engCond) {
          toast.error("Engine condition is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Trucks" || checkCategory === "Buses") {
        setStep((prevState) => prevState + 1);
      }
    }

    if (step === 5) {
      if (
        checkCategory === "Trucks" ||
        checkCategory === "Buses" ||
        checkCategory === "Construction Equipments"
      ) {
        if (!fuelTypeId) {
          toast.error("Fueltype is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Tractors") {
        if (!rc) {
          toast.error("RC is required");
        } else if (!owner) {
          toast.error("Owner is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }
    }

    if (step === 6) {
      if (checkCategory === "Trucks") {
        if (!rc) {
          toast.error("RC is required");
        } else if (!owner) {
          toast.error("Owner is required");
        } else if (!insuranceValidity) {
          toast.error("Insurance validity is required");
        } else if (!taxValidity) {
          toast.error("Tax validity is required");
        } else if (!fitnessValidity) {
          toast.error("Fitness validity is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Buses") {
        if (!rc) {
          toast.error("RC is required");
        } else if (!owner) {
          toast.error("Owner is required");
        } else if (!insuranceValidity) {
          toast.error("Insurance validity is required");
        } else if (!taxValidity) {
          toast.error("Tax validity is required");
        } else if (!fitnessValidity) {
          toast.error("Fitness validity is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Tractors") {
        if (!horsePowerText) {
          toast.error("Horse power is required");
        } else if (!validateNums.test(horsePowerText)) {
          toast.error("Please enter vaild horse power");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Construction Equipments") {
        if (!rc) {
          toast.error("RC is required");
        } else if (!owner) {
          toast.error("Owner is required");
        } else if (!insuranceValidity) {
          toast.error("Insurance validity is required");
        } else if (!taxValidity) {
          toast.error("Tax validity is required");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }
    }

    if (step === 7) {
      // 7 mein daaal
      if (checkCategory === "Trucks") {
        if (!vehicleNoText) {
          toast.error("Vehicle number is required");
        } else if (!validateVehicleNo.test(vehicleNoText)) {
          toast.error("Please enter vaild vehicle number");
        } else if (!expectedPriceText) {
          toast.error("Expected price is required");
        } else if (!validateNums.test(expectedPriceText)) {
          toast.error("Please enter vaild price");
        } else if (!kmDrivenText) {
          toast.error("Kilometers is required");
        } else if (!validateNums.test(kmDrivenText)) {
          toast.error("Please enter vaild kilometers");
        } else if (noOfTyreText) {
          if (!validateNums.test(noOfTyreText)) {
            toast.error("Please enter vaild number of tyre");
          } else {
            setStep((prevState) => prevState + 1);
          }
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Buses") {
        if (!vehicleNoText) {
          toast.error("Vehicle number is required");
        } else if (!validateVehicleNo.test(vehicleNoText)) {
          toast.error("Please enter vaild vehicle number");
        } else if (!expectedPriceText) {
          toast.error("Expected price is required");
        } else if (!validateNums.test(expectedPriceText)) {
          toast.error("Please enter vaild price");
        } else if (!kmDrivenText) {
          toast.error("Kilometers is required");
        } else if (!validateNums.test(kmDrivenText)) {
          toast.error("Please enter vaild kilometers");
        } else if (!noOfSeatsText) {
          toast.error("Number of seats is required");
        } else if (!validateNums.test(noOfSeatsText)) {
          toast.error("Please enter vaild number of seats");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Tractors") {
        if (!vehicleNoText) {
          toast.error("Vehicle number is required");
        } else if (!validateVehicleNo.test(vehicleNoText)) {
          toast.error("Please enter vaild vehicle number");
        } else if (!expectedPriceText) {
          toast.error("Expected price is required");
        } else if (!validateNums.test(expectedPriceText)) {
          toast.error("Please enter vaild price");
        } else if (!noOfHrsText) {
          toast.error("Number of hours is required");
        } else if (!validateNums.test(noOfHrsText)) {
          toast.error("Please enter vaild number of hours");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }

      if (checkCategory === "Construction Equipments") {
        if (!vehicleNoText) {
          toast.error("Vehicle number is required");
        } else if (!validateVehicleNo.test(vehicleNoText)) {
          toast.error("Please enter vaild vehicle number");
        } else if (!expectedPriceText) {
          toast.error("Expected price is required");
        } else if (!validateNums.test(expectedPriceText)) {
          toast.error("Please enter vaild price");
        } else if (!noOfHrsText) {
          toast.error("Number of hours is required");
        } else if (!validateNums.test(noOfHrsText)) {
          toast.error("Please enter vaild number of hours");
        } else {
          setStep((prevState) => prevState + 1);
        }
      }
    }

    if (step === 8) {
      if (!sidePicLeftCropImg) {
        toast.error("Side picture is required");
      } else if (!frontsideCropImg) {
        toast.error("Frontside picture is required");
      } else {
        setStep((prevState) => prevState + 1);
      }
    }
  };

  const prevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  const tyreConditionList = [
    {
      label: "Bad",
      condition: "bad",
    },
    {
      label: "Average",
      condition: "average",
    },
    {
      label: "Good",
      condition: "good",
    },
    {
      label: "Very Good",
      condition: "very good",
    },
  ];

  const engineConditionList = [
    {
      label: "Bad",
      condition: "bad",
    },
    {
      label: "Average",
      condition: "average",
    },
    {
      label: "Good",
      condition: "good",
    },
    {
      label: "Very Good",
      condition: "very good",
    },
  ];

  const RCList = [
    {
      label: "Yes",
      rc: "yes",
    },
    {
      label: "No",
      rc: "no",
    },
  ];

  const permitList = [
    {
      label: "National",
      permit: "national",
    },
    {
      label: "State",
      permit: "state",
    },
  ];

  const ownersList = [
    {
      label: "First",
      owner: "first",
    },
    {
      label: "Second",
      owner: "second",
    },
    {
      label: "Third",
      owner: "third",
    },
    {
      label: "Fourth +",
      owner: "fourth +",
    },
  ];

  const formatDate = (date) => {
    return moment(date).utc().format("YYYY-MM-DD");
  };

  const fetchVehicleDetails = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllVehicles + `/vehicleDetails/${categoryId}`
    );
    setEditVehicleObj(response.data.vehicle);

    console.log(response);

    if (response.data.vehicle) {
      setCheckCategory(response.data?.vehicle?.category?.title);

      categoryId = response.data?.vehicle?.category?._id;

      if (response.data?.vehicle?.brand) {
        setFormFields((prevState) => ({
          ...prevState,
          brandText: response.data?.vehicle?.brand?.title,
        }));

        setBrandId(response.data?.vehicle?.brand?._id);
      }

      if (response.data?.vehicle?.model) {
        setFormFields((prevState) => ({
          ...prevState,
          modelText: response.data?.vehicle?.model?.name,
        }));

        setModelId(response.data?.vehicle?.model?._id);
      }

      setYearId(response?.data?.vehicle?.years?._id);
      setTyreCondition(response?.data?.vehicle?.tyre_cond);
      if (response?.data?.vehicle?.bodyType?._id === undefined) {
        setBodyTypeId("");
      } else {
        setBodyTypeId(response?.data?.vehicle?.bodyType?._id);
      }
      if (response?.data?.vehicle?.fuelType?._id === undefined) {
        setFuelTypeId("");
      } else {
        setFuelTypeId(response?.data?.vehicle?.fuelType?._id);
      }
      setStateId(response?.data?.vehicle?.state?._id);
      setCityId(response?.data?.vehicle?.city?._id);
      setYearTitle(response?.data?.vehicle?.years?.year);
      console.log(response?.data?.vehicle?.years?.year);

      if (response.data?.vehicle?.km_driven) {
        setFormFields((prevState) => ({
          ...prevState,
          kmDrivenText: response.data?.vehicle?.km_driven,
        }));
      }

      if (response.data?.vehicle?.no_of_tyre) {
        setFormFields((prevState) => ({
          ...prevState,
          noOfTyreText: response.data?.vehicle?.no_of_tyre,
        }));
      }

      if (response.data?.vehicle?.reg_no) {
        setFormFields((prevState) => ({
          ...prevState,
          vehicleNoText: response.data?.vehicle?.reg_no,
        }));
      }

      if (response.data?.vehicle?.selling_price) {
        setFormFields((prevState) => ({
          ...prevState,
          expectedPriceText: response.data?.vehicle?.selling_price,
        }));
      }

      if (response.data?.vehicle?.no_of_hrs) {
        setFormFields((prevState) => ({
          ...prevState,
          noOfHrsText: response.data?.vehicle?.no_of_hrs,
        }));
      }

      if (response.data?.vehicle?.no_of_seats) {
        setFormFields((prevState) => ({
          ...prevState,
          noOfSeatsText: response.data?.vehicle?.no_of_seats,
        }));
      }

      if (response.data?.vehicle?.horse_power) {
        setFormFields((prevState) => ({
          ...prevState,
          horsePowerText: response.data?.vehicle?.horse_power,
        }));
      }

      if (response.data?.vehicle?.no_of_owner) {
        setOwner(response.data?.vehicle?.no_of_owner);
      }

      if (response.data?.vehicle?.engine_cond) {
        setEngCond(response.data?.vehicle?.engine_cond);
      }

      if (response.data?.vehicle?.rc_document) {
        setRC(response.data?.vehicle?.rc_document);
      }

      if (response.data?.vehicle?.vehicle_permit) {
        setPermit(response.data?.vehicle?.vehicle_permit);
      }

      if (response?.data?.vehicle?.insurance) {
        setFormFields((prevState) => ({
          ...prevState,
          insuranceValidity: formatDate(response?.data?.vehicle?.insurance),
        }));
      }
      if (response?.data?.vehicle?.tax_validity) {
        setFormFields((prevState) => ({
          ...prevState,
          taxValidity: formatDate(response?.data?.vehicle?.tax_validity),
        }));
      }

      if (response?.data?.vehicle?.fitness_certificate) {
        setFormFields((prevState) => ({
          ...prevState,
          fitnessValidity: formatDate(
            response?.data?.vehicle?.fitness_certificate
          ),
        }));
      }

      if (response?.data?.vehicle?.front_side_pic) {
        setFrontSideImgURL(response?.data?.vehicle?.front_side_pic);
        setFrontsideCropImg(response?.data?.vehicle?.front_side_pic);
      }

      if (response?.data?.vehicle?.engine_pic !== null) {
        setEngineImgURL(response?.data?.vehicle?.engine_pic);
        setEngineCropImg(response?.data?.vehicle?.engine_pic);
      }

      if (response?.data?.vehicle?.back_side_pic) {
        setBacksideImgURL(response?.data?.vehicle?.back_side_pic);
        setBacksideCropImg(response?.data?.vehicle?.back_side_pic);
      }

      if (response?.data?.vehicle?.front_tyre) {
        setFronttyreLeftImgURL(response?.data?.vehicle?.front_tyre[0]);
        // setFronttyreRightImgURL(response?.data?.vehicle?.front_tyre[1]);
        setFronttyreLeftCropImg(response?.data?.vehicle?.front_tyre[0]);
        // setFronttyreRightCropImg(response?.data?.vehicle?.front_tyre[1]);
      }

      if (response?.data?.vehicle?.side_pic_vehicle) {
        setSidePicLeftImgURL(response?.data?.vehicle?.side_pic_vehicle[0]);
        // setSidePicRightImgURL(response?.data?.vehicle?.side_pic_vehicle[1]);
        setSidePicLeftCropImg(response?.data?.vehicle?.side_pic_vehicle[0]);
        // setSidePicRightCropImg(response?.data?.vehicle?.side_pic_vehicle[1]);
      }

      if (response?.data?.vehicle?.secondary_name) {
        setFormFields((prevState) => ({
          ...prevState,
          userName: response?.data?.vehicle?.secondary_name,
        }));
      }

      if (response?.data?.vehicle?.secondary_email) {
        setFormFields((prevState) => ({
          ...prevState,
          userEmail: response?.data?.vehicle?.secondary_email,
        }));
      }

      if (response?.data?.vehicle?.secondary_mob) {
        setFormFields((prevState) => ({
          ...prevState,
          userMobNo: response?.data?.vehicle?.secondary_mob,
        }));
      }

      if (response?.data?.vehicle?.state) {
        setFormFields((prevState) => ({
          ...prevState,
          userState: response?.data?.vehicle?.state?.title,
        }));
      }

      if (response?.data?.vehicle?.city) {
        setFormFields((prevState) => ({
          ...prevState,
          userCity: response?.data?.vehicle?.city?.title,
        }));
      }
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        Constant.getUrls.getAllCategories + `/${categoryId}`
      );
      if (response.data) {
        setCheckCategory(response?.data?.category?.title);
        setCatId(response?.data?.category?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrands = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllBrands +
        `?limit=5000&status=active&sort=true&q=${brandText.toLowerCase()}`
    );
    setBrandsList(response.data.brand.docs);
  };

  const fetchModels = async (id) => {
    if (id) {
      const response = await axios.get(
        Constant.getUrls.getAllModels +
          `?brand=${id}&limit=5000&status=active&sort=true&q=${modelText.toLowerCase()}`
      );
      setModelList(response.data.model.docs);
    }
  };

  const fetchYears = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllYears +
        "?limit=500&year=true&status=active&flag=web"
    );
    setYearList(response.data._yrs.docs);
  };

  const fetchBodyTypes = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllBodyTypes +
        `?status=active&limit=500&category=${categoryId}`
    );
    setBodyTypeList(response.data._getBodyType.docs);
  };

  const fetchFuelType = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllFuelTypes + "?status=active"
    );
    setFuelTypeList(response.data._getFuel.docs);
  };

  const fetchStates = async () => {
    const res = await axios.get(
      Constant.getUrls.getAllStates + "?status=active&sort=true&limit=5000"
    );
    if (res.data) {
      setStateList(res.data.getAllStates.docs);
    }
  };

  const fetchCities = async (id) => {
    const res = await axios.get(
      Constant.getUrls.getAllCity +
        `?status=active&sort=true&state=${id}&limit=500`
    );
    if (res.data) {
      setCityList(res.data.getAllCities.docs);
    }
  };

  const filterStates = stateList.filter((state) => {
    return state.title.toLowerCase().includes(userState.toLowerCase());
  });

  const fetchUser = async () => {
    try {
      const userToken = localStorage.getItem("Token");
      const response = await axios.get(Constant.getUrls.getUser, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.data.status === "success") {
        setUserData(response?.data?.user);
        // if (response?.data?.user?.name) {
        //   setFormFields((prevState) => ({
        //     ...prevState,
        //     userName: response?.data?.user?.name,
        //   }));
        // }

        // if (response?.data?.user?.email) {
        //   setFormFields((prevState) => ({
        //     ...prevState,
        //     userEmail: response?.data?.user?.email,
        //   }));
        // }

        // if (response?.data?.user?.mob_no) {
        //   setFormFields((prevState) => ({
        //     ...prevState,
        //     userMobNo: response?.data?.user?.mob_no,
        //   }));
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchVehicleDetails();
    fetchCategory();
    fetchBrands();
    fetchYears();
    fetchBodyTypes();
    fetchFuelType();
    fetchStates();
  }, []);

  let filterCities;
  if (cityList.length > 0) {
    filterCities = cityList.filter((city) => {
      return city.title.toLowerCase().includes(userCity.toLowerCase());
    });
  }

  /* POST DATA */
  const handlePostData = async () => {
    let validateName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    let formData = new FormData();

    // front blob
    const frontSideFile = await fetch(frontsideCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], frontsideCropImg, { type: blobFile.type })
      );

    // backside blob
    const backSideFile = await fetch(backsideCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], backsideCropImg, { type: blobFile.type })
      );

    // engine blob
    const engFile = await fetch(engineCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], engineCropImg, {
            type: blobFile.type,
          })
      );

    // fronttyre left blob
    const fronttyreLeftFile = await fetch(fronttyreLeftCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], fronttyreLeftCropImg, {
            type: blobFile.type,
          })
      );

    // fronttyre right blob
    const fronttyreRightFile = await fetch(fronttyreRightCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], fronttyreRightCropImg, {
            type: blobFile.type,
          })
      );

    // side Pic left blob
    const sidePicLeftFile = await fetch(sidePicLeftCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], sidePicLeftCropImg, {
            type: blobFile.type,
          })
      );

    // side Pic right blob
    const sidePicRightFile = await fetch(sidePicRightCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], sidePicRightCropImg, {
            type: blobFile.type,
          })
      );

    if (step === 9) {
      if (!userName) {
        toast.error("Name is required");
      } else if (!validateName.test(userName.trim())) {
        toast.error("Please enter valid name");
      } else if (!userEmail) {
        toast.error("Email is required");
      } else if (!validateEmail.test(userEmail.trim())) {
        toast.error("Please enter valid email");
      } else if (!userMobNo) {
        toast.error("Phone number is required");
      } else if (!validateMobNo.test(userMobNo)) {
        toast.error("Please enter valid phone number");
      } else if (!userState) {
        toast.error("Please enter your state");
      } else if (!userCity) {
        toast.error("Please enter your city");
      } else {
        setSaveLoading(true);
        formData.append("category", categoryId);
        formData.append("state", stateId);
        formData.append("city", cityId);
        formData.append("brand", brandId);
        formData.append("model", modelId);
        formData.append("years", yearId);
        formData.append("reg_no", vehicleNoText);
        formData.append("km_driven", kmDrivenText || 0);
        formData.append("no_of_hrs", noOfHrsText || 0);
        formData.append("no_of_owner", owner);
        formData.append("engine_cond", engCond);
        formData.append("no_of_seats", noOfSeatsText);
        formData.append("no_of_tyre", noOfTyreText);
        formData.append("horse_power", horsePowerText);
        formData.append("tyre_cond", tyreCondition);
        formData.append("fuelType", fuelTypeId);
        formData.append("bodyType", bodyTypeId);
        formData.append("rc_document", rc);
        formData.append("vehicle_permit", permit);
        formData.append("insurance", insuranceValidity);
        formData.append("tax_validity", taxValidity);
        formData.append("selling_price", expectedPriceText);
        formData.append("fitness_certificate", fitnessValidity);
        formData.append("secondary_name", userName.trim());
        formData.append("secondary_email", userEmail.trim());
        formData.append("secondary_mob", userMobNo);
        formData.append("status", "published");

        if (frontsideCropImg) {
          formData.append("front_side_pic", frontSideFile);
        }
        if (engineCropImg) {
          formData.append("engine_pic", engFile);
        }
        if (backsideCropImg) {
          formData.append("back_side_pic", backSideFile);
        }
        if (fronttyreLeftCropImg) {
          formData.append("front_tyre", fronttyreLeftFile);
        }
        // if (fronttyreLeftCropImg) {
        //   formData.append("front_tyre", fronttyreRightFile);
        // }
        if (sidePicLeftCropImg) {
          formData.append("side_pic_vehicle", sidePicLeftFile);
        }
        // if (sidePicLeftCropImg) {
        //   formData.append("side_pic_vehicle", sidePicRightFile);
        // }

        const userToken = localStorage.getItem("Token");
        const response = await axios.post(
          Constant.postUrls.postAllVehicles + "/addVehicle",
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success(response.data.message);
          setSaveLoading(false);
          navigate("/UserVehicles");
        } else {
          toast.error(response.data.errors.msg);
          setSaveLoading(false);
        }

        console.log(response);
      }
    }
  };

  /* UPDATE DATA */
  const handleUpdateData = async () => {
    let validateName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    let formData = new FormData();

    // front blob
    const frontSideFile = await fetch(frontsideCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], frontsideCropImg, { type: blobFile.type })
      );

    // backside blob
    const backSideFile = await fetch(backsideCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], backsideCropImg, { type: blobFile.type })
      );

    // engine blob
    const engFile = await fetch(engineCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], engineCropImg, {
            type: blobFile.type,
          })
      );

    // fronttyre left blob
    const fronttyreLeftFile = await fetch(fronttyreLeftCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], fronttyreLeftCropImg, {
            type: blobFile.type,
          })
      );

    // fronttyre right blob
    const fronttyreRightFile = await fetch(fronttyreRightCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], fronttyreRightCropImg, {
            type: blobFile.type,
          })
      );

    // side Pic left blob
    const sidePicLeftFile = await fetch(sidePicLeftCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], sidePicLeftCropImg, {
            type: blobFile.type,
          })
      );

    // side Pic right blob
    const sidePicRightFile = await fetch(sidePicRightCropImg)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], sidePicRightCropImg, {
            type: blobFile.type,
          })
      );

    if (step === 9) {
      if (!userName) {
        toast.error("Name is required");
      } else if (!validateName.test(userName.trim())) {
        toast.error("Please enter valid name");
      } else if (!userEmail) {
        toast.error("Email is required");
      } else if (!validateEmail.test(userEmail.trim())) {
        toast.error("Please enter valid email");
      } else if (!userMobNo) {
        toast.error("Phone number is required");
      } else if (!validateMobNo.test(userMobNo)) {
        toast.error("Please enter valid phone number");
      } else {
        setSaveLoading(true);
        formData.append("category", editVehicleObj?.category?._id);
        formData.append("state", stateId);
        formData.append("city", cityId);
        formData.append("brand", brandId);
        formData.append("model", modelId);
        formData.append("years", yearId);
        formData.append("reg_no", vehicleNoText);
        formData.append("km_driven", kmDrivenText || 0);
        formData.append("no_of_hrs", noOfHrsText || 0);
        formData.append("no_of_owner", owner);
        formData.append("no_of_seats", noOfSeatsText);
        formData.append("no_of_tyre", noOfTyreText);
        formData.append("horse_power", horsePowerText);
        formData.append("tyre_cond", tyreCondition);
        formData.append("engine_cond", engCond);
        formData.append("fuelType", fuelTypeId);
        formData.append("bodyType", bodyTypeId);
        formData.append("rc_document", rc);
        formData.append("vehicle_permit", permit);
        formData.append("insurance", insuranceValidity);
        formData.append("tax_validity", taxValidity);
        formData.append("selling_price", expectedPriceText);
        formData.append("fitness_certificate", fitnessValidity);
        formData.append("secondary_name", userName.trim());
        formData.append("secondary_email", userEmail.trim());
        formData.append("secondary_mob", userMobNo);
        formData.append("status", "published");

        if (isFrontsideImgClicked) {
          formData.append("front_side_pic", frontSideFile);
        }
        if (isEngineImgClicked) {
          formData.append("engine_pic", engFile);
        }
        if (isBacksideImgClicked) {
          formData.append("back_side_pic", backSideFile);
        }
        if (isfronttyreLeftImgClicked) {
          formData.append("front_tyre", fronttyreLeftFile);
        }
        if (isfronttyreRightImgClicked) {
          formData.append("front_tyre", fronttyreRightFile);
        }
        if (isSidePicLeftClicked) {
          formData.append("side_pic_vehicle", sidePicLeftFile);
        }
        if (isSidePicRightClicked) {
          formData.append("side_pic_vehicle", sidePicRightFile);
        }

        const userToken = localStorage.getItem("Token");
        const updateResponse = await axios.put(
          Constant.postUrls.postAllVehicles +
            "/updateVehicle/" +
            editVehicleObj?._id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (updateResponse.data.status === "success") {
          toast.success(updateResponse.data.message);
          setSaveLoading(false);
          navigate("/UserVehicles");
        } else {
          toast.error(updateResponse.data.errors.msg);
          setSaveLoading(false);
        }

        console.log(updateResponse);
      }
    }
  };

  switch (step) {
    case 0:
      return (
        <Brand
          brandsList={brandsList}
          setStep={setStep}
          brandText={brandText}
          setBrandId={setBrandId}
          handleOnChange={handleOnChange}
          fetchModels={fetchModels}
          checkCategory={checkCategory}
          step={step}
          setFormFields={setFormFields}
          editVehicleObj={editVehicleObj}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 1:
      return (
        <Model
          setStep={setStep}
          modelList={modelList}
          modelText={modelText}
          setModelId={setModelId}
          handleOnChange={handleOnChange}
          checkCategory={checkCategory}
          setFormFields={setFormFields}
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 2:
      return (
        <Year
          setStep={setStep}
          step={step}
          yearList={yearList}
          setYearId={setYearId}
          nextStep={nextStep}
          prevStep={prevStep}
          setYearTitle={setYearTitle}
          yearTitle={yearTitle}
          checkCategory={checkCategory}
        />
      );
    case 3:
      return (
        <Tyre
          setStep={setStep}
          step={step}
          tyreConditionList={tyreConditionList}
          tyreCondition={tyreCondition}
          setTyreCondition={setTyreCondition}
          nextStep={nextStep}
          prevStep={prevStep}
          isTyreCondActive={isTyreCondActive}
          setIsTyreCondActive={setIsTyreCondActive}
          checkCategory={checkCategory}
          editVehicleObj={editVehicleObj}
        />
      );
    case 4:
      return (
        <Body
          setStep={setStep}
          step={step}
          bodytypeList={bodytypeList}
          setBodyTypeId={setBodyTypeId}
          bodyTypeId={bodyTypeId}
          setIsBodyTypeActive={setIsBodyTypeActive}
          isBodyTypeActive={isBodyTypeActive}
          nextStep={nextStep}
          prevStep={prevStep}
          checkCategory={checkCategory}
          editVehicleObj={editVehicleObj}
          engineConditionList={engineConditionList}
          isEngActive={isEngActive}
          setIsEngActive={setIsEngActive}
          setEngCond={setEngCond}
          engCond={engCond}
        />
      );
    case 5:
      return (
        <FuelType
          RCList={RCList}
          setRC={setRC}
          rc={rc}
          setIsRCActive={setIsRCActive}
          isRCActive={isRCActive}
          ownersList={ownersList}
          setOwner={setOwner}
          owner={owner}
          setIsOwnerActive={setIsOwnerActive}
          isOwnerActive={isOwnerActive}
          setStep={setStep}
          step={step}
          fueltypeList={fueltypeList}
          isFuelTypeActive={isFuelTypeActive}
          setIsFuelTypeActive={setIsFuelTypeActive}
          setFuelTypeId={setFuelTypeId}
          fuelTypeId={fuelTypeId}
          nextStep={nextStep}
          prevStep={prevStep}
          checkCategory={checkCategory}
          editVehicleObj={editVehicleObj}
          engineConditionList={engineConditionList}
          isEngActive={isEngActive}
          setIsEngActive={setIsEngActive}
          setEngCond={setEngCond}
          engCond={engCond}
          horsePowerText={horsePowerText}
          noOfHrsText={noOfHrsText}
          handleOnChange={handleOnChange}
        />
      );
    case 6:
      return (
        <Info
          setStep={setStep}
          step={step}
          RCList={RCList}
          setRC={setRC}
          rc={rc}
          setIsRCActive={setIsRCActive}
          isRCActive={isRCActive}
          permitList={permitList}
          setPermit={setPermit}
          permit={permit}
          setIsPermitActive={setIsPermitActive}
          isPermitActive={isPermitActive}
          insuranceValidity={insuranceValidity}
          taxValidity={taxValidity}
          fitnessValidity={fitnessValidity}
          handleOnChange={handleOnChange}
          checkCategory={checkCategory}
          nextStep={nextStep}
          prevStep={prevStep}
          ownersList={ownersList}
          setOwner={setOwner}
          owner={owner}
          setIsOwnerActive={setIsOwnerActive}
          isOwnerActive={isOwnerActive}
          editVehicleObj={editVehicleObj}
          horsePowerText={horsePowerText}
        />
      );
    case 7:
      return (
        <Details
          setStep={setStep}
          step={step}
          checkCategory={checkCategory}
          kmDrivenText={kmDrivenText}
          vehicleNoText={vehicleNoText}
          expectedPriceText={expectedPriceText}
          noOfTyreText={noOfTyreText}
          noOfHrsText={noOfHrsText}
          noOfSeatsText={noOfSeatsText}
          horsePowerText={horsePowerText}
          handleOnChange={handleOnChange}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 8:
      return (
        <ImageUpload
          setStep={setStep}
          step={step}
          checkCategory={checkCategory}
          isFrontsideImgClicked={isFrontsideImgClicked}
          setIsFrontsideImgClicked={setIsFrontsideImgClicked}
          frontSideImgURL={frontSideImgURL}
          setFrontSideImgURL={setFrontSideImgURL}
          frontsideCropImg={frontsideCropImg}
          setFrontsideCropImg={setFrontsideCropImg}
          isEngineImgClicked={isEngineImgClicked}
          setIsEngineImgClicked={setIsEngineImgClicked}
          engineImgURL={engineImgURL}
          setEngineImgURL={setEngineImgURL}
          engineCropImg={engineCropImg}
          setEngineCropImg={setEngineCropImg}
          isBacksideImgClicked={isBacksideImgClicked}
          setIsBacksideImgClicked={setIsBacksideImgClicked}
          backsideImgURL={backsideImgURL}
          setBacksideImgURL={setBacksideImgURL}
          backsideCropImg={backsideCropImg}
          setBacksideCropImg={setBacksideCropImg}
          isfronttyreLeftImgClicked={isfronttyreLeftImgClicked}
          setIsFronttyreLeftImgClicked={setIsFronttyreLeftImgClicked}
          fronttyreLeftImgURL={fronttyreLeftImgURL}
          setFronttyreLeftImgURL={setFronttyreLeftImgURL}
          fronttyreLeftCropImg={fronttyreLeftCropImg}
          setFronttyreLeftCropImg={setFronttyreLeftCropImg}
          isfronttyreRightImgClicked={isfronttyreRightImgClicked}
          setIsFronttyreRightImgClicked={setIsFronttyreRightImgClicked}
          fronttyreRightImgURL={fronttyreRightImgURL}
          setFronttyreRightImgURL={setFronttyreRightImgURL}
          fronttyreRightCropImg={fronttyreRightCropImg}
          setFronttyreRightCropImg={setFronttyreRightCropImg}
          isSidePicLeftClicked={isSidePicLeftClicked}
          setIsSidePicLeftClicked={setIsSidePicLeftClicked}
          sidePicLeftImgURL={sidePicLeftImgURL}
          setSidePicLeftImgURL={setSidePicLeftImgURL}
          sidePicLeftCropImg={sidePicLeftCropImg}
          setSidePicLeftCropImg={setSidePicLeftCropImg}
          isSidePicRightClicked={isSidePicRightClicked}
          setIsSidePicRightClicked={setIsSidePicRightClicked}
          sidePicRightImgURL={sidePicRightImgURL}
          setSidePicRightImgURL={setSidePicRightImgURL}
          sidePicRightCropImg={sidePicRightCropImg}
          setSidePicRightCropImg={setSidePicRightCropImg}
          editVehicleObj={editVehicleObj}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      );
    case 9:
      return (
        <User
          setStep={setStep}
          step={step}
          checkCategory={checkCategory}
          userName={userName}
          userState={userState}
          userCity={userCity}
          userEmail={userEmail}
          userMobNo={userMobNo}
          handleOnChange={handleOnChange}
          prevStep={prevStep}
          setStateId={setStateId}
          fetchCities={fetchCities}
          cityList={cityList}
          setCityId={setCityId}
          setFormFields={setFormFields}
          filterStates={filterStates}
          filterCities={filterCities}
          handlePostData={handlePostData}
          handleUpdateData={handleUpdateData}
          editVehicleObj={editVehicleObj}
          saveLoading={saveLoading}
          userData={userData}
        />
      );
    default:
      break;
  }
};
export default Wizard;
