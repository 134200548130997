import React, { Fragment, useState, useEffect } from "react";
import "./user.css";

import Stepper from "../Stepper/Stepper";

import Navbar from "../Navbar";
import Footer from "../Footer";

const User = ({
  setStep,
  step,
  checkCategory,
  userName,
  userState,
  userCity,
  userEmail,
  userMobNo,
  prevStep,
  handleOnChange,
  setStateId,
  filterStates,
  filterCities,
  setCityId,
  fetchCities,
  setFormFields,
  handlePostData,
  handleUpdateData,
  saveLoading,
  editVehicleObj,
  userData,
}) => {
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);

  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleOnStateFocus = () => {
    setStateDropdownOpen(true);
    setCityDropdownOpen(false);
    setOverlayVisible(true);
  };

  const handleOnCityFocus = () => {
    setCityDropdownOpen((prevState) => !prevState);
    setOverlayVisible(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      setStateDropdownOpen(false);
      setCityDropdownOpen(false);
    }
  };

  const handleEnterUploadKeyDown = (e) => {
    if (e.key === "Enter") {
      handlePostData();
    }
  };

  const handleOverlay = () => {
    setStateDropdownOpen(false);
    setCityDropdownOpen(false);
    setOverlayVisible(false);
  };

  useEffect(() => {
    if (!editVehicleObj) {
      setFormFields((prevState) => ({
        ...prevState,
        userName: userData?.name,
        userEmail: userData?.email,
        userMobNo: userData?.mob_no,
      }));
    }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        {overlayVisible && (
          <div className="overlay-user" onClick={handleOverlay}></div>
        )}

        <div className="user-container">
          <h5>User Information</h5>
        </div>
        <div className="user-fields-container">
          <div className="user-input-controls">
            <h6>Name</h6>
            <input
              type="text"
              name="userName"
              onChange={handleOnChange}
              value={userName}
            />
          </div>
          <div className="user-input-controls">
            <h6>State</h6>
            <input
              type="text"
              name="userState"
              onChange={handleOnChange}
              value={userState}
              onFocus={handleOnStateFocus}
              onKeyDown={handleKeyDown}
            />

            {stateDropdownOpen && (
              <div className="dropdown-box">
                {filterStates.map((state) => (
                  <h6
                    key={state._id}
                    onClick={() => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        userState: state.title,
                      }));
                      setStateId(state._id);
                      fetchCities(state._id);
                      setStateDropdownOpen((prevState) => !prevState);
                      setOverlayVisible(false);
                    }}
                  >
                    {state.title}
                  </h6>
                ))}
              </div>
            )}
          </div>
          <div className="user-input-controls">
            <h6>City</h6>
            <input
              type="text"
              name="userCity"
              onChange={handleOnChange}
              placeholder="Please select state first"
              value={userCity}
              onFocus={handleOnCityFocus}
              onKeyDown={handleKeyDown}
            />

            {cityDropdownOpen && (
              <div className="dropdown-box">
                {filterCities?.map((city) => (
                  <h6
                    key={city._id}
                    onClick={() => {
                      setFormFields((prevState) => ({
                        ...prevState,
                        userCity: city.title,
                      }));
                      setCityId(city._id);
                      setCityDropdownOpen((prevState) => !prevState);
                      setOverlayVisible(false);
                    }}
                  >
                    {city.title}
                  </h6>
                ))}
              </div>
            )}
          </div>
          <div className="user-input-controls">
            <h6>Email</h6>
            <input
              type="email"
              name="userEmail"
              onChange={handleOnChange}
              value={userEmail}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="user-input-controls">
            <h6>Phone Number</h6>
            <input
              type="number"
              name="userMobNo"
              onChange={handleOnChange}
              value={userMobNo}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="user-btns">
            <button onClick={prevStep} className="prev-btn">
              Previous
            </button>
            {editVehicleObj?._id ? (
              <button onClick={handleUpdateData}>
                {saveLoading ? "Updating..." : "Update"}
              </button>
            ) : (
              <button
                onClick={handlePostData}
                onKeyDown={handleEnterUploadKeyDown}
              >
                {saveLoading ? "Uploading..." : "Upload Vehicle"}
              </button>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default User;
