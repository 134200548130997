import React from "react";
import "./LoggedUser.css";
import "./changepassword.css";
import shipping from "../assets/shipping.png";
import logout from "../assets/logout.png";
import next_arrow from "../assets/next_arrow.svg";
import Constant from "./../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import init from "../Helpers/WindowToken";
import clipboard from "../assets/clipboard.png";
import soldtabIcon from "../assets/soldicon.png";
import myVehicleEnqIco from "../assets/myvehicle_enq.png";
import lockPasswordIcon from "../assets/lock-password.png";
import eyeOpenIcon from "../assets/eye_icon.png";
import closeEyeIcon from "../assets/closed_eye_icon.png";

function ChangePassword() {
  const [name, setName] = useState("");
  const [isEyeOpen, setIsEyeOpen] = useState(false);

  const [inputFields, setInputFields] = useState({
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
  });

  const { oldPwd, newPwd, confirmPwd } = inputFields;

  function logoutAccount() {
    localStorage.clear();
    window.location.href = "/";
  }

  const handleOnChange = (e) => {
    setInputFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const getDetails = async () => {
    if (init() === "success") {
      await axios.get(Constant.getUrls.getUser).then((res) => {
        if (res?.data?.user?.name) {
          setName(res?.data?.user);
        }
      });
    }
  };

  const validateFeilds = () => {
    if (!oldPwd) {
      toast.error("Please enter your old password.");
    } else if (oldPwd.length < 8) {
      toast.error("Password must be 8 characters long.");
    } else if (!newPwd) {
      toast.error("Please enter new password.");
    } else if (newPwd.length < 8) {
      toast.error("Password must be 8 characters long.");
    } else if (!confirmPwd) {
      toast.error("Please enter confirm password.");
    } else if (confirmPwd.length < 8) {
      toast.error("Password must be 8 characters long.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateFeilds();

    let fd = {
      old_pass: oldPwd,
      new_pass: newPwd,
      confirm_pass: confirmPwd,
    };

    try {
      const response = await axios.post(
        Constant.getUrls.getUser + "/reset_pass",
        fd
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setInputFields({
          oldPwd: "",
          newPwd: "",
          confirmPwd: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePwdKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <Navbar />
      <div className="outside-container">
        <div className="profile-container gd_container">
          <div
            data-aos="fade-right"
            data-aos-delay="250"
            data-aos-duration="1500"
            className="left-profile-container"
          >
            <div className="upper-div">
              <p className="hello-text">Hello</p>
              <p className="user-name-left-div">{name?.name}</p>
            </div>
            <div className="options-div">
              <div className="my-vehicle-div">
                <img className="shipping-img" src={shipping} alt=""></img>
                <Link to="/UserVehicles" className="my-vehicle-text">
                  <span>My Vehicles</span>
                </Link>
                <Link to="/UserVehicles">
                  <img className="next-arrow-img" src={next_arrow} alt="" />
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={soldtabIcon} alt=""></img>
                <Link to="/mysoldvehicles" className="my-order-text">
                  <span>My Sold Vehicles</span>
                </Link>
                <Link to="/mysoldvehicles">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={clipboard} alt=""></img>
                <Link to="/UserOrder" className="my-order-text">
                  <span>My Buying Enquiries</span>
                </Link>
                <Link to="/UserOrder">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={myVehicleEnqIco}
                  alt=""
                ></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span>My Listing Enquiries</span>
                </Link>
                <Link to="/myvehicleenq">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={lockPasswordIcon}
                  alt=""
                ></img>
                <Link to="/changepassword" className="my-order-text">
                  <span>Change Password</span>
                </Link>
                <Link to="/changepassword">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={logout} alt=""></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span
                    onClick={() => {
                      logoutAccount();
                    }}
                  >
                    Sign out
                  </span>
                </Link>
                <Link to="">
                  <img
                    className="next-arrow-img hidden"
                    src={next_arrow}
                    alt=""
                  ></img>
                </Link>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="250"
            data-aos-duration="1500"
            className="right-profile-container"
          >
            <form onSubmit={handleSubmit}>
              <div className="change-pwd-container">
                <div className="input-form-control">
                  <h6>Old Password</h6>
                  <input
                    type="password"
                    name="oldPwd"
                    value={oldPwd}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="input-form-control">
                  <h6>New Password</h6>
                  <input
                    type="password"
                    name="newPwd"
                    value={newPwd}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="input-form-control">
                  <h6>Confirm Password</h6>
                  <input
                    type={isEyeOpen ? "text" : "password"}
                    name="confirmPwd"
                    value={confirmPwd}
                    onChange={handleOnChange}
                  />
                  <img
                    src={isEyeOpen ? closeEyeIcon : eyeOpenIcon}
                    alt="eye"
                    onClick={() => setIsEyeOpen((prevState) => !prevState)}
                  />
                </div>

                <div className="submit-btn">
                  <button onKeyDown={handleChangePwdKeyDown}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ChangePassword;
