import { PAGENO_ACTION_TYPES } from "./pageno.types";

const INITIAL_STATE = {
  pageNo: 1,
};

export const pageNoReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAGENO_ACTION_TYPES.SET_PAGENO_VALUE:
      return { ...state, pageNo: payload };

    default:
      return state;
  }
};
