import React, { Fragment, useEffect } from "react";
import "./year.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";

const Year = ({
  setStep,
  step,
  yearList,
  setYearId,
  prevStep,
  nextStep,
  checkCategory,
  setYearTitle,
  yearTitle,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="years-container">
          <h5>
            Select Manufacturing Year <span style={{ color: "red" }}>*</span>
          </h5>
        </div>
        <strong style={{ display: "inline-block" }}>{yearTitle}</strong>
        <div className="year-fields-contianer">
          <div className="years-list">
            {yearList.map((year) => (
              <div
                className="year-data"
                key={year._id}
                onClick={() => {
                  setYearId(year._id);
                  setYearTitle(year.year);
                  setStep((prevState) => prevState + 1);
                }}
              >
                <p>{year.year}</p>
              </div>
            ))}
          </div>

          <div className="year-btns">
            <button onClick={prevStep} className="prev-btn">
              Previous
            </button>
            <button onClick={nextStep}>Next</button>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Year;
