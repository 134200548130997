import React, { Fragment, useEffect } from "react";
import "./tyre.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";

const Tyre = ({
  setStep,
  step,
  tyreConditionList,
  setTyreCondition,
  nextStep,
  prevStep,
  setIsTyreCondActive,
  isTyreCondActive,
  checkCategory,
  editVehicleObj,
  tyreCondition,
}) => {
  if (editVehicleObj?._id) {
    let tyreCondIdx = tyreConditionList
      .map((tyreCond) => tyreCond.condition)
      .indexOf(tyreCondition);
    setIsTyreCondActive(tyreCondIdx);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="tyre-condition-container">
          <h5>
            Tyre Condition <span style={{ color: "red" }}>*</span>
          </h5>

          <div className="tyre-fields-container">
            <div className="tyre-condition-list">
              {tyreConditionList.map((cond, index) => (
                <div
                  className={
                    isTyreCondActive === index
                      ? "condition-data active"
                      : "condition-data"
                  }
                  key={index}
                  onClick={() => {
                    setTyreCondition(cond.condition);
                    setIsTyreCondActive(index);
                    setStep((prevState) => prevState + 1);
                  }}
                >
                  <p style={{ color: isTyreCondActive === index && "#ffffff" }}>
                    {cond.label}
                  </p>
                </div>
              ))}
            </div>

            <div className="tyre-btns">
              <button onClick={prevStep} className="prev-btn">
                Previous
              </button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Tyre;
