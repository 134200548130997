import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "./TermsAndConditions.style.css";
import "./contactus.style.css";
import TACbannerImg from "../assets/TACBanner.png";
import Footer from "./../components/Footer";
import gp from "../assets/app-store.png";
import ap from "../assets/google-play.png";
import contactLocIcon from "../assets/contact-loc-icon.png";
import contactTelIcon from "../assets/contact-tel-icon.png";
import contactEmailIcon from "../assets/contact-email-icon.png";
import contactIllustration from "../assets/contact-illustration.png";
import Constant from "../constants";
import { Helmet } from "react-helmet";
// import downArrow from "../assets/down-arrow.png";
// import upArrow from "../assets/up-arrow.png";
import axios from "axios";
import { toast } from "react-toastify";

function ContactUs() {
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    mobno: "",
    city: "",
    comment: "",
  });
  // const [cityDropdownBox, setCityDropdownBox] = useState(false);
  // const [cities, setCities] = useState([]);

  const { name, email, mobno, city, comment } = contactFormData;

  // const handleCityOnFoucs = () => {
  //   setCityDropdownBox((prevState) => !prevState);
  // };

  const handleOnChange = (e) => {
    setContactFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearFields = () => {
    setContactFormData((prevState) => ({
      ...prevState,
      name: "",
      email: "",
      mobno: "",
      city: "",
      comment: "",
    }));
  };

  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (name === "") {
      toast.error("Please enter your name");
    } else if (!validateName.test(name)) {
      toast.error("Please enter valid name");
    }

    if (email === "") {
      toast.error("Please enter your email");
    } else if (!validateEmail.test(email.trim())) {
      toast.error("Please enter valid email");
    }

    if (mobno === "") {
      toast.error("Please enter your mobile no");
    } else if (!validateMobNo.test(mobno)) {
      toast.error("Please enter valid mobile number");
    }

    if (city === "") {
      toast.error("Please enter your city");
    } else if (!validateName.test(city.trim())) {
      toast.error("Please enter valid city");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let fd = {
      name: name,
      email: email.trim(),
      mob_no: mobno,
      city: city.trim(),
      description: comment,
    };

    try {
      validateInputs();

      const response = await axios.post(Constant.postUrls.postContactUs, fd);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        clearFields();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const fetchCities = async () => {
  //     try {
  //       const response = await axios.get(
  //         Constant.getUrls.getAllCity + "?status=active&sort=true"
  //       );

  //       if (response) {
  //         setCities(response.data.getAllCities.docs);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchCities();
  // }, []);

  // const filterCities = cities.filter((cityFetch) =>
  //   cityFetch?.title?.toLowerCase().includes(city.toLowerCase())
  // );

  return (
    <div>
      <Helmet>
        <title>
          Contact Us | Buy &amp; Sell Used Commercial Vehicle - GaddiDeals
        </title>
        <meta
          name="description"
          content="GaddiDeals is a next-generation platform that has the purpose to ease the process of the used commercial vehicle market by providing buyers and sellers a platform to buy and sell used commercial vehicles"
        />
        <meta name="author" content="Carapace Technologies" />
        <meta
          property="og:title"
          content="GaddiDeals - Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gaddideals.com/" />
        <meta
          property="og:image"
          content="https://gaddideals.com/hero_BG.png"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GaddiDeals" />
        <meta
          name="twitter:description"
          content="Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta name="twitter:url" content="https://gaddideals.com/" />
        <meta
          name="twitter:image"
          content="https://gaddideals.com/hero_BG.png"
        />
      </Helmet>
      <Navbar />
      <div className="banner-div">
        <img
          className="tac-banner-img"
          src={TACbannerImg}
          alt="terms&conditionsImg"
        />
        <div className="heading-TAC">
          <span>Contact Us</span>
        </div>
        <div className="downloads-div">
          <img
            src={gp}
            className="gp-img"
            alt=""
            onClick={() => {
              window.location.href =
                "https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700058906740516_creativeid_480977734970_device_c&gclid=Cj0KCQjw54iXBhCXARIsADWpsG-CCJ0Va1SM47pcLde7OIB2qTUQqRMNXrjApqyWTvovb5yTz72kgn8aAnMeEALw_wcB&gclsrc=aw.ds";
            }}
          />
          <img
            src={ap}
            className="ap-img"
            alt=""
            onClick={() => {
              window.location.href = "https://www.apple.com/in/app-store/";
            }}
          />
        </div>
      </div>
      <div className="gd_container">
        {/* {cityDropdownBox && (
          <div
            onClick={() => setCityDropdownBox((prevState) => !prevState)}
            className="overlay-contact"
          ></div>
        )} */}

        <div className="contact-wrapper">
          <div className="contact-container">
            <h4>Let us contact you</h4>

            <div className="contact-form-container">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      placeholder="Enter Name"
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      placeholder="Enter Email"
                      onChange={handleOnChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="mobno"
                      value={mobno}
                      maxLength={10}
                      placeholder="Enter Mobile Number"
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 city-input">
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={city}
                      placeholder="City"
                      // onFocus={handleCityOnFoucs}
                      onChange={handleOnChange}
                    />

                    {/* {cityDropdownBox && (
                      <div className="city-dropdown-box">
                        {filterCities.length > 0 ? (
                          <>
                            {filterCities.map((city) => (
                              <p key={city?._id}>{city.title}</p>
                            ))}
                          </>
                        ) : (
                          <p>No Cities Available</p>
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <textarea
                      className="form-control"
                      name="comment"
                      placeholder="Comment"
                      rows="7"
                      value={comment}
                      onChange={handleOnChange}
                    ></textarea>
                  </div>
                </div>

                <div className="submit-btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="address-wrapper">
          <div className="address-container">
            <div className="left-address">
              <h4>Contact GaddiDeals</h4>
              <div className="contact-details">
                <div className="contact-icons">
                  <img src={contactLocIcon} alt="location" />
                </div>
                <div className="contact-text">
                  <h6>Goregaon West</h6>
                  <p>406,Unique Towers Goregoan (west) 400104</p>
                </div>
              </div>

              <div className="contact-details">
                <div className="contact-icons">
                  <img src={contactTelIcon} alt="telephone" />
                </div>
                <div className="contact-text">
                  <p>Have doubts? Just contact us.</p>
                  <h6>+91-9902544445</h6>
                </div>
              </div>

              <div className="contact-details">
                <div className="contact-icons">
                  <img src={contactEmailIcon} alt="email" />
                </div>
                <div className="contact-text">
                  <p>Be in touch with us by dropping mail.</p>
                  <h6>info@gaddideals.com</h6>
                </div>
              </div>
            </div>
            <div className="divider-line">
              <span className="cricle"></span>
              <div className="line"></div>
              <span className="circle"></span>
            </div>
            <div className="right-address">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15075.028900486279!2d72.83460128058356!3d19.162101461488337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b65081d27f11%3A0xa3ddbac169463ee3!2sGoregaon%20West%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1661411555984!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>
          </div>
        </div>

        {/* <div className="contact-illustration">
          <img src={contactIllustration} alt="illustratiob" />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
