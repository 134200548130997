import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAWSKeys } from "../store/aws/aws.selector";
import * as AWS from "@aws-sdk/client-translate";

export default function AWS_Translater({ _text }) {
  const clientObj = useSelector(selectAWSKeys);
  const [text, setText] = useState(_text);

  useEffect(() => {
    if (localStorage.getItem("lang") === "hi") {
      const client = new AWS.Translate({
        region: "us-east-2",
        credentials: {
          accessKeyId: clientObj.awsKey?.aws_key,
          secretAccessKey: clientObj.awsKey?.access_key,
        },
      });

      client
        ?.translateText({
          SourceLanguageCode: "en",
          TargetLanguageCode: "hi",
          Text: _text,
        })
        .then((resp) => {
          setText(resp.TranslatedText);
        });
    }
  }, []);

  return <>{text}</>;
}
