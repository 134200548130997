import React, { useState, useEffect } from "react";
import VehicleDetails from "./pages/VehicleDetails";
import VehicleListings from "./pages/VehicleListings";
import UserLogin from "./pages/UserLogin";
import { Routes, Route, useNavigate } from "react-router-dom";
import SellerHomePage from "./pages/SellerHomePage";
import UserVehicles from "./pages/UserVehicles";
import LoggedUser from "./pages/LoggedUser";
import UserOrder from "./pages/UserOrder";
import UserFaq from "./pages/UserFaq";
import Subscription from "./pages/Subscription";
import SellerHome from "./pages/SellerHome";
import VehicleCard from "./pages/VehicleCard";
import DirectSignup from "./components/DirectSignup";
import MyVehicleEnq from "./pages/MyVehicleEnq";
import MySoldVehicles from "./pages/MySoldVehicles";
import ChangePassword from "./pages/ChangePassword";
import "../src/App.css";
import { CircularProgress } from "@mui/material";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Tooltip } from "bootstrap";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./components/PageNotFound";
import MobileDialog from "./components/MobileDialog/MobileDialog";

import { useDispatch } from "react-redux";
import { setAWSSecretKeys } from "./store/aws/aws.action";
import axios from "axios";
import Constant from "./constants";
import * as AWS from "@aws-sdk/client-translate";

import Wizard from "./components/Wizard/Wizard";

import AOS from "aos";
import "aos/dist/aos.css";

const queryString = require("query-string");

function App() {
  const location = queryString.parse(window.location.search);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  AOS.init();

  const navigate = useNavigate();
  const isAndroid = /Android/i;
  const isIphone = /iPhone/i;

  const fetchCredentials = async () => {
    const response = await axios.get(
      Constant.getUrls.getCred + "?secret=GaddidealsAWS@2022"
    );

    // const client = new AWS.Translate({
    //   region: "us-east-2",
    //   credentials: {
    //     accessKeyId: response?.data?.awsKey?.aws_key,
    //     secretAccessKey: response?.data?.awsKey?.access_key,
    //   },
    // });

    dispatch(setAWSSecretKeys(response.data));
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    let cookie = document.cookie;
    if (!cookie.includes("googtrans")) {
      document.cookie = "googtrans=/auto/en";
    }

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    fetchCredentials();
  }, []);

  if (!location) {
    const lang = localStorage.getItem("lang");
    let prevUrl = queryString.stringify(location);

    if (lang) {
      prevUrl += "&lang=" + lang;
    } else {
      prevUrl += "&lang=" + "en";
    }

    navigate("?" + prevUrl);
  }

  return (
    <>
      {navigator.userAgent.match(isAndroid) ? (
        <MobileDialog detectOS={isAndroid} />
      ) : navigator.userAgent.match(isIphone) ? (
        <MobileDialog iphoneOS={isIphone} />
      ) : (
        ""
      )}
      <div id="google_translate_element"></div>
      <>
        <Routes>
          {/* all routes here */}
          <Route element={<SellerHomePage />} index />
          <Route element={<SellerHome />} path="SellUsedCommercialVehicle" />
          <Route
            element={<VehicleListings />}
            path="BuyUsedCommercialVehicle"
          />
          <Route element={<VehicleDetails />} path="/vehicledetails/:id" />
          <Route element={<UserLogin />} path="/UserLogin" />
          <Route element={<LoggedUser />} path="/LoggedUser" />
          <Route element={<UserVehicles />} path="/UserVehicles" />
          <Route element={<UserOrder />} path="/UserOrder" />
          <Route element={<UserFaq />} path="/UserFaq" />
          <Route element={<Subscription />} path="/Subscription" />
          <Route element={<ChangePassword />} path="/changepassword" />
          {/* <Route element={<SellerForm />} path="/sellerform/:categoryId" /> */}
          <Route element={<Wizard />} path="/sellerform/:categoryId" />
          <Route element={<VehicleCard />} path="/VehicleCard" />
          <Route element={<DirectSignup />} path="/DirectSignup" />
          <Route element={<AboutUs />} path="/AboutUs" />
          <Route element={<ContactUs />} path="/ContactUs" />
          <Route element={<TermsAndConditions />} path="/termsandconditions" />
          <Route element={<PrivacyPolicy />} path="/PrivacyPolicy" />
          <Route element={<MyVehicleEnq />} path="/myvehicleenq" />
          <Route element={<MySoldVehicles />} path="/mysoldvehicles" />
          <Route element={<PageNotFound />} path="*" />
        </Routes>
        <ToastContainer autoClose={5000} />
      </>
    </>
  );
}
export default App;
