import React, { Fragment } from "react";
import "./brand.css";

import Navbar from "../Navbar";
import Footer from "../Footer";
import Stepper from "../Stepper/Stepper";

import searchIcon from "../../assets/search-icon.png";

import { imgurl } from "../../constants";

const Brand = ({
  setStep,
  brandsList,
  brandText,
  setBrandId,
  handleOnChange,
  step,
  fetchModels,
  checkCategory,
  nextStep,
  prevStep,
  setFormFields,
  editVehicleObj,
}) => {
  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="brands-container">
          <h5>
            Select Brand <span style={{ color: "red" }}>*</span>
          </h5>

          <div className="brands-fields-container">
            <div className="brand-input">
              <input
                type="search"
                name="brandText"
                value={brandText}
                placeholder="Search"
                onChange={handleOnChange}
              />
              <img src={searchIcon} alt="search icon" />
            </div>

            <p className="selected-text">{}</p>

            <div className="brand-list">
              {brandsList.map((brand) => (
                <div
                  className="brand-ui-container"
                  key={brand._id}
                  onClick={() => {
                    setBrandId(brand._id);
                    fetchModels(brand._id);
                    setFormFields((prevState) => ({
                      ...prevState,
                      brandText: brand.title,
                    }));
                    setStep((prevState) => prevState + 1);
                  }}
                >
                  <div className="brand-img">
                    <img src={imgurl + brand?.image} alt={brand?.title} />
                  </div>
                  <p>{brand?.title}</p>
                </div>
              ))}
            </div>

            {editVehicleObj?._id && (
              <div className="model-btns">
                <button onClick={nextStep}>Next</button>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Brand;
