import React, { Fragment, useEffect } from "react";
import "./fueltype.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";

const FuelType = ({
  setStep,
  step,
  fueltypeList,
  isFuelTypeActive,
  setIsFuelTypeActive,
  setFuelTypeId,
  nextStep,
  prevStep,
  checkCategory,
  handleOnChange,
  horsePowerText,
  noOfHrsText,
  editVehicleObj,
  fuelTypeId,
  RCList,
  setRC,
  rc,
  setIsRCActive,
  isRCActive,
  ownersList,
  setOwner,
  setIsOwnerActive,
  isOwnerActive,
  owner,
}) => {
  if (editVehicleObj?._id) {
    let fuelTypeIndex = fueltypeList
      .map((fuelType) => fuelType._id)
      .indexOf(fuelTypeId);
    setIsFuelTypeActive(fuelTypeIndex);

    let rcIndex = RCList.map((rc) => rc.rc).indexOf(rc);
    setIsRCActive(rcIndex);

    let ownerIndex = ownersList.map((owner) => owner.owner).indexOf(owner);
    setIsOwnerActive(ownerIndex);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        {checkCategory === "Tractors" ? (
          <div className="body-fields-container">
            <div className="rc-container">
              <h6>
                RC <span style={{ color: "red" }}>*</span>
              </h6>
              <div className="rc-data-container">
                {RCList.map((rcDoc, index) => (
                  <div
                    key={index}
                    className={
                      isRCActive === index ? "rc-data active" : "rc-data"
                    }
                    onClick={() => {
                      setRC(rcDoc.rc);
                      setIsRCActive(index);
                    }}
                  >
                    <p style={{ color: isRCActive === index && "#ffffff" }}>
                      {rcDoc.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="owner-type-container">
              <h6
                style={{
                  color: "#050f56",
                  fontWeight: "bold",
                }}
              >
                Owners <span style={{ color: "red" }}>*</span>
              </h6>

              <div className="owner-fields-container">
                <div className="owner-type-list">
                  {ownersList.map((ownerData, index) => (
                    <div
                      className={
                        isOwnerActive === index
                          ? "owner-data active"
                          : "owner-data"
                      }
                      key={index}
                      onClick={() => {
                        setOwner(ownerData.owner);
                        setIsOwnerActive(index);
                        if (checkCategory === "Tractors") {
                          if (rc) {
                            setStep((prevState) => prevState + 1);
                          }
                        }
                      }}
                    >
                      <p
                        style={{ color: isOwnerActive === index && "#ffffff" }}
                      >
                        {ownerData.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="body-btns">
              <button onClick={prevStep} className="prev-btn">
                Previous
              </button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        ) : (
          <div className="fuel-type-container">
            <h5>
              Fuel Type <span style={{ color: "red" }}>*</span>
            </h5>

            <div className="fuel-fields-container">
              <div className="fuel-type-list">
                {fueltypeList.map((fuel, index) => (
                  <div
                    className={
                      isFuelTypeActive === index
                        ? "fuel-data active"
                        : "fuel-data"
                    }
                    key={index}
                    onClick={() => {
                      setFuelTypeId(fuel._id);
                      setIsFuelTypeActive(index);
                      setStep((prevState) => prevState + 1);
                    }}
                  >
                    <p
                      style={{ color: isFuelTypeActive === index && "#ffffff" }}
                    >
                      {fuel.title}
                    </p>
                  </div>
                ))}
              </div>

              <div className="fuel-btns">
                <button onClick={prevStep} className="prev-btn">
                  Previous
                </button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default FuelType;
