import React, { Fragment } from "react";
import "./stepper.css";

const stepsTractorArray = [
  {
    label: "Brand",
  },
  {
    label: "Model",
  },
  {
    label: "Year",
  },
  {
    label: "Tyre",
  },
  {
    label: "Engine",
  },
  {
    label: "Info",
  },
  {
    label: "Horse Power",
  },
  {
    label: "Details",
  },
  {
    label: "Image",
  },
  {
    label: "User",
  },
];

const stepsConstructionArray = [
  {
    label: "Brand",
  },
  {
    label: "Model",
  },
  {
    label: "Year",
  },
  {
    label: "Tyre",
  },
  {
    label: "Engine",
  },
  {
    label: "Fuel",
  },
  {
    label: "Info",
  },
  {
    label: "Details",
  },
  {
    label: "Image",
  },
  {
    label: "User",
  },
];

const stepsArray = [
  {
    title: "Brand",
  },
  {
    title: "Model",
  },
  {
    title: "Year",
  },
  {
    title: "Tyre",
  },
  {
    title: "Body",
  },
  {
    title: "Fuel",
  },
  {
    title: "Info",
  },
  {
    title: "Details",
  },
  {
    title: "Image",
  },
  {
    title: "User",
  },
];

const Stepper = ({ setStep, stepIndex, checkCategory }) => {
  return (
    <div className="stepper-container">
      <div className="step-divider"></div>

      {checkCategory === "Tractors" ? (
        <Fragment>
          {stepsTractorArray.map((step, index) => (
            <div
              className="step-container"
              key={index}
              onClick={() => {
                if (index <= stepIndex) {
                  setStep(index);
                }
              }}
            >
              <div
                className={
                  index <= stepIndex ? "step-circle active" : "step-circle"
                }
              ></div>
              <p>{step.label}</p>
            </div>
          ))}
        </Fragment>
      ) : checkCategory === "Construction Equipments" ? (
        <Fragment>
          {stepsConstructionArray.map((step, index) => (
            <div
              className="step-container"
              key={index}
              onClick={() => {
                if (index <= stepIndex) {
                  setStep(index);
                }
              }}
            >
              <div
                className={
                  index <= stepIndex ? "step-circle active" : "step-circle"
                }
              ></div>
              <p>{step.label}</p>
            </div>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {stepsArray.map((step, index) => (
            <div
              className="step-container"
              key={index}
              onClick={() => {
                if (index <= stepIndex) {
                  setStep(index);
                }
              }}
            >
              <div
                className={
                  index <= stepIndex ? "step-circle active" : "step-circle"
                }
              ></div>
              <p>{step.title}</p>
            </div>
          ))}
        </Fragment>
      )}
    </div>
  );
};

export default Stepper;
