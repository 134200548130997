import React, { Fragment, useRef } from "react";
import "./info.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";
const Info = ({
  setStep,
  step,
  RCList,
  setRC,
  rc,
  setIsRCActive,
  isRCActive,
  permitList,
  setPermit,
  permit,
  setIsPermitActive,
  isPermitActive,
  insuranceValidity,
  taxValidity,
  fitnessValidity,
  handleOnChange,
  nextStep,
  prevStep,
  checkCategory,
  editVehicleObj,
  ownersList,
  setOwner,
  setIsOwnerActive,
  isOwnerActive,
  owner,
  horsePowerText,
}) => {
  const insuranceRef = useRef(null);
  const taxRef = useRef(null);
  const fitnessRef = useRef(null);

  const handleInsuranceFocus = () => {
    insuranceRef.current.showPicker();
  };
  const handleTaxFocus = () => {
    taxRef.current.showPicker();
  };
  const handleFitnessFocus = () => {
    fitnessRef.current.showPicker();
  };

  if (editVehicleObj?._id) {
    let rcIndex = RCList.map((rc) => rc.rc).indexOf(rc);
    setIsRCActive(rcIndex);

    let ownerIndex = ownersList.map((owner) => owner.owner).indexOf(owner);
    setIsOwnerActive(ownerIndex);

    let permitIndex = permitList
      .map((permitData) => permitData.permit)
      .indexOf(permit);
    setIsPermitActive(permitIndex);
  }

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        {checkCategory !== "Tractors" && (
          <div className="info-container">
            <h5>Vehicle Information</h5>
          </div>
        )}

        {checkCategory === "Tractors" ? (
          <>
            <div className="form-input-control">
              <h6>
                Horse Power <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="horsePowerText"
                onChange={handleOnChange}
                value={horsePowerText}
              />
            </div>
            <div className="body-btns">
              <button onClick={prevStep} className="prev-btn">
                Previous
              </button>
              <button onClick={nextStep}>Next</button>
            </div>
          </>
        ) : (
          <div className="info-fields-container">
            <div className="rc-container">
              <h6>
                RC <span style={{ color: "red" }}>*</span>
              </h6>
              <div className="rc-data-container">
                {RCList.map((rcDoc, index) => (
                  <div
                    key={index}
                    className={
                      isRCActive === index ? "rc-data active" : "rc-data"
                    }
                    onClick={() => {
                      setRC(rcDoc.rc);
                      setIsRCActive(index);
                    }}
                  >
                    <p style={{ color: isRCActive === index && "#ffffff" }}>
                      {rcDoc.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="owner-type-container">
              <h6
                style={{
                  color: "#050f56",
                  fontWeight: "bold",
                }}
              >
                Owners <span style={{ color: "red" }}>*</span>
              </h6>

              <div className="owner-fields-container">
                <div className="owner-type-list">
                  {ownersList.map((ownerData, index) => (
                    <div
                      className={
                        isOwnerActive === index
                          ? "owner-data active"
                          : "owner-data"
                      }
                      key={index}
                      onClick={() => {
                        setOwner(ownerData.owner);
                        setIsOwnerActive(index);
                        if (checkCategory === "Tractors") {
                          if (rc) {
                            setStep((prevState) => prevState + 1);
                          }
                        }
                      }}
                    >
                      <p
                        style={{ color: isOwnerActive === index && "#ffffff" }}
                      >
                        {ownerData.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {checkCategory === "Trucks" && (
              <div className="permit-container">
                <h6>Vehicle Length(in feet)</h6>
                <div className="permit-data-container">
                  <input
                    type="text"
                    name="permit"
                    onChange={(e) => setPermit(e.target.value)}
                    value={permit}
                  />
                  {/* {permitList.map((permitDOC, index) => (
                    <div
                      className={
                        isPermitActive === index
                          ? "permit-data active"
                          : "permit-data"
                      }
                      onClick={() => {
                        setPermit(permitDOC.permit);
                        setIsPermitActive(index);
                      }}
                      key={index}
                    >
                      <p
                        style={{ color: isPermitActive === index && "#ffffff" }}
                      >
                        {permitDOC.label}
                      </p>
                    </div>
                  ))} */}
                </div>
              </div>
            )}

            {checkCategory === "Buses" && (
              <div className="permit-container">
                <h6>Vehicle Length(in feet)</h6>
                <div className="permit-data-container">
                  <input
                    type="text"
                    name="permit"
                    onChange={(e) => setPermit(e.target.value)}
                    value={permit}
                  />
                  {/* {permitList.map((permitDOC, index) => (
                    <div
                      className={
                        isPermitActive === index
                          ? "permit-data active"
                          : "permit-data"
                      }
                      onClick={() => {
                        setPermit(permitDOC.permit);
                        setIsPermitActive(index);
                      }}
                      key={index}
                    >
                      <p
                        style={{ color: isPermitActive === index && "#ffffff" }}
                      >
                        {permitDOC.label}
                      </p>
                    </div>
                  ))} */}
                </div>
              </div>
            )}

            {checkCategory !== "Tractors" && (
              <div className="info-input-controls">
                <h6>
                  Insurance Validity <span style={{ color: "red" }}>*</span>
                </h6>
                <input
                  type="date"
                  name="insuranceValidity"
                  onChange={handleOnChange}
                  value={insuranceValidity}
                  ref={insuranceRef}
                  onFocus={handleInsuranceFocus}
                />
              </div>
            )}

            {checkCategory !== "Tractors" && (
              <div className="info-input-controls">
                <h6>
                  Tax Validity <span style={{ color: "red" }}>*</span>
                </h6>
                <input
                  type="date"
                  name="taxValidity"
                  onChange={handleOnChange}
                  value={taxValidity}
                  ref={taxRef}
                  onFocus={handleTaxFocus}
                />
              </div>
            )}

            {checkCategory !== "Tractors" && (
              <div className="info-input-controls">
                <h6>
                  Fitness Validity
                  {checkCategory !== "Construction Equipments" && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </h6>
                <input
                  type="date"
                  name="fitnessValidity"
                  onChange={handleOnChange}
                  value={fitnessValidity}
                  ref={fitnessRef}
                  onFocus={handleFitnessFocus}
                />
              </div>
            )}

            <div className="info-btns">
              <button onClick={prevStep} className="prev-btn">
                Previous
              </button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default Info;
