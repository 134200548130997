import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import imgPlaceholder from "../assets/img-not-available.jpg";
import { imgurl } from "../constants";

import { Helmet } from "react-helmet";

import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import locationIcon from "../assets/location.png";
import searchIcon from "../assets/search-icon.png";
import truckHomeImage from "../assets/truck-home.png";
import "./vehiclelistings.style.css";

import Constant from "../constants";

import lottieAnimation from "../assets/my-vehicles-lottie.json";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ToggleCategory from "../components/ToggleCategory";

import axios from "axios";

import downArrow from "../assets/down-arrow.png";
import upArrow from "../assets/up-arrow.png";

import { Slider, withStyles } from "@material-ui/core";

import { toast } from "react-toastify";

import Modal from "react-awesome-modal";
import OtpInput from "react-otp-input";
import CloseTab from "../assets/close-tab.png";
import gaddiLoader from "../assets/gaddideal_loader.png";
// import downArrow from "../assets/down-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import Edit from "../assets/edit.png";
import AWS_Translater from "../Helpers/AWS_Translater";
import { selectLocation } from "../store/location/location.selector";
import { setPageNoValue } from "../store/pageno/pageno.action";
import { selectPageNoValue } from "../store/pageno/pageno.selector";
import {
  setMobSignInValue,
  setSignUpValue,
} from "../store/signup/signup.action";

const queryString = require("query-string");

const PrettoSlider = withStyles({
  root: { color: "#050F56", height: 10 },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#050F56",
    border: "none",
    marginTop: -5,
    marginLeft: -9,
  },
  track: { height: 5, borderRadius: 7, backgroundColor: "#050F56" },
  rail: { height: 5, borderRadius: 7 },
})(Slider);

const VehicleListings = () => {
  let location = queryString.parse(window.location.search);
  let user_token = localStorage.getItem("Token");
  const [locationDropDown, setlocationDropDown] = useState(false);

  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [vehiclesArray, setVehiclesArray] = useState(
    JSON.parse(localStorage.getItem("vehicles") || "[]")
  );
  const [vehicleObj, setVehicleObj] = useState({});
  const [bodytypes, setBodyTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [modelYears, setModelYears] = useState([]);
  const [kmsDriven, setKmsDriven] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [lth, setLTH] = useState(location.kim_driven_sort);
  const [cat, setCat] = useState(location.category);
  const [lthPrice, setLTHPrice] = useState(location.price_sort);
  const [recentlyAdded, setRecentlyAdded] = useState(location.sort);
  const [searchInput, setSearchInput] = useState("");
  const [minPrice, setMinPrice] = useState(location.min_price || 0);
  const [maxPrice, setMaxPrice] = useState(location.max_price || 850000);
  const [minMax, setMinMax] = useState([0, 2000000]);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [moreLoader, setMoreLoader] = useState(false);
  const [displayFilterTwo, setdisplayFilterTwo] = useState(false);
  const [displayFilterOne, setdisplayFilterOne] = useState(false);
  const [statesArray, setStatesArray] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [selectedStateIds, setselectedStateIds] = useState([]);
  const [yearsValue, setYearsValue] = useState([1991, 2022]);
  const [kmsValue, setKmsValue] = useState([0, 100000]);
  const [noOfHrsValue, setNoOfHrsValue] = useState([0, 100000]);
  const [horsePowerValue, setHorsePowerValue] = useState([0, 2000]);
  const [noOfSeatsValue, setNoOfSeatsValue] = useState([0, 100]);

  const [filterState, setfilterState] = useState("");
  const [citySearchValue, setCitySearchValue] = useState("");
  const [modelSearchValue, setModelSearchValue] = useState("");
  const [brandSearchValue, setBrandSearchValue] = useState("");

  const [loading, setLoading] = useState(true);

  const filterSearchCity = citiesArray.filter((citySearch) =>
    citySearch.title.toLowerCase().includes(citySearchValue.toLowerCase())
  );

  const filterSelectedStates = statesArray.filter((data) =>
    data.title.toLowerCase().includes(filterState.toLowerCase())
  );

  const locationCity = useSelector(selectLocation);
  const pageNoVal = useSelector(selectPageNoValue);

  // for get seller details
  const [userObj, setUserObj] = useState();
  const [BuyerInput, setBuyerInput] = useState(false);
  const [BuyerOtp, setBuyerOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [SellerDetails, setSellerDetails] = useState(false);

  const [userToken, setUserToken] = useState(localStorage.getItem("Token"));

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mob_no, setmob_no] = useState("");
  const [regNo, setRegNo] = useState("");
  const [city, setcity] = useState("");
  const [userType, setUserType] = useState("");
  const [user_type, setuser_type] = useState("");
  const [isTypeActive, setIsTypeActive] = useState();
  const [seller_id, setseller_id] = useState("");
  const [seller, setSeller] = useState("");
  const [resendId, setResendId] = useState("");

  const [isSortOpen, setIsSortOpen] = useState(false);

  const dealerType = [
    {
      user_type: "Company",
    },
    {
      user_type: "Sole Proprietor",
    },
    {
      user_type: "Agent/Broker",
    },
    {
      user_type: "Dealer",
    },
    {
      user_type: "Other",
    },
  ];

  const [statecities, setstatecities] = useState([]);

  const fetchLocationCities = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllCity + "?limit=5000"
    );
    setstatecities(response?.data?.getAllCities?.docs);
  };

  const [EnableResendOtp, setEnableResendOtp] = useState(false);
  const [timerVisible, settimerVisible] = useState(true);

  const [counter, setCounter] = React.useState(25);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setEnableResendOtp(true);
      settimerVisible(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );
  const filterCurentCities = statecities.filter((data) =>
    data.title.toLowerCase().includes(city.toLowerCase())
  );

  function getSingleUserInfo() {
    let user_token = localStorage.getItem("Token");
    axios
      .get(Constant.getUrls.getSingleUser, {
        headers: {
          Authorization: ` Bearer ${user_token} `,
        },
      })
      .then((res) => {
        setcity(res.data.user.city);
        setname(res.data.user.name);
        setemail(res.data.user.email);
        setmob_no(res.data.user.mob_no);
        setuser_type(res?.data?.user?.user_type);
        setBuyerInput(!BuyerInput); //displaying buyer input detail screen
        // saveBuyer();

        if (res?.data?.user?.user_type) {
          let typeIdx = dealerType
            .map((type) => type.user_type)
            .indexOf(res?.data?.user?.user_type);
          console.log(typeIdx);

          setIsTypeActive(typeIdx);
        }
      });
  }

  function saveBuyer() {
    let payload = {
      seller_id,
      name: name.trim(),
      email: email.trim(),
      mob_no,
      user_type,
      city,
      hash: "ekxpmAB8m9v",
    };
    axios.post(Constant.postUrls.postAllEnquiries, payload).then((result) => {
      if (result.data.status === "failed") {
        toast.error(result.data.message);
      } else {
        if (result.data.status === "success") {
          toast.success(result.data.message);
          // setvisibleOTP(!visibleOTP);
          setmob_no(mob_no);
          setSellerDetails(!SellerDetails);
          // setOtp(otp);
          setBuyerOtp(!BuyerOtp);
          // setCounter(59);
        }
      }
    });
  }

  function verifyOtp() {
    console.warn({ mob_no });
    let payload = { mob_no, hash: "ekxpmAB8m9v" };
    axios.post(Constant.postUrls.postAllSignins, payload).then((result) => {
      console.log("result", result);
      if (mob_no === "") {
        // notify();
        toast.error("enter moile number");
      } else if (result.data.status === "failed") {
        toast.error(result.data.message);
      } else {
        if (result.data.status === "success") {
          toast.success(result.data.message);

          // setOtp(result.data.otp);
          // setvisibleOTP(!visibleOTP);
          // setvisible(false);

          // setCounter(59);
        }
      }
    });
  }
  function handleChange(o) {
    setOtp(o);
    // console.log(otp);
  }
  function savePhoneOtp() {
    // console.log("otp verified");
    console.warn({ mob_no, otp });
    let payload = { mob_no, otp };
    axios.post(Constant.postUrls.postAllOtps, payload).then((res) => {
      // console.log(res);

      if (res.data.status === "failed") {
        toast.error("incorrect otp");
      } else if (res.data.status === "Success") {
        toast.success(res.data.message);
        // setSellerDetails(!SellerDetails);
        setBuyerOtp(!BuyerOtp);
      }
    });
  }

  function resendotp() {
    console.warn({ mob_no });
    let payload = { mob_no, hash: "ekxpmAB8m9v", _id: resendId };
    axios
      .post(Constant.postUrls.postEnquiryResendOTP, payload)
      .then((result) => {
        // console.log("result", result);
        if (mob_no === "") {
          toast.error("enter mobile number");
        } else if (result.data.status === "failed") {
          toast.error(result.data.message);
        } else {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            // setOtp(result.data.otp);
            settimerVisible(true);
            setEnableResendOtp(false);
            setCounter(25);
            // setCounter(59);
          }
        }
      });
  }

  //enquiry otp verify
  function enquiryVerifyOtp() {
    if (ValidateMobileNo()) {
      console.log("otp verified");
      console.warn({ mob_no, otp });
      let payload = { mob_no, otp };
      axios.post(Constant.postUrls.postAllEnquiryOtps, payload).then((res) => {
        console.log(res);

        if (res.data.status === "failed") {
          toast.error("incorrect otp");
        } else if (res.data.status === "Success") {
          toast.success(res.data.message);
          setBuyerOtp(!BuyerOtp); //closingotp screen
          setSellerDetails(!SellerDetails); //displaing seller details
          // saveBuyer();
        }
      });
    }
  }

  const validateFields = () => {
    let validateName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
    let validateMobNo =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

    const cityExists = statecities.find(
      (data) => city.toLowerCase() === data.title.toLowerCase()
    );

    if (!validateName.test(name.trim())) {
      toast.error("please enter valid name");
      return false;
    }
    if (!validateMobNo.test(mob_no)) {
      toast.error("please enter valid mobile number");
      return false;
    }
    if (!validateEmail.test(email.trim())) {
      toast.error("please enter valid email id");
      return false;
    }
    if (city === "") {
      toast.error("please enter location");
      return false;
    }
    if (!cityExists) {
      toast.error("Please select a city");
      return false;
    }
    if (user_type === "") {
      toast.error("please select user type");
      return false;
    }

    return true;
  };

  //for otp page
  const ValidateMobileNo = () => {
    let validateMobNo =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (!validateMobNo.test(mob_no)) {
      toast.error("please enter valid mobile number");
      return false;
    } else {
      return true;
    }
  };

  //signup otp verify
  function signUpVeryfyOtp() {
    if (ValidateMobileNo()) {
      console.log("otp verified");
      console.warn({ mob_no, otp });
      let payload = { mob_no, otp };
      axios.post(Constant.postUrls.postAllOtps, payload).then((res) => {
        console.log(res);
        localStorage.setItem("Token", res.data.user.accessToken);
        window.location.href = "/loggeduser";
        // if (res.data.status === "failed") {
        // toast.error("incorrect otp");
        // } else if (res.data.status === "Success") {
        // toast.success(res.data.message);
        // setBuyerOtp(!BuyerOtp); //closingotp screen
        // setSellerDetails(!SellerDetails); //displaing seller details
        // saveBuyer();
        // }
      });
    }
  }
  //for already logged user
  async function LoggedenquiryApi() {
    let user_token = localStorage.getItem("Token");

    if (validateFields()) {
      let payload = {
        vehicleId: seller_id,
        name: name.trim(),
        email: email.trim(),
        mob_no,
        user_type,
        city,
        hash: "ekxpmAB8m9v",
      };
      await axios
        .post(Constant.postUrls.postAllEnquiries, payload, {
          headers: {
            Authorization: ` Bearer ${user_token} `,
          },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.status === "failed") {
            console.log(result.data);
            toast.error(result.data.message);
          } else {
            if (result.data.status === "success") {
              console.log(result.data);
              // toast.success(result.data.message);
              setResendId(result.data.data._id);
              setBuyerInput(!BuyerInput); //closing buyer input screen
              setBuyerOtp(!BuyerOtp); //displaying otp screen
            }
          }
        });
    }
  }

  //for user visiting for the first time
  async function enquiryApi() {
    let user_token = localStorage.getItem("Token");

    if (validateFields()) {
      let payload = {
        vehicleId: seller_id,
        name: name.trim(),
        email: email.trim(),
        mob_no,
        user_type,
        city,
        hash: "ekxpmAB8m9v",
      };
      await axios
        .post(Constant.postUrls.postAllEnquiries, payload)
        .then((result) => {
          console.log(result.data);
          if (result.data.status === "failed") {
            console.log(result.data);
            // toast.error(result.data.message);
            toast.error("You are already a user");
            toast.error("Please sign in for enquiry");
          } else {
            if (result.data.status === "success") {
              console.log(result.data);
              // toast.success(result.data.message);
              setResendId(result.data.data._id);
              setBuyerInput(!BuyerInput); //closing buyer input screen
              setBuyerOtp(!BuyerOtp); //displaying otp screen
            }
          }
        });
    }
  }

  const navigate = useNavigate();

  // const locationCity = useSelector(selectLocation);

  const clearFilterParams = () => {
    location = {};
    navigate("/BuyUsedCommercialVehicle");
    window.location.reload();
  };

  const handleSearchChange = async (e) => {
    setSearchInput(e.target.value);

    if (searchInput) {
      const res = await axios.get(
        `${Constant.getUrls.getAllVehicles}` +
          `?status=approved&q=` +
          searchInput.toLowerCase()
      );

      setVehiclesArray(res.data.vehicle.docs);
    }
  };

  const handleMin = (e) => {
    setMinPrice(e.target.value);

    location["min_price"] = e.target.value;
  };

  const handleMax = (e) => {
    setMaxPrice(e.target.value);

    if (minPrice < maxPrice) {
      location["max_price"] = e.target.value;
      location["min_price"] = minPrice;
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      fetchVehiclesAPI(location);
    }
  };

  const handleMinMax = (event, minMaxValue) => {
    setMinMax(minMaxValue);

    if (minMaxValue) {
      location["min_price"] = minMaxValue[0];
      location["max_price"] = minMaxValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    }
  };

  const handleYears = (event, yearValue) => {
    setYearsValue(yearValue);

    if (yearValue) {
      location["min_year"] = yearValue[0];
      location["max_year"] = yearValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    }
  };

  const handleKMS = (event, kmsValue) => {
    setKmsValue(kmsValue);

    if (kmsValue) {
      location["min_km"] = kmsValue[0];
      location["max_km"] = kmsValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    } else {
      if (!kmsValue) {
        window.location.href = "/";
      }
    }
  };

  const handleNoOfHrs = (event, hrsValue) => {
    setNoOfHrsValue(hrsValue);
    if (hrsValue) {
      location["min_hrs"] = hrsValue[0];
      location["max_hrs"] = hrsValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    }
  };

  const handleHorsePower = (event, horseValue) => {
    setHorsePowerValue(horseValue);
    if (horseValue) {
      location["min_hp"] = horseValue[0];
      location["max_hp"] = horseValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    }
  };

  const handleNoOfSeats = (event, seatsValue) => {
    setNoOfSeatsValue(seatsValue);

    if (seatsValue) {
      location["min_seats"] = seatsValue[0];
      location["max_seats"] = seatsValue[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      // fetchVehiclesAPI(location);
    }
  };

  const fetchCategories = async () => {
    const response = await axios.get(`${Constant.getUrls.getAllCategories}`);
    if (response) {
      setCategories(response.data.category.docs);
      // if (!location.category) {
      //   setCat(response.data.category.docs[0]._id);
      //   location.category_name =
      //     response.data.category.docs[0].title.toLowerCase();
      //   console.log(locationCity);
      //   // location.city = cityId;
      //   location.category = response.data.category.docs[0]._id;
      //   let prevUrl = queryString.stringify(location);
      //   navigate("?" + prevUrl);
      // }
      // fetchVehiclesAPI(location);
    }
  };

  const rupee_format = (str) => {
    var x = str;
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const fetchVehiclesAPI = async (location) => {
    const apiUrl = queryString.stringify(location);
    const res = await axios.get(
      `${Constant.getUrls.getAllVehicles}?status=approved&${apiUrl}&is_sold=no&page=${pageNoVal}`
    );

    console.log(res.data.vehicle.docs);
    if (res.data.status === "success") {
      setLoading(false);
      setHasMore(res.data.vehicle.hasNextPage);
    }

    setVehicleObj(res.data.vehicle);

    if (!JSON.parse(localStorage.getItem("vehicles"))) {
      setVehiclesArray(res.data.vehicle.docs);
      setTotalPages(res.data.vehicle.totalPages);
    }
  };

  const fetchItems = async () => {
    dispatch(setPageNoValue(pageNoVal + 1));

    const apiUrl = queryString.stringify(location);

    if (pageNoVal > 1) {
      setMoreLoader(true);
      const response = await axios.get(
        `${Constant.getUrls.getAllVehicles}?status=approved&${apiUrl}&is_sold=no&page=${pageNoVal}`
      );

      setHasMore(response.data.vehicle.hasNextPage);

      localStorage.setItem(
        "vehicles",
        JSON.stringify([...vehiclesArray, ...response.data.vehicle.docs])
      );

      setVehiclesArray((prevState) => [
        ...prevState,
        ...response.data.vehicle.docs,
      ]);

      setMoreLoader(false);
    }

    // return response.data.vehicle.docs;
  };

  const fetchData = async () => {
    const vehicles = await fetchItems();

    setVehiclesArray([...vehiclesArray, ...vehicles]);

    // localStorage.setItem(
    //   "vehicles",
    //   JSON.stringify([...vehiclesArray, ...vehicles])
    // );

    if (vehicles.length === 0 || vehicles.length < 12) {
      setHasMore((prevState) => !prevState);
    }

    // setPageNo(pageNo + 1);

    // window.history.replaceState(`?page=${pageNo}`, "");
  };

  const fetchBodyTypes = async () => {
    const response = await axios.get(
      Constant.getUrls.getAllBodyTypes +
        `?status=active&limit=500&category=${cat}`
    );
    setBodyTypes(response.data._getBodyType.docs);
  };

  const fetchBrands = async () => {
    const response = await axios.get(
      `${Constant.getUrls.getAllBrands}?limit=5000`
    );
    setBrands(response.data.brand.docs);
  };

  const fetchModels = async () => {
    const response = await axios.get(
      `${Constant.getUrls.getAllModels}?limit=5000`
    );
    setModels(response.data.model.docs);
  };

  const fetchModelYears = async () => {
    const response = await axios.get(`${Constant.getUrls.getAllYears}`);
    setModelYears(response.data._yrs.docs);
  };

  const fetchKmsDriven = async () => {
    const response = await axios.get(`${Constant.getUrls.getAllKms}`);
    setKmsDriven(response.data._kms.docs);
  };

  const fetchFuelTypes = async () => {
    const response = await axios.get(`${Constant.getUrls.getAllFuelTypes}`);
    setFuelTypes(response.data._getFuel.docs);
  };

  const filterSearchModel = models.filter((model) =>
    model.name.toLowerCase().includes(modelSearchValue.toLowerCase())
  );

  const filterSearchBrand = brands.filter((brand) =>
    brand.title.toLowerCase().includes(brandSearchValue.toLowerCase())
  );

  // category
  const handleCat = (id) => {
    window.scrollTo(0, 0);
    setCat(id);
    location["category"] = id;
    let prevUrl = queryString.stringify(location);
    navigate("?" + prevUrl);
    fetchVehiclesAPI(location);
  };

  // Recently Added
  const handleRecentlyAdded = (bool) => {
    setRecentlyAdded(bool);
    location["sort"] = bool;
    let prevUrl = queryString.stringify(location);
    navigate("?" + prevUrl);
    fetchVehiclesAPI(location);
    // setIsSortOpen((prevState) => !prevState);
  };

  // Kms Driven
  const handleLowToHighKms = (status) => {
    setLTH(status);
    location["kim_driven_sort"] = status;
    let prevUrl = queryString.stringify(location);
    navigate("?" + prevUrl);
    fetchVehiclesAPI(location);
    // setIsSortOpen((prevState) => !prevState);
  };

  // price low to high
  const handleLowToHighPrice = (priceStatus) => {
    setLTHPrice(priceStatus);
    location["price_sort"] = priceStatus;
    let prevUrl = queryString.stringify(location);
    navigate("?" + prevUrl);
    fetchVehiclesAPI(location);
    // setIsSortOpen((prevState) => !prevState);
  };

  //owner checked
  const isOwnerChecked = (ownerStr) => {
    if (location["no_of_owner[]"]) {
      if (typeof location["no_of_owner[]"] === "string") {
        return location["no_of_owner[]"] === ownerStr;
      } else {
        return location["no_of_owner[]"].includes(ownerStr);
      }
    }
  };
  const handleOwnerType = (ownerStr) => {
    const prevUrl = queryString.stringify(location);

    if (!location["no_of_owner[]"]) {
      location["no_of_owner[]"] = ownerStr;
      navigate("?" + prevUrl + "&no_of_owner[]=" + ownerStr);
    } else {
      if (typeof location["no_of_owner[]"] === "string") {
        if (location["no_of_owner[]"] === ownerStr) {
          delete location["no_of_owner[]"];
        } else {
          location["no_of_owner[]"] = [location["no_of_owner[]"], ownerStr];
        }
      } else {
        const isBR = location["no_of_owner[]"].find(
          (owner) => owner === ownerStr
        );
        if (isBR) {
          const idx = location["no_of_owner[]"].indexOf(ownerStr);
          location["no_of_owner[]"].splice(idx, 1);
        } else {
          location["no_of_owner[]"].push(ownerStr);
        }
      }
    }

    const OWstring = queryString.stringify(location);
    navigate("?" + OWstring);

    // fetchVehiclesAPI(location);
  };

  // state checked
  const isStateChecked = (id) => {
    if (location["state[]"]) {
      if (typeof location["state[]"] === "string") {
        return location["state[]"] === id;
      } else {
        return location["state[]"].includes(id);
      }
    }
  };
  const handleStateType = (id, stateTitle) => {
    // window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    if (!location["state[]"]) {
      location["state[]"] = id;
      location["state_title"] = stateTitle;
      navigate("?" + prevUrl + "&state[]=" + id + "&state_title=" + stateTitle);
    } else {
      if (typeof location["state[]"] === "string") {
        if (location["state[]"] === id) {
          delete location["state[]"];
          delete location["state_title"];
        } else {
          location["state[]"] = [location["state[]"], id];
        }
      } else {
        const isFT = location["state[]"].find((st) => st === id);
        if (isFT) {
          const idx = location["state[]"].indexOf(id);
          location["state[]"].splice(idx, 1);
        } else {
          location["state[]"].push(id);
        }
      }
    }

    // manage cities data
    if (selectedStateIds.includes(id)) {
      let index = selectedStateIds.findIndex((_id) => _id === id);
      selectedStateIds.splice(index, 1);
      setselectedStateIds(selectedStateIds);
      fetchCities(selectedStateIds);
      if (selectedStateIds.length === 0) {
        setCitiesArray([]);
        delete location["city[]"];
        delete location["city_title"];
      }
    } else {
      selectedStateIds.push(id);
      setselectedStateIds(selectedStateIds);
      fetchCities(selectedStateIds);
    }

    const STstring = queryString.stringify(location);
    navigate("?" + STstring);
    // fetchVehiclesAPI(location);
  };

  // city checked
  const isCityChecked = (id) => {
    if (location["city[]"]) {
      if (typeof location["city[]"] === "string") {
        return location["city[]"] === id;
      } else {
        return location["city[]"].includes(id);
      }
    }
  };
  const handleCityType = (id, cityTitle) => {
    // window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    console.log(location);
    if (!location["city[]"]) {
      location["city[]"] = id;
      location["city_title"] = cityTitle;
      navigate("?" + prevUrl + "&city[]=" + id + "&city_title=" + cityTitle);
    } else {
      if (typeof location["city[]"] === "string") {
        if (location["city[]"] === id) {
          delete location["city[]"];
          delete location["city_title"];
        } else {
          location["city[]"] = [location["city[]"], id];
        }
      } else {
        const isFT = location["city[]"].find((st) => st === id);
        if (isFT) {
          const idx = location["city[]"].indexOf(id);
          location["city[]"].splice(idx, 1);
        } else {
          location["city[]"].push(id);
        }
      }
    }

    const STstring = queryString.stringify(location);
    navigate("?" + STstring);

    // fetchVehiclesAPI(location);
  };

  const isBtChecked = (id) => {
    if (location["bodyType[]"]) {
      if (typeof location["bodyType[]"] === "string") {
        return location["bodyType[]"] === id;
      } else {
        return location["bodyType[]"].includes(id);
      }
    }
  };
  const handleBodyType = (id, typeTitle) => {
    // window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    if (!location["bodyType[]"]) {
      location["bodyType[]"] = id;
      location["body_type_title"] = typeTitle;
      navigate(
        "?" + prevUrl + "&bodyType[]=" + id + "&body_type_title=" + typeTitle
      );
    } else {
      if (typeof location["bodyType[]"] === "string") {
        if (location["bodyType[]"] === id) {
          delete location["bodyType[]"];
          delete location["body_type_title"];
        } else {
          location["bodyType[]"] = [location["bodyType[]"], id];
        }
      } else {
        const isBT = location["bodyType[]"].find((bt) => bt === id);
        if (isBT) {
          const idx = location["bodyType[]"].indexOf(id);
          location["bodyType[]"].splice(idx, 1);
        } else {
          location["bodyType[]"].push(id);
        }
      }
    }

    const BTstring = queryString.stringify(location);
    navigate("?" + BTstring);

    // fetchVehiclesAPI(location);
  };

  const isBrandChecked = (id) => {
    if (location["brand[]"]) {
      if (typeof location["brand[]"] === "string") {
        return location["brand[]"] === id;
      } else {
        return location["brand[]"].includes(id);
      }
    }
  };
  const handleBrandType = (id, brandTitle) => {
    // window.scrollTo(0, 0);

    const prevUrl = queryString.stringify(location);

    if (!location["brand[]"]) {
      location["brand[]"] = id;
      location["brand_name"] = brandTitle;
      navigate("?" + prevUrl + "&brand[]=" + id + "&brand_name=" + brandTitle);
    } else {
      if (typeof location["brand[]"] === "string") {
        if (location["brand[]"] === id) {
          delete location["brand[]"];
          delete location["brand_name"];
        } else {
          location["brand[]"] = [location["brand[]"], id];
        }
      } else {
        const isBR = location["brand[]"].find((br) => br === id);
        if (isBR) {
          const idx = location["brand[]"].indexOf(id);
          location["brand[]"].splice(idx, 1);
        } else {
          location["brand[]"].push(id);
        }
      }
    }

    const BRstring = queryString.stringify(location);
    navigate("?" + BRstring);

    // fetchVehiclesAPI(location);
  };

  const isModelChecked = (id) => {
    if (location["model[]"]) {
      if (typeof location["model[]"] === "string") {
        return location["model[]"] === id;
      } else {
        return location["model[]"].includes(id);
      }
    }
  };
  const handleModelType = (id, modelName) => {
    const prevUrl = queryString.stringify(location);

    if (!location["model[]"]) {
      location["model[]"] = id;
      location["model_name"] = modelName;
      navigate("?" + prevUrl + "&model[]=" + id + "&model_name=" + modelName);
    } else {
      if (typeof location["model[]"] === "string") {
        if (location["model[]"] === id) {
          delete location["model[]"];
          delete location["model_name"];
        } else {
          location["model[]"] = [location["model[]"], id];
        }
      } else {
        const isMT = location["model[]"].find((mt) => mt === id);
        if (isMT) {
          const idx = location["model[]"].indexOf(id);
          location["model[]"].splice(idx, 1);
        } else {
          location["model[]"].push(id);
        }
      }
    }
    const MTstring = queryString.stringify(location);
    navigate("?" + MTstring);

    // fetchVehiclesAPI(location);
  };

  const isManufacturingChecked = (id) => {
    if (location["years[]"]) {
      if (typeof location["years[]"] === "string") {
        return location["years[]"] === id;
      } else {
        return location["years[]"].includes(id);
      }
    }
  };
  const handleManufacturingType = (id, yearTitle) => {
    window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    if (!location["years[]"]) {
      location["years[]"] = id;
      location["year_title"] = yearTitle;
      navigate("?" + prevUrl + "&years[]=" + id + "&year_title=" + yearTitle);
    } else {
      if (typeof location["years[]"] === "string") {
        if (location["years[]"] === id) {
          delete location["years[]"];
          delete location["year_title"];
        } else {
          location["years[]"] = [location["years[]"], id];
        }
      } else {
        const isMAT = location["years[]"].find((mat) => mat === id);
        if (isMAT) {
          const idx = location["years[]"].indexOf(id);
          location["years[]"].splice(idx, 1);
        } else {
          location["years[]"].push(id);
        }
      }

      const MATstring = queryString.stringify(location);
      navigate("?" + MATstring);

      fetchVehiclesAPI(location);
    }
  };

  const isKmsChecked = (id) => {
    if (location["km_driven[]"]) {
      if (typeof location["km_driven[]"] === "string") {
        return location["km_driven[]"] === id;
      } else {
        return location["km_driven[]"].includes(id);
      }
    }
  };
  const handleKilometersType = (id) => {
    window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    if (!location["km_driven[]"]) {
      location["km_driven[]"] = id;
      navigate("?" + prevUrl + "&km_driven[]=" + id);
    } else {
      if (typeof location["km_driven[]"] === "string") {
        if (location["km_driven[]"] === id) {
          delete location["km_driven[]"];
        } else {
          location["km_driven[]"] = [location["km_driven[]"], id];
        }
      } else {
        const isKMS = location["km_driven[]"].find((kms) => kms === id);
        if (isKMS) {
          const idx = location["km_driven[]"].indexOf(id);
          location["km_driven[]"].splice(idx, 1);
        } else {
          location["km_driven[]"].push(id);
        }
      }

      const KMSstring = queryString.stringify(location);
      navigate("?" + KMSstring);

      fetchVehiclesAPI(location);
    }
  };

  const isFuelChecked = (id) => {
    if (location["fuelType[]"]) {
      if (typeof location["fuelType[]"] === "string") {
        return location["fuelType[]"] === id;
      } else {
        return location["fuelType[]"].includes(id);
      }
    }
  };
  const handleFuelType = (id, fuelTitle) => {
    // window.scrollTo(0, 0);
    const prevUrl = queryString.stringify(location);

    if (!location["fuelType[]"]) {
      location["fuelType[]"] = id;
      location["fuel_title"] = fuelTitle;
      navigate(
        "?" + prevUrl + "&fuelType[]=" + id + "&fuel_title=" + fuelTitle
      );
    } else {
      if (typeof location["fuelType[]"] === "string") {
        if (location["fuelType[]"] === id) {
          delete location["fuelType[]"];
          delete location["fuel_title"];
        } else {
          location["fuelType[]"] = [location["fuelType[]"], id];
        }
      } else {
        const isFT = location["fuelType[]"].find((ft) => ft === id);
        if (isFT) {
          const idx = location["fuelType[]"].indexOf(id);
          location["fuelType[]"].splice(idx, 1);
        } else {
          location["fuelType[]"].push(id);
        }
      }

      const FTstring = queryString.stringify(location);
      navigate("?" + FTstring);

      // fetchVehiclesAPI(location);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const res = await axios.get(
          Constant.getUrls.getAllStates + "?limit=5000"
        );

        if (res.data) {
          setStatesArray(res.data.getAllStates.docs);
        }
      } catch (error) {
        console.log(error);
      }
    };

    // if (sessionStorage.getItem("scrollPosition")) {
    //   const scrolledY = sessionStorage.getItem("scrollPosition") ?? 0;
    //   if (scrolledY) {
    //     window.scrollTo(0, parseInt(scrolledY));
    //     // sessionStorage.removeItem("scrollPosition");
    //   }
    // }

    fetchCategories();
    fetchBodyTypes();
    fetchBrands();
    fetchModels();
    fetchModelYears();
    fetchKmsDriven();
    fetchFuelTypes();
    fetchStates();
    fetchLocationCities();
    fetchVehiclesAPI(location);

    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const fetchCities = async (stateIds) => {
    try {
      if (stateIds.length > 0) {
        let url = Constant.getUrls.getAllCity + "?";
        stateIds.forEach((id) => {
          url += "state[]=" + id + "&limit=5000";
        });
        const res = await axios.get(url);

        if (res.data) {
          setCitiesArray((prevState) => [...res.data.getAllCities.docs]);
        }
      } else {
        setCitiesArray([]);
      }
    } catch (error) {
      console.log(error);
    }

    // const isCity = citiesArray.find((city) => city?._id === stateId);
    // if (!isStateChecked) {
    //   const idx = citiesArray.indexOf(stateId);
    //   citiesArray.splice(idx, 1);
    // }
  };

  // if (loading) {
  //   return (
  //     <div className="loading-effect">
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  const categoryVehicle = categories.find(
    (vehicle) => vehicle._id === location.category
  );

  return (
    <>
      <Navbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy Used Commercial Vehicle | GaddiDeals</title>
        <meta
          name="description"
          content="GaddiDeals is a next-generation platform that has the purpose to ease the process of the used commercial vehicle market by providing buyers and sellers a platform to buy and sell used commercial vehicles"
        />
        <meta name="author" content="Carapace Technologies" />
        <meta
          property="og:title"
          content="GaddiDeals - Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gaddideals.com/" />
        <meta
          property="og:image"
          content="https://gaddideals.com/hero_BG.png"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GaddiDeals" />
        <meta
          name="twitter:description"
          content="Buy &amp; Sell Used Commercial Vehicle"
        />
        <meta name="twitter:url" content="https://gaddideals.com/" />
        <meta
          name="twitter:image"
          content="https://gaddideals.com/hero_BG.png"
        />
      </Helmet>

      <Modal
        visible={BuyerInput}
        width={matches ? "85%" : "35%"}
        effect="fadeInLeft"
        onClickAway={() => {
          setBuyerInput(!BuyerInput);
        }}
      >
        <div className="buyer-dtails-container">
          <img
            src={CloseTab}
            alt=""
            onClick={() => {
              setBuyerInput(!BuyerInput);
            }}
          ></img>
          <h1>Please share your contact</h1>
          <input
            className="buyer-name"
            onChange={(e) => {
              setname(e.target.value);
            }}
            value={name}
            type="text"
            placeholder="Name "
          ></input>
          <input
            className="buyer-number"
            placeholder="Phone Number "
            onChange={(e) => {
              setmob_no(e.target.value);
            }}
            value={mob_no}
            maxLength={10}
          ></input>
          <input
            onChange={(e) => {
              setemail(e.target.value);
            }}
            value={email}
            type="email"
            className="buyer-email"
            placeholder="Email"
          ></input>

          <div className="buyer-location">
            <input
              onChange={(e) => {
                setcity(e.target.value);
              }}
              value={city}
              placeholder="Location"
              onFocus={() => {
                setlocationDropDown(!locationDropDown);
              }}
            ></input>
            <img src={downArrow} alt=""></img>
            {locationDropDown && (
              <div className="sign-up-loaction-drop-down">
                {filterCurentCities.map((data) => (
                  <p
                    onClick={() => {
                      setcity(data.title);
                      setlocationDropDown(false);
                    }}
                    key={data._id}
                  >
                    {data.title}
                  </p>
                ))}
              </div>
            )}
          </div>

          <div className="dealerType">
            <div className="dealer-que">What type of user you are?</div>
            <div className="dealerTypeOption">
              {dealerType.map((user_types, index) => (
                <div
                  key={index}
                  className={
                    isTypeActive === index ? "dealer active" : "dealer"
                  }
                  onClick={() => {
                    setuser_type(user_types.user_type);
                    setIsTypeActive(index);
                  }}
                >
                  <span>{user_types.user_type}</span>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={() => {
              if (user_token) {
                LoggedenquiryApi();
              } else {
                enquiryApi(); //posting data into enquiry api
              }
              setCounter(25);
            }}
          >
            Get Contact Details
          </button>

          {!userToken && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => {
                if (matches) {
                  dispatch(setMobSignInValue(true));
                } else {
                  dispatch(setSignUpValue(true));
                }
                setBuyerInput((prevState) => !prevState);
              }}
            >
              <h6 style={{ margin: 0, color: "#050f56", fontSize: "12px" }}>
                Already a user? Sign In.
              </h6>
            </div>
          )}

          {!userToken && (
            <div className="modal-agree-terms">
              <div>
                <input type="checkbox" checked />
              </div>
              <span>
                "By clicking on get contact details" you agree with our&nbsp;
                <a href="/termsandconditions">terms and condition.</a>
              </span>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        visible={BuyerOtp}
        width={matches ? "85%" : "35%"}
        effect="fadeInLeft"
        onClickAway={() => {
          setBuyerOtp(!BuyerOtp);
        }}
      >
        <div className="buyer-otp-container">
          <img
            src={CloseTab}
            alt=""
            onClick={() => {
              setBuyerOtp(!BuyerOtp);
            }}
          ></img>
          <div className="instruction-text">
            6 digit code sent to mobile number
          </div>
          <div className="buyer-phone-number-input">
            <input
              placeholder="Enter Phone Number"
              onChange={(e) => {
                setmob_no(e.target.value);
              }}
              value={mob_no}
            ></input>
            <img
              src={Edit}
              alt=""
              onClick={() => {
                setBuyerInput(!BuyerInput);
                setBuyerOtp(!BuyerOtp);
              }}
            ></img>
          </div>
          <div className="enter-otp-text">Enter OTP to verify</div>
          <OtpInput
            containerStyle="otpStyle"
            inputStyle="otBoxStyle"
            numInputs={6}
            separator={<span></span>}
            value={otp}
            type="number"
            onChange={(e) => {
              handleChange(e);
            }}
          ></OtpInput>
          <div className="new-otp-text">
            {timerVisible && (
              <p>
                Get new OTP in
                <span className="blue-text"> {counter} sec</span>
              </p>
            )}
            {EnableResendOtp && (
              <p
                onClick={() => {
                  resendotp();
                }}
                className="blue-text"
              >
                Resend
              </p>
            )}
          </div>
          <button
            onClick={() => {
              setOtp("");
              if (userToken) {
                enquiryVerifyOtp(); //verify enquiry otp
              } else {
                enquiryVerifyOtp();
                signUpVeryfyOtp(); //hitting both api
              }
            }}
          >
            Verify
          </button>
        </div>
      </Modal>

      <Modal
        visible={SellerDetails}
        width={matches ? "85%" : "35%"}
        effect="fadeInLeft"
        onClickAway={() => {
          setSellerDetails(!SellerDetails);
        }}
      >
        <div className="sellerDetailsContainer">
          <img
            src={CloseTab}
            alt=""
            onClick={() => {
              setSellerDetails(!SellerDetails);
            }}
          ></img>
          <div className="userProfilePic">
            <img
              src={
                imgurl + userObj?.secondary_name
                  ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  : userObj?.user?.profile_pic_url
              }
              alt="profile"
            ></img>
          </div>
          <div className="userName">
            {userObj?.secondary_name
              ? userObj?.secondary_name
              : userObj?.user?.name}
          </div>
          <hr></hr>
          <input
            value={
              userObj?.secondary_name
                ? userObj?.secondary_name
                : userObj?.user?.name
            }
            placeholder="Name"
          ></input>
          <input
            value={
              userObj?.secondary_mob
                ? userObj?.secondary_mob
                : userObj?.user?.mob_no
            }
            placeholder="Phone Numer"
          ></input>
          <input
            value={
              userObj?.secondary_email
                ? userObj?.secondary_email
                : userObj?.user.email
            }
            placeholder="Email"
          ></input>
          <input value={regNo} placeholder="vehicle number" />
        </div>
      </Modal>

      <div className="filter-sort-button-div">
        <div
          className="filter-button"
          onClick={() => {
            setdisplayFilterTwo(!displayFilterTwo);
          }}
        >
          <button>Filter</button>

          <img src={displayFilterTwo ? upArrow : downArrow} alt=""></img>
        </div>
        <div
          className="sortBy-button"
          onClick={() => {
            setdisplayFilterOne(!displayFilterOne);
          }}
        >
          <button>Sort By</button>
          <img src={displayFilterOne ? upArrow : downArrow} alt=""></img>
        </div>
      </div>

      {/* filter two in mobile */}
      {displayFilterTwo && (
        <div className="filter-two lg-devices">
          <div className="search-container">
            <img src={searchIcon} alt="search icon" />
            <input
              placeholder="search"
              type="search"
              name="search"
              id="search"
              value={searchInput}
              onChange={handleSearchChange}
            />
          </div>
          <div className="categories-container">
            <img
              className="close-div-img"
              src={CloseTab}
              alt=""
              onClick={() => {
                setdisplayFilterTwo(!displayFilterTwo);
              }}
            />
            <h3>Category</h3>
            <div className="four-category-container">
              {categories.map((category) => (
                <div className="category" key={category._id}>
                  <div
                    onClick={() => {
                      handleCat(category._id);
                    }}
                    className="icon-wrapper"
                    style={{
                      backgroundColor:
                        cat === category._id ? "#00adef" : "none",
                    }}
                  >
                    <img src={imgurl + category.icon} alt={category.title} />
                  </div>
                  <a rel="noreferrer" href="#dasd">
                    {category.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="line"></div>

          <div className="price-container">
            <h3>Price</h3>
            <div className="min-max-controls">
              <div className="price-input">
                <PrettoSlider
                  value={minMax}
                  min={0}
                  max={2000000}
                  onChange={handleMinMax}
                />
                <div className="ranges-price">
                  <span>₹{rupee_format(minMax[0])}</span>
                  <span>₹{rupee_format(minMax[1])}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="line"></div>

          <div className="vehicles-stats-container">
            <div className="filter-btn-resp">
              <button
                onClick={() => {
                  if (Object.keys(location).length !== 0) {
                    window.location.reload();
                    fetchVehiclesAPI(location);
                  }
                }}
              >
                Apply Filter
              </button>
            </div>

            {categoryVehicle?.title === "Trucks" && (
              <ToggleCategory categoryTitle="Body Type">
                {bodytypes.map((type) => (
                  <div key={type._id} className="list">
                    <div
                      className="list-content"
                      onClick={() => handleBodyType(type._id, type.title)}
                    >
                      {isBtChecked(type._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          color="#050F56"
                          size={25}
                        />
                      )}

                      <p>{type.title}</p>
                    </div>
                  </div>
                ))}
              </ToggleCategory>
            )}

            <ToggleCategory categoryTitle="Brand">
              <div className="state-filter-search-div">
                <input
                  placeholder="search brand"
                  value={brandSearchValue}
                  onChange={(e) => {
                    setBrandSearchValue(e.target.value);
                  }}
                />
              </div>
              <div className="list scroll">
                {filterSearchBrand.map((brand) => (
                  <div
                    key={brand._id}
                    className="list-content"
                    onClick={() => handleBrandType(brand._id, brand.title)}
                  >
                    {isBrandChecked(brand._id) ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>{brand.title}</p>
                  </div>
                ))}
              </div>
            </ToggleCategory>

            <ToggleCategory categoryTitle="Model">
              <div className="state-filter-search-div">
                <input
                  placeholder="search model"
                  value={modelSearchValue}
                  onChange={(e) => {
                    setModelSearchValue(e.target.value);
                  }}
                />
              </div>
              <div className="list scroll">
                {filterSearchModel.map((model) => (
                  <div
                    key={model._id}
                    className="list-content"
                    onClick={() => handleModelType(model._id, model.name)}
                  >
                    {isModelChecked(model._id) ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>{model.name}</p>
                  </div>
                ))}
              </div>
            </ToggleCategory>

            <ToggleCategory categoryTitle="Manufacturing Year">
              <PrettoSlider
                value={yearsValue}
                min={1991}
                max={2022}
                onChange={handleYears}
              />
              <div className="ranges-year">
                <span>{yearsValue[0]}</span>
                <span>{yearsValue[1]}</span>
              </div>
            </ToggleCategory>

            {categoryVehicle?.title === "Trucks" && (
              <ToggleCategory categoryTitle="Kilometers Driven">
                <PrettoSlider
                  value={kmsValue}
                  min={0}
                  max={100000}
                  onChange={handleKMS}
                />
                <div className="ranges-kms">
                  <span>{kmsValue[0]}</span>
                  <span>{kmsValue[1]}</span>
                </div>
              </ToggleCategory>
            )}

            {categoryVehicle?.title === "Buses" && (
              <ToggleCategory categoryTitle="Number of seats">
                <PrettoSlider
                  value={noOfSeatsValue}
                  min={0}
                  max={100}
                  onChange={handleNoOfSeats}
                />
                <div className="ranges-kms">
                  <span>{noOfSeatsValue[0]}</span>
                  <span>{noOfSeatsValue[1]}</span>
                </div>
              </ToggleCategory>
            )}

            {categoryVehicle?.title === "Tractors" && (
              <ToggleCategory categoryTitle="Number of hours">
                <PrettoSlider
                  value={noOfHrsValue}
                  min={0}
                  max={100000}
                  onChange={handleNoOfHrs}
                />
                <div className="ranges-kms">
                  <span>{noOfHrsValue[0]}</span>
                  <span>{noOfHrsValue[1]}</span>
                </div>
              </ToggleCategory>
            )}

            {categoryVehicle?.title === "Construction Equipments" && (
              <ToggleCategory categoryTitle="Number of hours">
                <PrettoSlider
                  value={noOfHrsValue}
                  min={0}
                  max={100000}
                  onChange={handleNoOfHrs}
                />
                <div className="ranges-kms">
                  <span>{noOfHrsValue[0]}</span>
                  <span>{noOfHrsValue[1]}</span>
                </div>
              </ToggleCategory>
            )}

            {categoryVehicle?.title === "Tractors" && (
              <ToggleCategory categoryTitle="Horse Power">
                <PrettoSlider
                  value={horsePowerValue}
                  min={0}
                  max={2000}
                  onChange={handleHorsePower}
                />
                <div className="ranges-kms">
                  <span>{horsePowerValue[0]}</span>
                  <span>{horsePowerValue[1]}</span>
                </div>
              </ToggleCategory>
            )}

            {/* <div className="vehicle-stat">
              <div className="stat-label">
                <a href="#brand">Number of Owners</a>
                <img src={downArrow} alt="down arrow icon" />
              </div>
            </div> */}

            <ToggleCategory categoryTitle="Fuel Type">
              {fuelTypes.map((fuelType) => (
                <div key={fuelType._id} className="list">
                  <div
                    className="list-content"
                    onClick={() => handleFuelType(fuelType._id, fuelType.title)}
                  >
                    {isFuelChecked(fuelType._id) ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>{fuelType.title}</p>
                  </div>
                </div>
              ))}
            </ToggleCategory>
            <ToggleCategory categoryTitle="State">
              <div className="state-filter-search-div">
                <input
                  placeholder="search state"
                  value={filterState}
                  onChange={(e) => {
                    setfilterState(e.target.value);
                  }}
                />
              </div>
              <div className="states">
                {filterSelectedStates.map((state) => (
                  <div className="list" key={state?._id}>
                    <div
                      className="list-content"
                      onClick={() => {
                        handleStateType(state?._id, state?.title);
                      }}
                    >
                      {isStateChecked(state?._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          color="#050F56"
                          size={25}
                        />
                      )}
                      <p>{state?.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </ToggleCategory>

            {citiesArray.length > 0 && (
              <ToggleCategory categoryTitle="City">
                <div className="city">
                  {citiesArray.length > 0 ? (
                    <>
                      {citiesArray.map((city) => (
                        <div className="list" key={city?._id}>
                          <div
                            className="list-content"
                            onClick={() => {
                              handleCityType(city?._id, city?.title);
                            }}
                          >
                            {isCityChecked(city?._id) ? (
                              <MdOutlineCheckBox color="#050F56" size={25} />
                            ) : (
                              <MdOutlineCheckBoxOutlineBlank
                                color="#050F56"
                                size={25}
                              />
                            )}
                            <p className="notranslate">
                              <AWS_Translater _text={city?.title} />
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    "No Cities Available"
                  )}
                </div>
              </ToggleCategory>
            )}

            <ToggleCategory categoryTitle="Number of owners">
              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("first")}
                >
                  {isOwnerChecked("first") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>First</p>
                </div>
              </div>

              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("second")}
                >
                  {isOwnerChecked("second") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>Second</p>
                </div>
              </div>

              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("third")}
                >
                  {isOwnerChecked("third") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>Third</p>
                </div>
              </div>

              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("fourth")}
                >
                  {isOwnerChecked("fourth") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>Fourth</p>
                </div>
              </div>

              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("fifth")}
                >
                  {isOwnerChecked("fifth") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>Fifth</p>
                </div>
              </div>

              <div className="list">
                <div
                  className="list-content"
                  onClick={() => handleOwnerType("other")}
                >
                  {isOwnerChecked("other") ? (
                    <MdOutlineCheckBox color="#050F56" size={25} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#050F56" size={25} />
                  )}
                  <p>Other</p>
                </div>
              </div>
            </ToggleCategory>
          </div>
          <div className="line"></div>
          <div className="clear-container">
            <button onClick={clearFilterParams} className="clear-btn">
              Clear Filter
            </button>
          </div>
        </div>
      )}
      {displayFilterOne && (
        //  {/* Filter One for mobile */}
        <div className="filter-one lg-devices">
          <div className="filter-container">
            <h3 className="sort-by">Sort By :</h3>
            <div
              className="filter-controls"
              onClick={() => {
                handleRecentlyAdded(!recentlyAdded);
                // setdisplayFilterOne(!displayFilterOne);
              }}
            >
              {recentlyAdded ? (
                <ImRadioChecked color="#050F56" size={15} />
              ) : (
                <ImRadioUnchecked color="#050F56" size={15} />
              )}
              <span>Recently Added</span>
            </div>
          </div>
          <div className="line"></div>
          <div className="filter-container">
            <h3>Price</h3>
            <div
              className="filter-controls"
              onClick={() => {
                handleLowToHighPrice("low");
                // setdisplayFilterOne(!displayFilterOne);
              }}
            >
              {lthPrice === "low" ? (
                <ImRadioChecked color="#050F56" size={15} />
              ) : (
                <ImRadioUnchecked color="#050F56" size={15} />
              )}
              <span>Low to High</span>
            </div>
            <div
              className="filter-controls"
              onClick={() => {
                handleLowToHighPrice("high");
                // setdisplayFilterOne(!displayFilterOne);
              }}
            >
              {lthPrice === "high" ? (
                <ImRadioChecked color="#050F56" size={15} />
              ) : (
                <ImRadioUnchecked color="#050F56" size={15} />
              )}
              <span>High to Low</span>
            </div>
          </div>
          <div className="line"></div>
          <div className="filter-container kmsdriven">
            <h3>Kms Driven</h3>
            <div
              className="filter-controls"
              onClick={() => {
                handleLowToHighKms("low");
                // setdisplayFilterOne(!displayFilterOne);
              }}
            >
              {lth === "low" ? (
                <ImRadioChecked color="#050F56" size={15} />
              ) : (
                <ImRadioUnchecked color="#050F56" size={15} />
              )}
              <span>Low to High</span>
            </div>
            <div
              className="filter-controls"
              onClick={() => {
                handleLowToHighKms("high");
                // setdisplayFilterOne(!displayFilterOne);
              }}
            >
              {lth === "high" ? (
                <ImRadioChecked color="#050F56" size={15} />
              ) : (
                <ImRadioUnchecked color="#050F56" size={15} />
              )}
              <span>High to Low</span>
            </div>
          </div>
        </div>
      )}
      <div className="pages-navigation gd_container">
        <div>
          <Link to="/">
            <small>Home</small>
          </Link>
          &nbsp; &#62; &nbsp;
          <small>Vehicle listing</small>
        </div>
        <div
          className="sort-btn"
          onClick={() => setIsSortOpen((prevState) => !prevState)}
        >
          <span>Sort By</span>
          <img src={isSortOpen ? upArrow : downArrow} alt="down arrow icon" />
        </div>
        {isSortOpen && (
          <div className="filter-sort">
            <div className="filter-sort-container">
              <h3>Sort By :</h3>
              <div
                className="filter-sort-controls"
                onClick={() => handleRecentlyAdded(!recentlyAdded)}
              >
                {recentlyAdded ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Recently Added</span>
              </div>
            </div>
            <div className="line"></div>
            <div className="filter-sort-container">
              <h3>Price</h3>
              <div
                className="filter-sort-controls"
                onClick={() => {
                  handleLowToHighPrice("low");
                }}
              >
                {lthPrice === "low" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Low to High</span>
              </div>
              <div
                className="filter-sort-controls"
                onClick={() => {
                  handleLowToHighPrice("high");
                }}
              >
                {lthPrice === "high" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>High to Low</span>
              </div>
            </div>
            <div className="line"></div>
            <div className="filter-sort-container kmsdriven">
              <h3>Kms Driven</h3>
              <div
                className="filter-sort-controls"
                onClick={() => {
                  handleLowToHighKms("low");
                }}
              >
                {lth === "low" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Low to High</span>
              </div>
              <div
                className="filter-sort-controls"
                onClick={() => {
                  handleLowToHighKms("high");
                }}
              >
                {lth === "high" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>High to Low</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <section className="vehicles-container gd_container">
        <aside className="filter-sidebar">
          <div className="apply-filter-btn-web">
            <button
              onClick={() => {
                if (Object.keys(location).length !== 0) {
                  window.location.reload();
                  fetchVehiclesAPI(location);
                }
              }}
            >
              Apply Filter
            </button>
          </div>

          {/* Filter One */}
          {/* <div className="filter-one">
            <div className="filter-container">
              <h3>Sort By :</h3>
              <div
                className="filter-controls"
                onClick={() => handleRecentlyAdded(!recentlyAdded)}
              >
                {recentlyAdded ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Recently Added</span>
              </div>
            </div>
            <div className="line"></div>
            <div className="filter-container">
              <h3>Price</h3>
              <div
                className="filter-controls"
                onClick={() => {
                  handleLowToHighPrice("low");
                }}
              >
                {lthPrice === "low" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Low to High</span>
              </div>
              <div
                className="filter-controls"
                onClick={() => {
                  handleLowToHighPrice("high");
                }}
              >
                {lthPrice === "high" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>High to Low</span>
              </div>
            </div>
            <div className="line"></div>
            <div className="filter-container kmsdriven">
              <h3>Kms Driven</h3>
              <div
                className="filter-controls"
                onClick={() => {
                  handleLowToHighKms("low");
                }}
              >
                {lth === "low" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>Low to High</span>
              </div>
              <div
                className="filter-controls"
                onClick={() => {
                  handleLowToHighKms("high");
                }}
              >
                {lth === "high" ? (
                  <ImRadioChecked color="#050F56" size={15} />
                ) : (
                  <ImRadioUnchecked color="#050F56" size={15} />
                )}
                <span>High to Low</span>
              </div>
            </div>
          </div> */}

          {/* Filter Two Desktop */}
          <div className="filter-two">
            {/* <div className="search-container">
              <img src={searchIcon} alt="search icon" />
              <input
                placeholder="search"
                type="search"
                name="search"
                id="search"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </div> */}
            <div className="categories-container">
              <h3>Category</h3>

              {categories.map((category) => (
                <a
                  href={
                    !location
                      ? "/BuyUsedCommercialVehicle?category=" +
                        category._id +
                        "&title=" +
                        category.title
                      : ""
                  }
                  key={category._id}
                >
                  <div className="category" key={category._id}>
                    <div
                      onClick={() => {
                        // handleCat(category._id);
                        if (location["category"] === category._id) {
                          location = {};
                          navigate("/BuyUsedCommercialVehicle");
                          handleCat();

                          localStorage.removeItem("vehicles");
                        } else {
                          handleCat(category._id);
                        }
                      }}
                      className="icon-wrapper"
                      style={{
                        backgroundColor:
                          cat === category._id ? "#00adef" : "none",
                      }}
                    >
                      <img src={imgurl + category.icon} alt={category.title} />
                    </div>
                    <p rel="noreferrer" href="#dasd">
                      {category.title}
                    </p>
                  </div>
                </a>
              ))}
            </div>
            <div className="line"></div>

            <div className="price-container">
              <h3>Price</h3>
              <div className="min-max-controls">
                <div className="price-input">
                  {/* <input
                    type="number"
                    placeholder="₹0"
                    name="minprice"
                    value={minPrice}
                    onChange={handleMin}Seller
                  /> */}
                  <PrettoSlider
                    value={minMax}
                    min={0}
                    max={2000000}
                    onChange={handleMinMax}
                  />
                  <div className="ranges-price">
                    <span>₹{rupee_format(minMax[0])}</span>
                    <span>₹{rupee_format(minMax[1])}</span>
                  </div>
                </div>
                {/* <div className="price-input">
                  <span>Max Price</span>
                  <span>{maxPrice}</span>
                  <PrettoSlider
                    onChange={handleMax}
                    value={maxPrice}
                    min={0}
                    max={15000000}
                  />
                  <input
                    type="number"
                    placeholder="₹15,00,000"
                    name="maxprice"
                    value={maxPrice}
                    onChange={handleMax}
                  />
                </div> */}
              </div>
            </div>
            <div className="line"></div>

            <div className="vehicles-stats-container">
              {categoryVehicle?.title === "Trucks" && (
                <ToggleCategory categoryTitle="Body Type">
                  {bodytypes.map((type) => (
                    <div key={type._id} className="list">
                      <div
                        className="list-content"
                        onClick={() => handleBodyType(type._id, type.title)}
                      >
                        {isBtChecked(type._id) ? (
                          <MdOutlineCheckBox color="#050F56" size={25} />
                        ) : (
                          <MdOutlineCheckBoxOutlineBlank
                            color="#050F56"
                            size={25}
                          />
                        )}

                        <p>{type.title}</p>
                      </div>
                    </div>
                  ))}
                </ToggleCategory>
              )}

              <ToggleCategory categoryTitle="Brand">
                <div className="state-filter-search-div">
                  <input
                    placeholder="search brand"
                    value={brandSearchValue}
                    onChange={(e) => {
                      setBrandSearchValue(e.target.value);
                    }}
                  />
                </div>

                <div className="list scroll">
                  {filterSearchBrand.map((brand) => (
                    <div
                      key={brand._id}
                      className="list-content"
                      onClick={() => handleBrandType(brand._id, brand.title)}
                    >
                      {isBrandChecked(brand._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          color="#050F56"
                          size={25}
                        />
                      )}
                      <p>
                        <AWS_Translater _text={brand?.title} />
                      </p>
                    </div>
                  ))}
                </div>
              </ToggleCategory>

              <ToggleCategory categoryTitle="Model">
                <div className="state-filter-search-div">
                  <input
                    placeholder="search model"
                    value={modelSearchValue}
                    onChange={(e) => {
                      setModelSearchValue(e.target.value);
                    }}
                  />
                </div>
                <div className="list scroll">
                  {filterSearchModel.map((model) => (
                    <div
                      key={model._id}
                      className="list-content"
                      onClick={() => handleModelType(model._id, model.name)}
                    >
                      {isModelChecked(model._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          clear
                          color="#050F56"
                          size={25}
                        />
                      )}
                      <p className="notranslate">
                        <AWS_Translater _text={model?.name} />
                      </p>
                    </div>
                  ))}
                </div>
              </ToggleCategory>

              <ToggleCategory categoryTitle="Manufacturing Year">
                {/* {modelYears.map((modelYear) => (
                  <div key={modelYear._id} className="list">
                    <div
                      className="list-content"
                      onClick={() =>
                        handleManufacturingType(modelYear._id, modelYear.year)
                      }
                    >
                      {isManufacturingChecked(modelYear._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          color="#050F56"
                          size={25}
                        />
                      )}
                      <p>{modelYear.year}</p>
                    </div>
                  </div>
                ))} */}
                <PrettoSlider
                  value={yearsValue}
                  min={1991}
                  max={2022}
                  onChange={handleYears}
                />
                <div className="ranges-year">
                  <span>{yearsValue[0]}</span>
                  <span>{yearsValue[1]}</span>
                </div>
              </ToggleCategory>

              {categoryVehicle?.title === "Trucks" && (
                <ToggleCategory categoryTitle="Kilometers Driven">
                  <PrettoSlider
                    value={kmsValue}
                    min={0}
                    max={100000}
                    onChange={handleKMS}
                  />
                  <div className="ranges-kms">
                    <span>{kmsValue[0]}</span>
                    <span>{kmsValue[1]}</span>
                  </div>
                </ToggleCategory>
              )}

              {categoryVehicle?.title === "Buses" && (
                <ToggleCategory categoryTitle="Number of seats">
                  <PrettoSlider
                    value={noOfSeatsValue}
                    min={0}
                    max={100}
                    onChange={handleNoOfSeats}
                  />
                  <div className="ranges-kms">
                    <span>{noOfSeatsValue[0]}</span>
                    <span>{noOfSeatsValue[1]}</span>
                  </div>
                </ToggleCategory>
              )}

              {categoryVehicle?.title === "Tractors" && (
                <ToggleCategory categoryTitle="Number of hours">
                  <PrettoSlider
                    value={noOfHrsValue}
                    min={0}
                    max={100000}
                    onChange={handleNoOfHrs}
                  />
                  <div className="ranges-kms">
                    <span>{noOfHrsValue[0]}</span>
                    <span>{noOfHrsValue[1]}</span>
                  </div>
                </ToggleCategory>
              )}

              {categoryVehicle?.title === "Construction Equipments" && (
                <ToggleCategory categoryTitle="Number of hours">
                  <PrettoSlider
                    value={noOfHrsValue}
                    min={0}
                    max={100000}
                    onChange={handleNoOfHrs}
                  />
                  <div className="ranges-kms">
                    <span>{noOfHrsValue[0]}</span>
                    <span>{noOfHrsValue[1]}</span>
                  </div>
                </ToggleCategory>
              )}

              {categoryVehicle?.title === "Tractors" && (
                <ToggleCategory categoryTitle="Horse Power">
                  <PrettoSlider
                    value={horsePowerValue}
                    min={0}
                    max={2000}
                    onChange={handleHorsePower}
                  />
                  <div className="ranges-kms">
                    <span>{horsePowerValue[0]}</span>
                    <span>{horsePowerValue[1]}</span>
                  </div>
                </ToggleCategory>
              )}

              {/* <div className="vehicle-stat">
                <div className="stat-label">
                  <a href="#brand">Number of Owners</a>
                  <img src={downArrow} alt="down arrow icon" />
                </div>
              </div> */}

              <ToggleCategory categoryTitle="Fuel Type">
                {fuelTypes.map((fuelType) => (
                  <div key={fuelType._id} className="list">
                    <div
                      className="list-content"
                      onClick={() =>
                        handleFuelType(fuelType._id, fuelType.title)
                      }
                    >
                      {isFuelChecked(fuelType._id) ? (
                        <MdOutlineCheckBox color="#050F56" size={25} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          color="#050F56"
                          size={25}
                        />
                      )}
                      <p>{fuelType.title}</p>
                    </div>
                  </div>
                ))}
              </ToggleCategory>

              <ToggleCategory categoryTitle="State">
                <div className="state-filter-search-div">
                  <input
                    placeholder="search state"
                    value={filterState}
                    onChange={(e) => {
                      setfilterState(e.target.value);
                    }}
                  />
                </div>

                <div className="states">
                  <div className="list scroll">
                    {filterSelectedStates.map((state) => (
                      <div
                        className="list-content"
                        key={state?._id}
                        onClick={() => {
                          handleStateType(state?._id, state?.title);
                        }}
                      >
                        {isStateChecked(state?._id) ? (
                          <MdOutlineCheckBox color="#050F56" size={25} />
                        ) : (
                          <MdOutlineCheckBoxOutlineBlank
                            color="#050F56"
                            size={25}
                          />
                        )}
                        <p>{state?.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </ToggleCategory>

              {citiesArray.length > 0 && (
                <ToggleCategory categoryTitle="City">
                  <div className="state-filter-search-div">
                    <input
                      placeholder="search city"
                      value={citySearchValue}
                      onChange={(e) => {
                        setCitySearchValue(e.target.value);
                      }}
                    />
                  </div>
                  <div className="city">
                    {filterSearchCity.length > 0 ? (
                      <>
                        <div className="list scroll" key={city?._id}>
                          {filterSearchCity.map((city) => (
                            <div
                              className="list-content"
                              onClick={() => {
                                handleCityType(city?._id, city?.title);
                              }}
                            >
                              {isCityChecked(city?._id) ? (
                                <MdOutlineCheckBox color="#050F56" size={25} />
                              ) : (
                                <MdOutlineCheckBoxOutlineBlank
                                  color="#050F56"
                                  size={25}
                                />
                              )}
                              <p className="notranslate">
                                <AWS_Translater _text={city?.title} />
                              </p>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      "No Cities Available"
                    )}
                  </div>
                </ToggleCategory>
              )}

              <ToggleCategory categoryTitle="Number of owners">
                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("first")}
                  >
                    {isOwnerChecked("first") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>First</p>
                  </div>
                </div>

                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("second")}
                  >
                    {isOwnerChecked("second") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>Second</p>
                  </div>
                </div>

                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("third")}
                  >
                    {isOwnerChecked("third") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>Third</p>
                  </div>
                </div>

                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("fourth")}
                  >
                    {isOwnerChecked("fourth") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>Fourth</p>
                  </div>
                </div>

                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("fifth")}
                  >
                    {isOwnerChecked("fifth") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>Fifth</p>
                  </div>
                </div>

                <div className="list">
                  <div
                    className="list-content"
                    onClick={() => handleOwnerType("other")}
                  >
                    {isOwnerChecked("other") ? (
                      <MdOutlineCheckBox color="#050F56" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#050F56"
                        size={25}
                      />
                    )}
                    <p>Other</p>
                  </div>
                </div>
              </ToggleCategory>
            </div>
            <div className="line"></div>
            <div className="clear-container">
              <button onClick={clearFilterParams} className="clear-btn">
                Clear Filter
              </button>
            </div>
          </div>
        </aside>

        {/* <InfiniteScroll
          dataLength={vehiclesArray.length}
          loader={
            <div
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={20} />
            </div>
          }
          next={fetchData}
          hasMore={hasMore}
        > */}

        {!loading && (
          <>
            {vehiclesArray?.length <= 0 ? (
              <div className="gaddi-loader">
                <img src={gaddiLoader} alt="loader" />
              </div>
            ) : (
              <div className="vehicles-list-container container m-0 p-0">
                {vehiclesArray?.map((vehicle, index) => (
                  <div
                    className="vehicle-card "
                    key={index}
                    onClick={() => {
                      // sessionStorage.setItem(
                      //   "scrollPosition",
                      //   window.scrollY
                      // );
                    }}
                  >
                    <div className="uid">
                      <span>UID-{vehicle?.uid}</span>
                    </div>
                    <Link to={`/vehicledetails/${vehicle._id}`}>
                      <div className="img-wrapper">
                        <img
                          src={
                            vehicle.front_side_pic
                              ? `${imgurl}${vehicle.front_side_pic}`
                              : ` ${imgPlaceholder}`
                          }
                          alt={vehicle?.category?.title}
                        />
                      </div>
                    </Link>
                    <div className="vehicle-info">
                      <div className="name">
                        <h3 className="notranslate">
                          <AWS_Translater
                            _text={
                              vehicle.brand.title + " " + vehicle?.model?.name
                            }
                          />
                        </h3>
                        <div className="location">
                          <img src={locationIcon} alt="location-icon" />
                          {/* <span>{vehicle?.city?.title}</span> */}
                          <span className="notranslate">
                            <AWS_Translater _text={vehicle?.city?.title} />
                          </span>
                        </div>
                      </div>
                      <div className="truck-stats">
                        <div className="stat">
                          <span>
                            {vehicle.km_driven
                              ? vehicle.km_driven + " km"
                              : "N/A"}
                          </span>
                        </div>
                        <div className="stat">
                          <span>
                            {vehicle?.years?.year
                              ? vehicle?.years?.year
                              : "N/A"}
                          </span>
                        </div>
                        {vehicle.category.title === "Trucks" && (
                          <div className="stat">
                            <span>
                              {vehicle.vehicle_permit
                                ? vehicle.vehicle_permit + " feet"
                                : "N/A"}
                            </span>
                          </div>
                        )}
                        {vehicle.category.title === "Tractors" && (
                          <div className="stat">
                            <span>
                              {vehicle.horse_power
                                ? vehicle.horse_power
                                : "N/A"}
                              Hp
                            </span>
                          </div>
                        )}
                        {vehicle.category.title === "Buses" && (
                          <div className="stat">
                            <span>
                              {vehicle.no_of_seats
                                ? vehicle.no_of_seats + " seat"
                                : "N/A"}
                            </span>
                          </div>
                        )}
                        {vehicle.category.title ===
                          "Construction Equipments" && (
                          <div className="stat">
                            <span>
                              {vehicle.no_of_tyre ? vehicle.no_of_tyre : "N/A"}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="selling-price">
                        <p>
                          Selling Price
                          <span>₹{rupee_format(vehicle.selling_price)}</span>
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          setseller_id(vehicle._id);
                          setUserObj(vehicle);
                          setRegNo(vehicle?.reg_no);
                          if (userToken) {
                            getSingleUserInfo();
                          } else {
                            setBuyerInput(!BuyerInput);
                          }
                        }}
                      >
                        Get Seller Details
                      </button>
                    </div>
                  </div>
                ))}

                {!loading ? (
                  <>
                    {hasMore && (
                      <div className="load-more-container">
                        <button onClick={fetchItems} className="load-more-btn">
                          {moreLoader ? "Loading..." : "Load More Vehicles"}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        )}

        {/* </InfiniteScroll> */}

        {displayFilterOne && (
          <div
            className="mob-menue-overlay"
            onClick={() => {
              setdisplayFilterOne(false);
            }}
          ></div>
        )}
        {displayFilterTwo && (
          <div
            className="mob-menue-overlay"
            onClick={() => {
              setdisplayFilterTwo(false);
            }}
          ></div>
        )}
      </section>
      {/*Truck Image  */}
      <div className="truck-section gd_container">
        <div className="image-wrapper">
          <img src={truckHomeImage} alt="truck" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VehicleListings;
