import React from "react";
import locationIcon from "../assets/location.png";
import shipping from "../assets/shipping.png";
import "./LoggedUser.css";
import "./UserVehicles.style.css";
import "./vehiclecard.styles.css";
import logout from "../assets/logout.png";
import myVehicleEnqIco from "../assets/myvehicle_enq.png";
import next_arrow from "../assets/next_arrow.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import clipboard from "../assets/clipboard.png";
import downArrow from "../assets/down-arrow.png";
import lockPasswordIcon from "../assets/lock-password.png";
import animationData from "../assets/my-vehicles-lottie.json";
import searchIcon from "../assets/search-icon.png";
import soldtabIcon from "../assets/soldicon.png";
import upArrow from "../assets/up-arrow.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Constant, { imgurl } from "../constants";
import init from "../Helpers/WindowToken";
import AWS_Translater from "../Helpers/AWS_Translater";
const queryString = require("query-string");

function UserVehicles() {
  let location = queryString.parse(window.location.search);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [name, setname] = useState("");
  // for get name
  const getDetails = () => {
    if (init() === "success") {
      axios.get("https://core.gaddideals.com/api/user").then((res) => {
        setname(res.data.user);
      });
    }
  };
  function logoutAccount() {
    localStorage.clear();
    window.location.href = "/";
  }
  const [dataAvailable, setdataAvailable] = useState(false);
  // const [array,setarray]=useState("");
  const [vehicleData, setvehicleData] = useState([]);
  const [vehicleName, setvehicleName] = useState("");
  const [Seller, setSeller] = useState("");
  const [userToken, setUserToken] = useState(localStorage.getItem("Token"));
  const [vehicleImg, setvehicleImg] = useState("");
  const [vehicleNoValue, setVehicleNoValue] = useState({ regno: "" });
  const [brandDropdown, setBrandDropdown] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [modelDropdown, setModelDropdown] = useState(false);
  const [modelsData, setModelsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageNo, setPageNo] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const handleOnChange = async (e) => {
    setVehicleNoValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    getMyVehicleDetails();
  };

  //for vehicle data
  const getMyVehicleDetails = async (location) => {
    // console.log("otsideeeeeeeeeeeee");

    const apiUrl = queryString.stringify(location);

    await axios
      .get(
        `${
          Constant.getUrls.getAllMyEnquiries
        }?sort=true&status=active&limit=10&q=${vehicleNoValue.regno.toLowerCase()}&${apiUrl}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // console.log("insideeeeeeeeeeeeeeeeeeee");
        // console.log("enquiries response=======" + res);
        console.log(res?.data);
        setvehicleData(res?.data?.getMyEnquiries?.docs);
        res?.data?.getMyEnquiries?.docs.forEach((item, index) => {
          setvehicleName(item?.vehicle_id?.brand);
          setvehicleImg(item?.vehicle_id?.front_side_pic);
          setdataAvailable(true);
          // console.log(item?.vehicle_id?.model);
          setSeller(item?.seller_id);
        });
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getDetails();
    getMyVehicleDetails();

    const fetchBrands = async () => {
      const response = await axios.get(
        Constant.getUrls.getAllBrands + "?limit=5000"
      );
      setBrandsData(response.data.brand.docs);
    };

    const fetchModels = async () => {
      const response = await axios.get(
        Constant.getUrls.getAllModels + "?limit=5000"
      );
      setModelsData(response.data.model.docs);
    };

    fetchBrands();
    fetchModels();
  }, []);

  const fetchItems = async () => {
    const apiUrl = queryString.stringify(location);

    const response = await axios.get(
      `${Constant.getUrls.getAllMyEnquiries}?page=${pageNo}&limit=10&${apiUrl}`
    );

    return response?.data?.getMyEnquiries?.docs;
  };

  const fetchData = async () => {
    const vehicles = await fetchItems();

    setvehicleData([...vehicleData, ...vehicles]);

    if (vehicles.length === 0 || vehicles.length < 10) {
      setHasMore((prevState) => !prevState);
    }

    setPageNo(pageNo + 1);
  };

  console.log(vehicleData);
  const rupee_format = (str) => {
    if (str) {
      var x = str;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else {
      return;
    }
  };

  const isBrandChecked = (id) => {
    if (location["brand_id[]"]) {
      if (typeof location["brand_id[]"] === "string") {
        return location["brand_id[]"] === id;
      } else {
        return location["brand_id[]"].includes(id);
      }
    }
  };
  const handleBrandType = (id) => {
    const prevUrl = queryString.stringify(location);

    if (!location["brand_id[]"]) {
      location["brand_id[]"] = id;
      navigate("?" + prevUrl + "&brand_id[]=" + id);
    } else {
      if (typeof location["brand_id[]"] === "string") {
        if (location["brand_id[]"] === id) {
          delete location["brand_id[]"];
        } else {
          location["brand_id[]"] = [location["brand_id[]"], id];
        }
      } else {
        const isBR = location["brand_id[]"].find((br) => br === id);
        if (isBR) {
          const idx = location["brand_id[]"].indexOf(id);
          location["brand_id[]"].splice(idx, 1);
        } else {
          location["brand_id[]"].push(id);
        }
      }
    }

    const BRstring = queryString.stringify(location);
    navigate("?" + BRstring);

    getMyVehicleDetails(location);
  };

  const isModelChecked = (id) => {
    if (location["model_id[]"]) {
      if (typeof location["model_id[]"] === "string") {
        return location["model_id[]"] === id;
      } else {
        return location["model_id[]"].includes(id);
      }
    }
  };
  const handleModelType = (id) => {
    const prevUrl = queryString.stringify(location);

    if (!location["model_id[]"]) {
      location["model_id[]"] = id;
      navigate("?" + prevUrl + "&model_id[]=" + id);
    } else {
      if (typeof location["model_id[]"] === "string") {
        if (location["model_id[]"] === id) {
          delete location["model_id[]"];
        } else {
          location["model_id[]"] = [location["model_id[]"], id];
        }
      } else {
        const isMT = location["model_id[]"].find((mt) => mt === id);
        if (isMT) {
          const idx = location["model_id[]"].indexOf(id);
          location["model_id[]"].splice(idx, 1);
        } else {
          location["model_id[]"].push(id);
        }
      }
    }
    const MTstring = queryString.stringify(location);
    navigate("?" + MTstring);

    getMyVehicleDetails(location);
  };

  if (loading) {
    return (
      <div className="loading-effect">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="outside-container">
        <div className="profile-container gd_container">
          <div className="left-profile-container">
            <div className="upper-div">
              <p className="hello-text">Hello</p>
              <Link to="/LoggedUser">
                <p className="user-name-left-div">{name.name}</p>
              </Link>
            </div>
            <div className="options-div">
              <div className="my-vehicle-div">
                <img className="shipping-img" src={shipping} alt=""></img>
                <Link to="/UserVehicles" className="my-vehicle-text">
                  <span>My Vehicles</span>
                </Link>
                <Link to="/UserVehicles">
                  <img className="next-arrow-img" src={next_arrow} alt="" />
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={soldtabIcon} alt=""></img>
                <Link to="/mysoldvehicles" className="my-order-text">
                  <span>My Sold Vehicles</span>
                </Link>
                <Link to="/mysoldvehicles">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={clipboard} alt=""></img>
                <Link to="" className="my-order-text">
                  <span>My Buying Enquiries</span>
                </Link>
                <Link to="/Userorder">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={myVehicleEnqIco}
                  alt=""
                ></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span>My Listing Enquiries</span>
                </Link>
                <Link to="/myvehicleenq">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img
                  className="clipboard-img"
                  src={lockPasswordIcon}
                  alt=""
                ></img>
                <Link to="/changepassword" className="my-order-text">
                  <span>Change Password</span>
                </Link>
                <Link to="/changepassword">
                  <img className="next-arrow-img" src={next_arrow} alt=""></img>
                </Link>
              </div>
              <div className="my-order-div">
                <img className="clipboard-img" src={logout} alt=""></img>
                <Link to="/myvehicleenq" className="my-order-text">
                  <span
                    onClick={() => {
                      logoutAccount();
                    }}
                  >
                    Sign out
                  </span>
                </Link>
                <Link to="">
                  <img
                    className="next-arrow-img hidden"
                    src={next_arrow}
                    alt=""
                  ></img>
                </Link>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="250"
            data-aos-duration="1500"
            className="right-vehicle-container"
          >
            <div className="user-vehicle-header">
              <h1>My Buying Enquiries</h1>
            </div>

            <div className="filter-my-enquiries">
              <div
                className="brand-filter"
                onClick={() => {
                  if (!modelDropdown) {
                    setBrandDropdown((prevState) => !prevState);
                  }
                }}
              >
                <h5>Brand</h5>
                <img src={brandDropdown ? upArrow : downArrow} alt="arrow" />

                {brandDropdown && (
                  <div className="dropdown">
                    <div className="scroll-brand-model">
                      {brandsData.map((brand) => (
                        <div
                          key={brand._id}
                          className="text-content"
                          onClick={() =>
                            handleBrandType(brand._id, brand.title)
                          }
                        >
                          {isBrandChecked(brand._id) ? (
                            <MdOutlineCheckBox color="#050F56" size={25} />
                          ) : (
                            <MdOutlineCheckBoxOutlineBlank
                              color="#050F56"
                              size={25}
                            />
                          )}
                          <p>{brand.title}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="model-filter"
                onClick={() => {
                  if (!brandDropdown) {
                    setModelDropdown((prevState) => !prevState);
                  }
                }}
              >
                <h5>Model</h5>
                <img src={modelDropdown ? upArrow : downArrow} alt="arrow" />

                {modelDropdown && (
                  <div className="dropdown">
                    <div className="scroll-brand-model">
                      {modelsData.map((model) => (
                        <div
                          key={model._id}
                          className="text-content"
                          onClick={() => handleModelType(model._id)}
                        >
                          {isModelChecked(model._id) ? (
                            <MdOutlineCheckBox color="#050F56" size={25} />
                          ) : (
                            <MdOutlineCheckBoxOutlineBlank
                              color="#050F56"
                              size={25}
                            />
                          )}
                          <p>{model.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="vehicle-no-filter">
                <input
                  type="search"
                  placeholder="Vehicle Number"
                  name="regno"
                  value={vehicleNoValue.regno}
                  onChange={handleOnChange}
                />
                <img src={searchIcon} alt="search" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <InfiniteScroll
                dataLength={vehicleData.length}
                next={fetchData}
                style={{ width: "100%" }}
                hasMore={hasMore}
              >
                {dataAvailable ? (
                  <div className="vehicle-card-container mobile-enquiry-view">
                    {vehicleData?.map((item) => (
                      <Link
                        key={item._id}
                        to={`/vehicledetails/${item?.vehicle_id?._id}`}
                      >
                        <div className="card">
                          <div className="user-vehicle-uid">
                            <span>UID-{item?.vehicle_id?.uid}</span>
                          </div>
                          <div className="card-wrapper-vehicles">
                            <div className="card-img-wrapper mobile-enquiry-img">
                              <img
                                src={imgurl + item?.vehicle_id?.front_side_pic}
                                alt="truck"
                              />
                            </div>
                            <div className="card-info">
                              <div className="card-info-header">
                                <div className="card-info-title">
                                  <h1 className="notranslate">
                                    <AWS_Translater
                                      _text={
                                        item?.vehicle_id?.brand?.title +
                                        " " +
                                        item?.vehicle_id?.model?.name
                                      }
                                    />
                                  </h1>
                                  <div className="location">
                                    <img
                                      src={locationIcon}
                                      alt="location icon"
                                    />
                                    {/* fetching city name  */}
                                    <span className="notranslate">
                                      <AWS_Translater _text={item?.city} />
                                    </span>
                                  </div>
                                </div>
                                {/* <div className="card-publish-review">
                            <div className="review">
                              <strong>Under Review</strong>
                            </div>
                            <span>(Uploaded on Jun 01,2022)</span>
                          </div> */}
                                <div className="card-price mobile-enquiry-price">
                                  <h3>
                                    ₹
                                    {rupee_format(
                                      item?.vehicle_id?.selling_price
                                    )}{" "}
                                  </h3>
                                </div>
                              </div>

                              <div className="seller-details-container">
                                <div className="stat">
                                  <span>
                                    <span className="text-color-blue">
                                      Name -{" "}
                                    </span>
                                    {item?.vehicle_id?.secondary_name
                                      ? item?.vehicle_id?.secondary_name
                                      : item?.seller_id?.name}{" "}
                                  </span>
                                </div>
                                <div className="stat">
                                  <span>
                                    <span className="text-color-blue">
                                      Phone Number -{" "}
                                    </span>
                                    {item?.vehicle_id?.secondary_mob
                                      ? item?.vehicle_id?.secondary_mob
                                      : item?.seller_id?.mob_no}{" "}
                                  </span>
                                </div>
                                <div className="stat">
                                  <span>
                                    <span className="text-color-blue">
                                      Email -{" "}
                                    </span>
                                    {item?.vehicle_id?.secondary_email
                                      ? item?.vehicle_id?.secondary_email
                                      : item?.seller_id?.email}{" "}
                                  </span>
                                </div>
                                <div className="stat">
                                  <span style={{ textTransform: "uppercase" }}>
                                    <span className="text-color-blue">
                                      Vehicle Number -{" "}
                                    </span>
                                    {item?.vehicle_id?.reg_no}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="lottie-div">
                    <Lottie
                      options={defaultOptions}
                      height="100%"
                      width="100%"
                    />
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default UserVehicles;
