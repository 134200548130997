const mainDomain = "https://core.gaddideals.com/";
// const mainDomain = "http://192.168.1.12:3003/";

// const imgurl="https://gaddideals.brokerinvoice.co.in";
export const imgurl = "https://core.gaddideals.com";

const Constant = {
  getUrls: {
    getAlladmins: mainDomain + "api/user/admin_login",
    getAllBanners: mainDomain + "api/banner",
    getAllVehicles: mainDomain + "api/vehicle",
    getAllAdvertisments: mainDomain + "api/advertisment",
    getAllBrands: mainDomain + "api/brand",
    getAllCms: mainDomain + "api/cms",
    getAllCategories: mainDomain + "api/category",
    getAllTestimonials: mainDomain + "api/testimonial",
    getAllContactUs: mainDomain + "api/contactUs",
    getAllFaqs: mainDomain + "api/faq",
    getAllKms: mainDomain + "api/kms",
    getAllYears: mainDomain + "api/years",
    getAllFuelTypes: mainDomain + "api/fuelType",
    getAllModels: mainDomain + "api/model",
    getAllSubscriptions: mainDomain + "api/subscription",
    getAllLatestVehicles: mainDomain + "api/vehicle/latestVehicles",
    getSingleUser: mainDomain + "api/user",
    getAllBodyTypes: mainDomain + "api/bodyType",
    getAllSearchSuggestions: mainDomain + "api/vehicle/searchSuggestion",
    getAllStates: mainDomain + "api/state",
    getAllCity: mainDomain + "api/city",
    getUser: mainDomain + "api/user",
    getAllTrustedClients: mainDomain + "api/trustedClient",
    getAllMyEnquiries: mainDomain + "api/enquiries/my_enquiries",
    getAllMyVehicleEnquiries: mainDomain + "api/enquiries/my_Vehicle_enquiries",
    getCred: mainDomain + "api/aws_creds",
  },
  postUrls: {
    postAllAdmins: mainDomain + "api/user/admin_login",
    postAllBanners: mainDomain + "api/banner",
    postAllVehicles: mainDomain + "api/vehicle",
    postAllAdvertisments: mainDomain + "api/advertisment",
    postAllBrands: mainDomain + "api/brand",
    postAllCms: mainDomain + "api/cms",
    postAllCategorys: mainDomain + "api/category",
    postAllTestimonials: mainDomain + "api/testimonial",
    postAllContactUs: mainDomain + "api/contactUs",
    postAllFaqs: mainDomain + "api/faq",
    postAllKms: mainDomain + "api/kms",
    postAllYears: mainDomain + "api/years",
    postAllFuelTypes: mainDomain + "api/fuelType",
    postAllModels: mainDomain + "api/model",
    postAllSubscriptions: mainDomain + "api/subscription",
    postAllSignups: mainDomain + "api/user/auth/signup",
    postAllSignins: mainDomain + "api/user/auth/signin",
    postAllOtps: mainDomain + "api/user/auth/verifyOTP",
    potsALLresendotps: mainDomain + "api/user/auth/resendOTP",
    postAllEnquiries: mainDomain + "api/enquiries",
    postVehiclePublish: mainDomain + "api/status/",
    postAllEnquiryOtps: mainDomain + "api/enquiries/enquiryVerifyOTP",
    postContactUs: mainDomain + "api/contactUs",
    postEnquiryResendOTP: mainDomain + "api/enquiries/enquieryResendOTP",
  },
  putAllUrls: {
    putAllUsers: mainDomain + "api/user/update_profile",
  },
};
export default Constant;
