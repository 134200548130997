import React from "react";
import "./pagenotfound.style.css";
import NotFoundImg from "../assets/notfound.png";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <div className="not-found-container">
        <img src={NotFoundImg} alt="Not found" />
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
