import { AWS_ACTION_TYPES } from "./aws.types";

const INITIAL_STATE = {
  secretObj: {},
};

export const awsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AWS_ACTION_TYPES.SET_AWS_KEYS:
      return { ...state, secretObj: payload };

    default:
      return state;
  }
};
