import React, { Fragment } from "react";
import "./details.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import Stepper from "../Stepper/Stepper";

const Details = ({
  setStep,
  step,
  checkCategory,
  kmDrivenText,
  vehicleNoText,
  expectedPriceText,
  noOfTyreText,
  noOfHrsText,
  noOfSeatsText,
  handleOnChange,
  nextStep,
  prevStep,
}) => {
  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="details-container">
          <h5>Vehicle Details</h5>
        </div>
        <div className="details-fields-container">
          {checkCategory === "Trucks" && (
            <div className="form-input-control">
              <h6>
                KM Driven <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="kmDrivenText"
                onChange={handleOnChange}
                value={kmDrivenText}
              />
            </div>
          )}

          {/* {checkCategory === "Trucks" && (
            <div className="form-input-control">
              <h6>Number of Tyres</h6>
              <input
                type="number"
                name="noOfTyreText"
                onChange={handleOnChange}
                value={noOfTyreText}
              />
            </div>
          )} */}

          {checkCategory === "Buses" && (
            <div className="form-input-control">
              <h6>
                KM Driven <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="kmDrivenText"
                onChange={handleOnChange}
                value={kmDrivenText}
              />
            </div>
          )}

          {checkCategory === "Buses" && (
            <div className="form-input-control">
              <h6>
                Number of Seats <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="noOfSeatsText"
                onChange={handleOnChange}
                value={noOfSeatsText}
              />
            </div>
          )}

          {checkCategory === "Tractors" && (
            <div className="form-input-control">
              <h6>
                Number of Hours <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="noOfHrsText"
                onChange={handleOnChange}
                value={noOfHrsText}
              />
            </div>
          )}

          {checkCategory === "Construction Equipments" && (
            <div className="form-input-control">
              <h6>
                Number of Hours <span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="number"
                name="noOfHrsText"
                onChange={handleOnChange}
                value={noOfHrsText}
              />
            </div>
          )}

          <div className="form-input-control">
            <h6>
              Vehicle Number <span style={{ color: "red" }}>*</span>
            </h6>
            <input
              type="text"
              name="vehicleNoText"
              onChange={handleOnChange}
              value={vehicleNoText}
            />
          </div>
          <div className="form-input-control">
            <h6>
              Expected Price <span style={{ color: "red" }}>*</span>
            </h6>
            <input
              type="number"
              name="expectedPriceText"
              onChange={handleOnChange}
              value={expectedPriceText}
            />
          </div>

          <div className="details-btns">
            <button onClick={prevStep} className="prev-btn">
              Previous
            </button>
            <button onClick={nextStep}>Next</button>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Details;
