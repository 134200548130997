import React, { useState } from "react";
import "./mobiledialog.style.css";

import gaddilogo from "../../assets/gaddi_logo_dialog.png";
import cancelBtn from "../../assets/cancel-btn.png";

const MobileDialog = ({ detectOS, iphoneOS }) => {
  const [isOpen, setIsOpen] = useState(true);

  function launchAppOnAppleDevice() {
    if (detectOS) {
      window.location =
        "intent://gaddideals.com/#Intent;scheme=gaddidealscom;package=com.gaddi_deals;end";
    } else if (iphoneOS) {
      setTimeout(function () {
        window.location =
          "https://apps.apple.com/in/app/gaddideals/id6443432579";
      }, 25);

      // If "yourapp://" is registered, the user will see a dialog
      // asking if want to open your app. If they agree, your app will
      // launch  immediately and the timer won't fire.
      // If not installed, you'll get an ugly "Cannot Open Page"
      // dialogue and the App Store will launch when the timer expires.

      window.location = "gaddideals://";
    }
  }
  return (
    <>
      {isOpen && (
        <>
          <div
            className="overlay-dialog"
            onClick={() => setIsOpen((prevState) => !prevState)}
          ></div>

          <div className="dialog-container">
            <div className="gaddi-logo">
              <img src={gaddilogo} alt="logo" />
            </div>

            <h3>
              Download <span>Gaddideals App</span> <br />
              Now
            </h3>

            <button
              // href={
              //   detectOS
              //     ? "intent://gaddideals.com/#Intent;scheme=gaddidealscom;package=com.gaddi_deals;end"
              //     : iphoneOS
              //     ? launchAppOnAppleDevice()
              //     : ""
              // }
              onClick={launchAppOnAppleDevice}
            >
              Open In App <span className="arrow-btn">&#62;</span>
            </button>

            <img
              src={cancelBtn}
              alt="cancel"
              className="cancel-btn-img"
              onClick={() => setIsOpen((prevState) => !prevState)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MobileDialog;
