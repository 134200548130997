import React, { Fragment, useEffect } from "react";
import "./model.css";

import Navbar from "../Navbar";
import Footer from "../Footer";

import searchIcon from "../../assets/search-icon.png";

import Stepper from "../Stepper/Stepper";

const Model = ({
  setStep,
  step,
  modelList,
  setModelId,
  handleOnChange,
  modelText,
  nextStep,
  prevStep,
  checkCategory,
  setFormFields,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Fragment>
      <Navbar />
      <section className="section-wrapper-container">
        <Stepper
          setStep={setStep}
          stepIndex={step}
          checkCategory={checkCategory}
        />
        <div className="models-container">
          <h5>
            Select Model<span style={{ color: "red" }}>*</span>
          </h5>

          <div className="models-fields-container">
            <div className="model-input">
              <input
                type="search"
                name="modelText"
                value={modelText}
                placeholder="Search"
                onChange={handleOnChange}
              />
              <img src={searchIcon} alt="search icon" />
            </div>

            <div className="model-list">
              {modelList.map((model) => (
                <div
                  className="model-text"
                  key={model._id}
                  onClick={() => {
                    setModelId(model._id);
                    setFormFields((prevState) => ({
                      ...prevState,
                      modelText: model.name,
                    }));
                    setStep((prevState) => prevState + 1);
                  }}
                >
                  <p>{model?.name}</p>
                </div>
              ))}
            </div>

            <div className="model-btns">
              <button onClick={prevStep} className="prev-btn">
                Previous
              </button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Model;
