import { combineReducers } from "redux";

import { locationReducer } from "./location/location.reducer";
import { signUpReducer } from "./signup/signup.reducer";
import { pageNoReducer } from "./pageno/pageno.reducer";
import { awsReducer } from "./aws/aws.reducer";

export const rootReducer = combineReducers({
  location: locationReducer,
  signup: signUpReducer,
  pageno: pageNoReducer,
  awsKeys: awsReducer,
});
